import React, {
	ChangeEvent,
	InputHTMLAttributes,
	useEffect,
	useState,
} from 'react';
import './textarea.scss';

type TextareaProps = {
	placeholder?: string;
	value?: string;
	onChange?: (event: ChangeEvent<HTMLTextAreaElement>) => void;
} & InputHTMLAttributes<HTMLTextAreaElement>;

export const Textarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>(
	({ placeholder, value, onChange, ...rest }, ref) => {
		const [currentValue, setCurrentValue] = useState(value);

		useEffect(() => {
			setCurrentValue(value);
		}, [value]);

		const onChangeLocal = (e: any) => {
			const value = e.target.value;
			setCurrentValue(value);
			onChange?.(e);
		};

		return (
			<div className="textarea-container">
				<textarea
					type="text"
					className="textarea"
					placeholder={placeholder}
					value={currentValue}
					onChange={onChangeLocal}
					ref={ref}
					{...rest}
				/>
			</div>
		);
	},
);

Textarea.displayName = 'Textarea';
