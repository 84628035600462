import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import './symbology.scss';
import {
	IWorldIndicesTable,
	SYMBOLOGY_CONSTANTS,
	WorldIndicesColumns,
} from './symbology-constants';
import { LabelLink, Table, ToggleLinks } from '../../../../@core-ui';
import {
	scrollIntoView,
	handleEnterKeyPress,
} from '../../../../utilities/utils';
import { useQuotesNewsContext } from '../../../quotes-news/quotes-news-container/quotes-news-container';
import { dispatchMarketIndicesCustomEvent } from '../../../../utilities/events';
interface SymbologyProps {
	onClose: () => void;
}

const Symbology: React.FC<SymbologyProps> = (props) => {
	const { onClose } = props;
	const setShowWorldIndicesTab = useQuotesNewsContext()?.setShowWorldIndicesTab;
	const { StaticContent, Links, WorldIndices } = SYMBOLOGY_CONSTANTS;
	const [isExpanded, setIsExpanded] = useState(false);
	const [showWorldIndices, setshowWorldIndices] = useState(false);
	const expandedContentRef = useRef(null);

	const handleExpand = () => {
		setIsExpanded(true);
		if (expandedContentRef.current) {
			scrollIntoView(expandedContentRef.current as HTMLElement);
		}
	};

	const handleCollapse = () => {
		setIsExpanded(false);
	};

	const hideWorldIndicesTable = () => {
		setshowWorldIndices(false);
		setIsExpanded(true);
	};

	const showWorldIndicesTable = () => {
		setshowWorldIndices(true);
		setIsExpanded(false);
	};

	const gotoWorldIndicesTab = () => {
		onClose();
		setShowWorldIndicesTab?.(true);
		dispatchMarketIndicesCustomEvent();
	};

	const handleOnClick = () => {
		if (isExpanded) {
			return handleCollapse;
		} else if (showWorldIndices) {
			return hideWorldIndicesTable;
		} else {
			return handleExpand;
		}
	};

	const handleText = () => {
		if (isExpanded) {
			return 'Hide Symbology';
		} else if (showWorldIndices) {
			return 'Back to Symbology';
		} else {
			return 'Show Symbology';
		}
	};

	return (
		<div className="symbology-view" ref={expandedContentRef}>
			<div className="symbology-toggle">
				<LabelLink
					testId={'show-hide-symbology'}
					onClick={handleOnClick()}
					text={handleText()}
					onKeyDown={(e) => handleEnterKeyPress(e, handleOnClick())}
				/>
			</div>
			{isExpanded && (
				<div className="symbology-content">
					<div className="symbology-content-header">Symbology</div>
					<div className="static-content">
						For a listing of American Index symbols, please click{' '}
						<a onClick={showWorldIndicesTable}>here</a>. However, to get prices
						on World Indices, please go to the Markets section and click on the
						link to <a onClick={gotoWorldIndicesTab}>World Indices.</a>
					</div>
					<div
						className="static-content"
						tabIndex={0}
						dangerouslySetInnerHTML={{ __html: StaticContent }}
					></div>
					<div className="symbology-link-container">
						<ToggleLinks
							links={Links.map(({ Header, Component }) => ({
								Header,
								Component,
							}))}
						/>
					</div>
					<div className="symbology-toggle bottom">
						<LabelLink onClick={handleCollapse} text={'Hide Symbology'} />
					</div>
				</div>
			)}
			{showWorldIndices && (
				<Table
					data={WorldIndices as IWorldIndicesTable[]}
					columns={WorldIndicesColumns}
					pageSize={WorldIndices.length}
				/>
			)}
		</div>
	);
};

Symbology.propTypes = {
	onClose: PropTypes.func.isRequired,
};

export default Symbology;
