import './404.scss';

function Page404() {
	return (
		<div className="page-404-container">
			<h1>404 - page not found!</h1>
		</div>
	);
}

export default Page404;
