import DemoRC from '../../components/demo/demo';
import './demo.scss';

function DemoPage() {
	return (
		<div className="page-demo-container">
			<DemoRC></DemoRC>
		</div>
	);
}

export default DemoPage;
