import React, { ButtonHTMLAttributes, forwardRef } from 'react';
import './button.scss';

export enum ButtonVariant {
	Primary = 'primary',
	Secondary = 'secondary',
	Tertiary = 'tertiary',
}

type ButtonProps = {
	children: React.ReactNode;
	onClick?: (e: any) => void;
	disabled?: boolean;
	className?: string;
	testId?: string;
	variant?: ButtonVariant.Primary | ButtonVariant.Secondary;
	icon?: React.ReactNode;
} & ButtonHTMLAttributes<HTMLButtonElement>;

// eslint-disable-next-line react/display-name
const Button = forwardRef<HTMLButtonElement, ButtonProps>(
	(props: ButtonProps, ref) => {
		const {
			children,
			onClick,
			disabled,
			className = '',
			testId = '',
			variant = ButtonVariant.Primary,
			icon,
			...rest
		} = props;

		const buttonClassName = `button ${variant} ${className}`;
		return (
			<button
				className={buttonClassName}
				onClick={onClick}
				disabled={disabled}
				data-testid={testId}
				ref={ref}
				{...rest}
			>
				{children}
				{icon && <span className="button-icon">{icon}</span>}
			</button>
		);
	},
);

export default Button;
