import { resolvePromiseWithError } from '../utilities/utils';
import URLS from '../constants/urlConstants';
import {
	IXrefPredictiveResponse,
	IXrefSymbolResponse,
	IXrefPredictiveVenueRequest,
} from '../types/interfaces';
import DataRequest from './dataRequest';
import { E_HTTP_METHOD } from '../constants/appConstants';

export const getXrefDataByXids = async (
	venueXids: number[] = [],
): Promise<IXrefPredictiveResponse> => {
	return await DataRequest.execute(`${URLS.PLATFORM.XREF_GET_BY_VENUEXID}`, {
		params: { venueXids: venueXids },
	});
};

export const getXrefDataBySymbol = async (
	symbol: string,
): Promise<IXrefSymbolResponse> => {
	if (!symbol) {
		return await resolvePromiseWithError();
	}
	return await DataRequest.execute(
		`${URLS.PLATFORM.XREF_GET_BY_SYMBOL}/${symbol}`,
	);
};

export const getXrefDataByPredictiveSearch = async (
	requestParams: IXrefPredictiveVenueRequest,
): Promise<IXrefPredictiveResponse> => {
	return await DataRequest.execute(
		`${URLS.PLATFORM.XREF_GET_BY_PREDICTIVE_SEACRH}`,
		{ params: requestParams },
	);
};

export const getXrefDataBySymbols = async (
	symbols: any = [],
): Promise<IXrefSymbolResponse> => {
	if (symbols.length == 0) {
		return await resolvePromiseWithError();
	}
	return await DataRequest.execute(
		`${URLS.PLATFORM.XREF_GET_BY_SYMBOL}`,
		{
			method: E_HTTP_METHOD.POST,
			postData: {
				symbols: symbols,
			},
		},
		{
			'Content-type': 'application/json; charset=UTF-8',
		},
	);
};
export const XREF_XIDS_REQUEST_SIZE = 50;

export const fetchXrefDataInChunks = async (xids: number[]) => {
	const xidChunks = [];
	for (let i = 0; i < xids.length; i += XREF_XIDS_REQUEST_SIZE) {
		const chunk = xids.slice(i, i + XREF_XIDS_REQUEST_SIZE);
		xidChunks.push(chunk);
	}

	const promises: Promise<IXrefPredictiveResponse>[] = [];
	xidChunks.forEach((xidChunk) => {
		const promise = getXrefDataByXids(xidChunk);
		promises.push(promise);
	});

	const xrefResponse = await Promise.all(promises);
	return xrefResponse;
};
