import DataRequest from './dataRequest';
import URLS from '../constants/urlConstants';
import { INewsResponse, INewsArticleResponse } from '../types/interfaces';
import { resolvePromiseWithError } from '../utilities/utils';
import { E_HTTP_METHOD } from '../constants/appConstants';

export const getNews = async (newsInputs: any): Promise<INewsResponse> => {
	if (!newsInputs) {
		return await resolvePromiseWithError();
	}
	return await DataRequest.execute(
		`${URLS.PLATFORM.NEWS}`,
		{
			method: E_HTTP_METHOD.POST,
			postData: newsInputs,
		},
		{
			'Content-type': 'application/json; charset=UTF-8',
		},
	);
};

export const getNewsArticle = async (
	newsArticleId: string,
): Promise<INewsArticleResponse> => {
	if (!newsArticleId) {
		return await resolvePromiseWithError();
	}
	return await DataRequest.execute(
		`${URLS.PLATFORM.NEWS}/${newsArticleId}/component-type/PRES`,
		{
			method: E_HTTP_METHOD.GET,
		},
		{
			Accept: 'application/xml',
		},
	);
};
