import {
	getConvertedDate,
	getNewDate,
} from '../../../utilities/date-time-formatter-tz';
import { DEFAULT_NEWS_INPUTS } from '../../../constants/appConstants';
import {
	DATE_FORMATS,
	formatDateTime,
} from '../../../utilities/date-time-formatter';
import { addQBIDArgument } from '../../../utilities/utils';
import { TYPE_MAPPING } from '../news-constants';

export enum Time_Period {
	Today = 'Today',
	Week = 'Past 7 Days',
	Month = 'Past 1 Month',
	Between = 'Between',
}

export const CALENDAR_YEAR = {
	prevMonth: getConvertedDate(getNewDate()?.subtract(1, 'month'), {
		format: DATE_FORMATS.YEAR_MONTH_DATE,
	}),
	today: new Date().toISOString().split('T')[0],
	startDate: `${
		new Date().getFullYear() - 1
	}-0${new Date().getMonth()}-0${new Date().getDay()}`,
};

export const TIME_PERIOD_DROPDOWN = [
	{
		key: Time_Period.Today,
		value: 'Today',
	},
	{
		key: Time_Period.Week,
		value: 'Past 7 Days',
	},
	{
		key: Time_Period.Month,
		value: 'Past 1 Month',
	},
	{
		key: Time_Period.Between,
		value: 'Between',
	},
];

export const TIME_PERIOD_MAPPING: { [key: string]: number } = {
	Today: 1,
	'Past 7 Days': 7,
	'Past 1 Month': 30,
};

export const buildNewsInputs = (
	timeFrameValue: string,
	symbol: string,
	startDate?: any,
	endDate?: any,
) => {
	const today = getNewDate();
	const end = formatDateTime(endDate || today, {
		format: DATE_FORMATS.DOCUMENT_DATE_FORMAT,
	});
	const start = formatDateTime(
		startDate || today.subtract(TIME_PERIOD_MAPPING[timeFrameValue], 'day'),
		{
			format: DATE_FORMATS.DOCUMENT_DATE_FORMAT,
		},
	);
	const newsInputs: any = { ...DEFAULT_NEWS_INPUTS };
	newsInputs.limit = 5000;
	newsInputs.arguments = [
		addQBIDArgument(TYPE_MAPPING.BridgeSymbols, [symbol]),
		addQBIDArgument(TYPE_MAPPING.DocumentDate, [start], 'GreaterThanEqualTo'),
		addQBIDArgument(TYPE_MAPPING.DocumentDate, [end], 'LessThanEqualTo'),
	];
	return newsInputs;
};

export const CompanyNewsHeading = 'Company News';
