import React from 'react';
import { IBasicIconProps } from '../IBasicIconProps';

const NotesIcon: React.FC<IBasicIconProps> = ({
	width = 18,
	height = 22,
	color = '#C4C4C4',
}) => (
	<svg
		width={width}
		height={height}
		viewBox="0 0 24 24"
		version="1.1"
		xmlns="http://www.w3.org/2000/svg"
	>
		<g
			stroke="none"
			strokeWidth="1"
			fill="none"
			fillRule="evenodd"
			strokeLinecap="round"
			strokeLinejoin="round"
		>
			<g
				transform="translate(-671.000000, -204.000000)"
				stroke={color}
				strokeWidth="1.4"
			>
				<g transform="translate(671.000000, 204.000000)">
					<path d="M20.5023784,22.5 L3.5,22.5 L3.5,1.5 L20.5023784,1.5 L20.5023784,22.5 Z M7.6805848,7.69 L16.331795,7.69 M7.6805848,10.91 L13.1613515,10.91"></path>
				</g>
			</g>
		</g>
	</svg>
);

export default NotesIcon;
