import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import './automated-tests.scss';
import CompanyNews from '../../components/news/company-news/company-news';

function AutomatedTestsPage() {
	return (
		<div className="automated-tests-container">
			<Tabs>
				<TabList>
					<Tab>Company News</Tab>
				</TabList>

				<TabPanel>
					<CompanyNews symbol="KO"></CompanyNews>
				</TabPanel>
			</Tabs>
		</div>
	);
}

export default AutomatedTestsPage;
