import { BrowserRouter, Route, Routes } from 'react-router-dom';
import {
	HomePage,
	Page404,
	HowToIntegratePage,
	PrintPortfolio,
	PrintNewsArticle,
	DemoPage,
} from './pages';
import AutomatedTestsPage from './pages/automated-tests/automated-tests';

export default function App() {
	return (
		<BrowserRouter>
			<Routes>
				<Route path="/" element={<HomePage />} />
				<Route path="/home" element={<HomePage />} />
				<Route path="/demo" element={<DemoPage />} />
				<Route path="/how-to-integrate" element={<HowToIntegratePage />} />
				<Route path="print-portfolio" element={<PrintPortfolio />} />
				<Route path="print-news-article" element={<PrintNewsArticle />} />
				<Route path="automated-tests" element={<AutomatedTestsPage />} />
				<Route path="*" element={<Page404 />} />
			</Routes>
		</BrowserRouter>
	);
}
