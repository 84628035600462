import React from 'react';
import { IBasicIconProps } from '../IBasicIconProps';

const MinusIcon: React.FC<IBasicIconProps> = ({
	width = 13,
	height = 13,
	color = '#000000',
}) => (
	<svg
		width={width}
		height={height}
		viewBox="0 0 13 13"
		version="1.1"
		xmlns="http://www.w3.org/2000/svg"
		data-icon="minus"
	>
		<g
			stroke="none"
			strokeWidth="1"
			fill="none"
			fillRule="evenodd"
			strokeLinecap="round"
			strokeLinejoin="round"
		>
			<g
				transform="translate(-498.000000, -599.000000)"
				stroke={color}
				strokeWidth="1.3"
			>
				<g transform="translate(458.000000, 172.000000)">
					<g transform="translate(40.000000, 424.000000)">
						<g transform="translate(0.000000, 3.000000)">
							<path d="M0.5,6.5 L12.5,6.5" id="add-icon"></path>
						</g>
					</g>
				</g>
			</g>
		</g>
	</svg>
);

export default MinusIcon;
