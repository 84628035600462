export const ENABLE_LOGGING = false;
export const DISPLAY_ACCESS_TOKEN_ERROR_MESSAGE = false;
export const ACCESS_TOKEN_KEY = 'MD_ACCESS_TOKEN';
export const ACCESS_TOKEN_EXPIRE_TIME = 'MD_ACCESS_TOKEN_EXPIRE_TIME';

export enum E_DeviceType {
	Desktop = 'DESKTOP',
	Ipad = 'IPAD',
	Mobile = 'MOBILE',
}

export type T_DeviceType =
	| E_DeviceType.Desktop
	| E_DeviceType.Ipad
	| E_DeviceType.Mobile;

export const DEFAULT_NEWS_INPUTS = {
	argsOperator: 'AND',
	sortArguments: [
		{
			direction: 'desc',
			field: 'DocumentDate',
		},
	],
	limit: 10,
	offset: 0,
};

export const NEWS_QBID_ID = 154;

export const ERROR_MESSAGES = {
	DEFAULT_NO_DATA: 'This data is temporarily unavailable.',
	ACCESS_TOKEN_ERROR: '(MISSING OR INVALID ACCESS TOKEN)',
	CHART_NOT_AVAILABLE: 'Chart not available',
};

export enum TYPES_OF_ELEMENTS {
	LABEL = 'label',
	NUMBER = 'number',
	LINK_LABEL = 'linkLabel',
	SYMBOl_DETAILS_LABEL = 'symbolDetailsLabel',
	NOTES_ICON = 'notesIcon',
	DELETE_ICON = 'deleteIcon',
	BUTTON = 'button',
	DROPDOWN = 'dropdown',
	EDIT_ICON = 'editIcon',
	INPUT = 'input',
	TRASH_ICON = 'trashIcon',
	PRINT_ICON = 'printIcon',
}

export const AMERICAN_INDICES = [
	{
		key: 589821,
		value: 'Arca Tech 100',
	},
	{
		key: 589797,
		value: 'BANK',
	},
	{
		key: 572094,
		value: 'CBOE 10Y Treas',
	},
	{
		key: 590469,
		value: 'CBOE 30Y Treas',
	},
	{
		key: 588119,
		value: 'CBOE 5Y Treas',
	},
	{
		key: 573366,
		value: 'Computer',
	},
	{
		key: 599362,
		value: 'DJ Industrials',
	},
	{
		key: 589792,
		value: 'DJ Transportation',
	},
	{
		key: 578405,
		value: 'DJ Utilities',
	},
	{
		key: 579435,
		value: 'NASDAQ',
	},
	{
		key: 597573,
		value: 'NASDAQ 100',
	},
	{
		key: 584290,
		value: 'NASDAQ Financials',
	},
];

export const WORLD_INDICES = [
	{
		key: 601823,
		value: 'Australia All Ord.',
	},
	{
		key: 578587,
		value: 'Belgium Bel-20',
	},
	{
		key: 585994,
		value: 'France CAC-40',
	},
	{
		key: 575988,
		value: 'Netherlands AEX',
	},
];

export const SWISS_MARKET = [
	{
		key: 567786,
		value: 'Swiss Market',
	},
];

export enum E_HTTP_METHOD {
	PUT = 'PUT',
	POST = 'POST',
	DELETE = 'DELETE',
	GET = 'GET',
}

export const MARKET_DELAYED_MESSAGE =
	'Market data is delayed by at least 20 min';

export const OP_USER_SETTINGS = {
	OP_USER_INTRADAY_CHARTS_SELECTION: 'OP_USER_INTRADAY_CHARTS_SELECTION',
	OP_USER_TODAYS_PERFORMERS_SELECTION: 'OP_USER_TODAYS_PERFORMERS_SELECTION',
	OP_USER_HEADLINE_NEWS_SELECTION: 'OP_USER_HEADLINE_NEWS_SELECTION',
	OP_USER_INDICES_CHART_SELECTION: 'OP_USER_INDICES_CHART_SELECTION',
	OP_USER_INDICES_MARKET_AMERICAN_INDICES_SELECTION:
		'OP_USER_INDICES_MARKET_AMERICAN_INDICES_SELECTION',
	OP_USER_INDICES_MARKET_WORLD_INDICES_SELECTION:
		'OP_USER_INDICES_MARKET_WORLD_INDICES_SELECTION',
};

export const PRINT_WAIT_LOAD_TIME = 3000;
