import { useState, useRef, useEffect } from 'react';
import {
	CREATE_PORTFOLIO_HEADER,
	EDIT_PORTFOLIO_HEADER,
	CREATE_EDIT_PORTFOLIO_TYPE,
	CREATE_PORTFOLIO_LABELS,
	CREATE_PORTFOLIO_PLACEHOLDERS,
	CREATE_EDIT_PORTFOLIO_KEYS,
	PORTFOLIO_MESSAGE,
	BACK_TO_LANDING_LINK,
	PORTFOLIO_ACTIONS,
	ERROR_MESSAGE_STATE_TYPE,
} from '../portfolio-constants';
import './create-edit-portfolio.scss';
import {
	Heading,
	Label,
	Textbox,
	Loader,
	Textarea,
	LabelLink,
	MessageBox,
	MessageBoxStateType,
	Container,
} from '../../../../@core-ui';
import { savePortfolio, updatePortfolio } from '../../../../services';
import { NotesIcon } from '../../../../assets/Icons';
import Glossary from '../../../glossary/glossary';
import PortfolioHoldings from '../portfolio-holdings/portfolio-holdings';
import { ITransactionResponse } from '../../../../types/interfaces/IAPIResponse';
import { handleEnterKeyPress } from '../../../../utilities/utils';

type CREATE_EDIT_PORTFOLIO_PROPS_TYPE = {
	data?: CREATE_EDIT_PORTFOLIO_TYPE | null;
	handleClick?: (
		action?: string,
		fetchAgain?: boolean,
		checkLength?: boolean,
		data?: CREATE_EDIT_PORTFOLIO_TYPE | null,
	) => void;
	isGlossary?: boolean;
};

const CreateEditPortfolio = (props: CREATE_EDIT_PORTFOLIO_PROPS_TYPE) => {
	const [isLoading, setIsLoading] = useState(false);
	const [fetchHoldingsAgain, setFetchHoldingsAgain] = useState(true);
	const [masterState, setMasterState] = useState<CREATE_EDIT_PORTFOLIO_TYPE>(
		getInitialMasterState(props?.data || undefined),
	);
	const [errorMessage, setErrorMessage] = useState<ERROR_MESSAGE_STATE_TYPE>(
		getErrorState(''),
	);

	useEffect(() => {
		if (props?.data?.id) {
			setMasterState({ ...masterState, id: props.data.id });
		}
	}, [props?.data?.id]);

	const isEdit = props.data?.id;
	const nameRef = useRef<HTMLInputElement>(null);
	const masterAction = props.data?.isPortfolioView
		? PORTFOLIO_ACTIONS.VIEW_PORTFOLIO
		: '';

	function onInputChange(e: any) {
		const key = e.target.getAttribute('data-key');
		const value = e.target.value;
		if (errorMessage.message.length) {
			setErrorMessage(getErrorState(''));
		}
		setMasterState({
			...masterState,
			...{
				[key]: value,
			},
		});
	}

	function getInitialMasterState(data?: CREATE_EDIT_PORTFOLIO_TYPE) {
		const result = data || {
			[CREATE_EDIT_PORTFOLIO_KEYS.Name]: '',
			[CREATE_EDIT_PORTFOLIO_KEYS.Description]: '',
			[CREATE_EDIT_PORTFOLIO_KEYS.Id]: '',
			[CREATE_EDIT_PORTFOLIO_KEYS.InceptionDate]: '',
		};
		return result;
	}

	function getErrorState(message: string, type?: MessageBoxStateType) {
		return {
			message: message,
			status: type || MessageBoxStateType.Fail,
		};
	}

	const onCrossClick = () => {
		setErrorMessage(getErrorState(''));
	};

	const onButtonClick = async (
		e: any,
		saveTransaction?: () => Promise<ITransactionResponse>,
		errorMessage?: string | null,
	) => {
		if (!errorMessage) {
			const action = e?.target?.getAttribute('data-action');
			if (action == PORTFOLIO_ACTIONS.SAVE) {
				const portfolioName =
					masterState[CREATE_EDIT_PORTFOLIO_KEYS.Name]?.trim();
				if (isValidInputs(portfolioName)) {
					setIsLoading(true);
					const portfolioId = masterState?.id;
					const data = {
						name: masterState[CREATE_EDIT_PORTFOLIO_KEYS.Name] || '',
						description:
							masterState[CREATE_EDIT_PORTFOLIO_KEYS.Description] || '',
					};
					if (portfolioId?.length && saveTransaction) {
						const response = await saveTransaction();
						updatePortfolio(portfolioId, savePortfolioCallback, data, {
							responseTransactions: response,
						});
						setFetchHoldingsAgain(false);
					} else {
						savePortfolio(data, savePortfolioCallback);
						setFetchHoldingsAgain(true);
					}
				}
			} else {
				props.handleClick?.(masterAction, false, false, props.data);
			}
		}
	};

	const onBackToLandingClick = (e: any) => {
		onButtonClick(e);
	};

	function savePortfolioCallback(response: any) {
		let fetchAgain = false;
		setIsLoading(false);

		if (response.data?.errors || response.error) {
			if (response.data?.code === 400) {
				nameRef?.current?.focus();
				setErrorMessage(getErrorState(PORTFOLIO_MESSAGE.ALREADY_EXISTS));
			} else {
				setErrorMessage(getErrorState(PORTFOLIO_MESSAGE.GENERIC_ERROR));
			}
		} else {
			fetchAgain = true;
			const transactionsOutput = response?.requestInputs?.responseTransactions;
			let statusMessage = {
				status: MessageBoxStateType.Success,
				message: PORTFOLIO_MESSAGE.SUCCESS,
			};

			if (transactionsOutput) {
				if (transactionsOutput.status === MessageBoxStateType.Fail) {
					fetchAgain = false;
				}
				statusMessage = transactionsOutput;
			}
			printMessage(
				statusMessage.message,
				statusMessage.status,
				fetchAgain,
				response.data?.data,
			);
		}
	}

	const printMessage = (
		message: string,
		type: MessageBoxStateType,
		fetchAgain = false,
		data: any,
	) => {
		setIsLoading(false);
		setErrorMessage(getErrorState(message, type));

		setTimeout(() => {
			props.handleClick?.(
				PORTFOLIO_ACTIONS.EDIT_PORTFOLIO,
				fetchAgain,
				false,
				props.data || data,
			);
		}, 2000);
	};

	const isValidInputs = (portfoliName?: string) => {
		if (!portfoliName?.length) {
			nameRef?.current?.focus();
			setErrorMessage(getErrorState(PORTFOLIO_MESSAGE.NO_NAME));
			return false;
		}
		return true;
	};

	const bindNameAndNotes = () => {
		return (
			<div className="create-p-upper-container">
				{errorMessage.message.length > 0 && (
					<MessageBox
						content={errorMessage.message}
						state={errorMessage.status}
						enableCloseIcon={true}
						onClose={onCrossClick}
						autoHide={true}
						timeAutoHide={2000}
					/>
				)}
				<div className="portfolio-name-container">
					<Label
						text={CREATE_PORTFOLIO_LABELS.PORTFOLIO_NAME}
						size={'m'}
						isBold={true}
					/>
					<Textbox
						placeholder={CREATE_PORTFOLIO_PLACEHOLDERS.LIMIT}
						maxLength={20}
						onChange={onInputChange}
						ref={nameRef}
						data-key={`${CREATE_EDIT_PORTFOLIO_KEYS.Name}`}
						value={masterState[CREATE_EDIT_PORTFOLIO_KEYS.Name]}
					/>
					<Label text={CREATE_PORTFOLIO_LABELS.REQUIRED} isGrey={true} />
				</div>
				<div className="portfolio-notes-container">
					<div className="portfolio-notes-header">
						<Label
							text={CREATE_PORTFOLIO_LABELS.PORTFOLIO_NOTES}
							isBold={true}
							size={'m'}
						/>
						<NotesIcon />
					</div>

					<div className="portfolio-notes-text">
						<Textarea
							placeholder={CREATE_PORTFOLIO_PLACEHOLDERS.NOTE}
							onChange={onInputChange}
							data-key={`${CREATE_EDIT_PORTFOLIO_KEYS.Description}`}
							value={masterState[CREATE_EDIT_PORTFOLIO_KEYS.Description]}
						/>
					</div>
				</div>
			</div>
		);
	};

	return (
		<div>
			<div className={'back-button'}>
				<span>{'<'}</span>
				<LabelLink
					text={BACK_TO_LANDING_LINK}
					className={'back-link'}
					isBold={true}
					onClick={onBackToLandingClick}
					onKeyDown={(e) => handleEnterKeyPress(e, onBackToLandingClick)}
					size={'s'}
				/>
			</div>
			{props.isGlossary ? (
				<Glossary term="P" />
			) : (
				<Container applyPadding applyBorder applyWhiteBackground>
					<div className="create-portfolio-container">
						<header className={'header'}>
							<Heading
								content={
									isEdit ? EDIT_PORTFOLIO_HEADER : CREATE_PORTFOLIO_HEADER
								}
							/>
							{bindNameAndNotes()}
						</header>
						<main className={'create-p-transaction-conatainer'}>
							{isLoading ? (
								<div className={'loader-container'}>
									<Loader />
								</div>
							) : (
								<PortfolioHoldings
									onButtonClick={onButtonClick}
									id={props.data?.id}
									preventFetchAgain={!fetchHoldingsAgain}
								/>
							)}
						</main>
					</div>
				</Container>
			)}
		</div>
	);
};

export default CreateEditPortfolio;
