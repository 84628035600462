import React from 'react';
import { IBasicIconProps } from '../IBasicIconProps';

const PrinterIcon: React.FC<IBasicIconProps> = ({
	width = 24,
	height = 24,
	color = '#000000',
}) => (
	<svg
		width={width}
		height={height}
		viewBox="0 0 24 24"
		version="1.1"
		xmlns="http://www.w3.org/2000/svg"
	>
		<g
			stroke="none"
			strokeWidth="1"
			fill="none"
			fillRule="evenodd"
			strokeLinecap="round"
			strokeLinejoin="round"
		>
			<g
				transform="translate(-591.000000, -204.000000)"
				stroke={color}
				strokeWidth="1.4"
			>
				<g transform="translate(591.000000, 204.000000)">
					<g transform="translate(2.000000, 2.000000)">
						<path
							d="M4,16 L2,16 C1.46957,16 0.96086,15.7893 0.58579,15.4142 C0.21071,15.0391 0,14.5304 0,14 L0,9 C0,8.4696 0.21071,7.96086 0.58579,7.58579 C0.96086,7.21071 1.46957,7 2,7 L18,7 C18.5304,7 19.0391,7.21071 19.4142,7.58579 C19.7893,7.96086 20,8.4696 20,9 L20,14 C20,14.5304 19.7893,15.0391 19.4142,15.4142 C19.0391,15.7893 18.5304,16 18,16 L16,16"
							id="Path"
						></path>
						<polygon id="Path" points="16 12 4 12 4 20 16 20"></polygon>
						<polyline id="Path" points="4 7 4 0 16 0 16 7"></polyline>
					</g>
				</g>
			</g>
		</g>
	</svg>
);

export default PrinterIcon;
