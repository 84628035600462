import { Label } from '../Label/Label';
import { formatNumber } from '../../utilities/formatter';
import './NumberLabel.scss';
import { INumberConfig } from '../../types';
import { getColorByGlobalName } from '../../utilities/utils';

interface NumberProps {
	size?: 's' | 'm' | 'l';
	value: string;
	testId?: string;
	isBold?: boolean;
	numberConfig?: INumberConfig;
	fontType?: 'neue-regular' | 'neue-medium' | 'neue-bold' | 'palatino-regular';
	style?: any;
}

export const NumberLabel = (props: NumberProps) => {
	const { size, value, testId = '', isBold, style, fontType } = props;

	const formattedNumberObj = formatNumber(
		value,
		props.numberConfig ?? ({} satisfies INumberConfig),
	);

	let colorCode = '';
	if (formattedNumberObj.color === 'positive') {
		colorCode = getColorByGlobalName('--md-color-green');
	} else if (formattedNumberObj.color === 'negative') {
		colorCode = getColorByGlobalName('--md-color-red');
	}

	return (
		<span className={'number ' + formattedNumberObj.color} data-testid={testId}>
			<Label
				text={formattedNumberObj.value}
				size={size}
				isBold={isBold}
				fontType={fontType}
				style={{ ...style, color: colorCode }}
			/>
		</span>
	);
};
