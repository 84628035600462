import React, { useState, useRef, useEffect } from 'react';
import './SingleSelect.scss';
import { createUUID } from '../../../utilities/utils';
import { ChevronArrow, Check } from '../../../assets/Icons';
interface SingleSelectProps {
	changeHandler?: (event: React.ReactElement, id: string) => void;
	data: Array<{
		key: any;
		value: string;
		icon?: React.JSX.Element;
	}>;
	selectedValue?: any;
	label?: string;
	styles?: React.CSSProperties;
	liStyle?: React.CSSProperties;
	id?: string;
	displayName?: string;
	dataTestId?: string;
	icon?: React.JSX.Element;
	isListOpen?: boolean;
	isSelectedIcon?: boolean;
	hideCheck?: boolean;
	setSingleSelectOpenedId?: (id: string) => void;
}

const SingleSelect = (props: SingleSelectProps) => {
	const [containerStyle] = useState({});
	const [isListOpen, setListOpen] = useState<boolean>(false);
	const { data, id } = props;
	const refDropDown = useRef<any>();
	const selectedValueText =
		(props.selectedValue &&
			data &&
			data.find(({ key }) => key === props.selectedValue)?.value) ??
		data?.[0]?.value;
	const [selectedValue, setSelectedValue] = useState(selectedValueText);
	useEffect(() => {
		setSelectedValue(selectedValueText);
	}, [selectedValueText]);

	let liIndex = -1;
	const span =
		props.label && props.label.length > 0 ? <span>{props.label} </span> : null;
	let valueViaUpDownKeys = selectedValue;
	const guid = id ?? createUUID();

	useEffect(() => {
		setSelectedValue(selectedValueText);
	}, [selectedValueText]);

	useEffect(() => {
		setListOpen(props.isListOpen ?? false);
	}, [props.isListOpen]);

	bindEvents();

	function bindEvents() {
		document.addEventListener('click', handleClickOutside);
	}

	function openCloseMenu(e?: any) {
		e?.stopPropagation();
		// !isListOpen && getDropDownPosition(e)
		valueViaUpDownKeys = selectedValue;
		setListOpen(!isListOpen);
		if (props.setSingleSelectOpenedId) {
			props.setSingleSelectOpenedId(guid);
		}
	}

	function setSelection(value: string) {
		setSelectedValue(value);
		valueViaUpDownKeys = value;
	}

	function handleKeys(e?: any) {
		if (isListOpen) {
			e?.preventDefault();
		}
		const sibling = e.currentTarget.nextElementSibling;
		const renderedLi = sibling.querySelectorAll('li');
		const lengthrenderedLi = renderedLi.length - 1;
		if (e.key === 'Enter') {
			const value = renderedLi[liIndex]?.getAttribute('data-value');
			if (value && value !== selectedValue) {
				setSelection(value);
				props.changeHandler?.(renderedLi[liIndex], guid);
			}
			openCloseMenu(e);
		} else {
			const keyCode = e.which;
			let isFound = 0;
			renderedLi?.forEach((item: any, idx: number) => {
				item.classList.remove('li-hover');
				if (
					valueViaUpDownKeys === item.getAttribute('data-value') &&
					!isFound
				) {
					liIndex = idx;
					isFound = 1;
				}
			});
			handlelKeycode(keyCode, lengthrenderedLi);
			const ci = renderedLi[liIndex];
			ci?.classList.add('li-hover');
			valueViaUpDownKeys = ci?.getAttribute('data-value');
			ci?.scrollIntoView({ block: 'nearest' });
		}
	}

	function handlelKeycode(keyCode: any, lengthrenderedLi: number) {
		if (keyCode === 40) {
			liIndex++;
			if (liIndex > lengthrenderedLi) liIndex = 0;
		} else if (keyCode === 38) {
			liIndex--;
			if (liIndex < 0) liIndex = lengthrenderedLi;
		}
	}

	function handleChangeEvent(e: any) {
		const value = e.currentTarget.getAttribute('data-value') || '';
		setSelection(value);
		if (props.changeHandler) {
			props.changeHandler(e.currentTarget, guid);
		}
		if (e.key === 'Enter') {
			handleKeys();
		} else {
			openCloseMenu(e);
		}
	}

	function handleIconClick(e: React.MouseEvent) {
		e.stopPropagation();
		openCloseMenu();
	}

	const renderDDLOption = (item: any) => {
		const isSelected = selectedValue === item.value;
		return (
			<React.Fragment key={'li_' + item.key}>
				<li
					tabIndex={0}
					onClick={handleChangeEvent}
					onKeyDown={handleChangeEvent}
					className={`${isListOpen ? '' : 'hide'} ${
						isSelected ? 'selected' : ''
					}`}
					data-value={item.value}
					data-key={item.key}
					id={id ?? ''}
				>
					{props.isSelectedIcon ? (
						<span className={'check-icon'}>{isSelected && <Check />}</span>
					) : (
						!item.icon && (
							<span className={'check-icon'}>
								{isSelected && !props.hideCheck && <Check />}
							</span>
						)
					)}
					{item.icon && (
						<span className="option-icon">
							<item.icon height={22} width={22} color={'#000'} />
						</span>
					)}
					<span className={'name'} title={item.value}>
						{item.value}
					</span>
				</li>
			</React.Fragment>
		);
	};
	function bindDropDownValues(dropdownData: any): React.ReactNode {
		const dropdownBody =
			dropdownData && dropdownData.length > 0
				? dropdownData.map((item: any) => renderDDLOption(item))
				: null;
		return dropdownBody;
	}

	function handleClickOutside(e: any) {
		if (isListOpen && !refDropDown?.current?.contains(e.target)) {
			openCloseMenu();
		}
	}
	return (
		<div className={'dropDownMain'}>
			{span}
			<ul
				className={`basicDropDownContainer
          ${isListOpen ? 'open' : ''} DropDownUL`}
				style={props.styles}
				id={guid}
				data-testid={props.dataTestId ?? 'dropDownId'}
				ref={refDropDown}
			>
				{!props.icon && (
					<li
						tabIndex={0}
						onClick={openCloseMenu}
						onKeyDown={handleKeys}
						id={`DropdownLabel_${guid}`}
						className={'selected-value'}
					>
						{
							<React.Fragment>
								{props.displayName ? (
									<span className={'name'}>{props.displayName}</span>
								) : (
									<span className={'name'} title={selectedValue}>
										{selectedValue}
									</span>
								)}
								<span className={'caret-icon'}>
									<ChevronArrow type={isListOpen ? 'up' : 'down'} />
								</span>
							</React.Fragment>
						}
					</li>
				)}
				<li
					className={`${
						isListOpen ? '' : 'hide'
					} basicDropDownValueContainer dropDownList`}
					style={{ ...containerStyle, ...props.liStyle }}
				>
					<ul>{bindDropDownValues(data)}</ul>
				</li>
			</ul>
			{props.icon && (
				<div className="icon-toggle" onClick={handleIconClick}>
					{props.icon}
				</div>
			)}
		</div>
	);
};

export default SingleSelect;
