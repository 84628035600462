import React, { useState } from 'react';
import { Textbox, Button } from './../../../../@core-ui';
import { SearchIcon, ArrowRightIcon } from './../../../../assets/Icons';
import {
	handleEnterKeyPress,
	isAlphaNumeric,
} from '../../../../utilities/utils';
import { getXrefDataBySymbol } from '../../../../services/xref-data-request-methods';
import SymbolSearchModal from '../symbol-search-modal/symbol-search-modal';
import './search-box.scss';
import { IXrefResponseItem } from '../../../..//types/interfaces';
import { E_SecurityTypes } from '../symbol-search-modal/symbol-search-modal-constants';

type SearchboxProps = {
	label?: string;
	placeholder?: string;
	onAddSymbol: (item: any) => void;
	closeOnSymbolClick?: boolean;
};

const SearchBox = ({
	label,
	placeholder,
	onAddSymbol,
	closeOnSymbolClick,
}: SearchboxProps) => {
	const [showSymbolSearchPopup, setShowSymbolSearchPopup] = useState(false);
	const [searchValue, setSearchValue] = useState('');

	const searchSymbolPopupHandler = () => {
		setShowSymbolSearchPopup(true);
	};

	const onSearchHandler = () => {
		fetchXrefBySymbolSearch();
	};

	const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const inputValue = event.target.value;
		if (isAlphaNumeric(inputValue)) {
			setSearchValue(inputValue);
		}
	};

	const onClose = () => {
		setSearchValue('');
		setShowSymbolSearchPopup(false);
	};

	const fetchXrefBySymbolSearch = async () => {
		const response = await getXrefDataBySymbol(searchValue?.trim());
		if (
			response?.data?.data &&
			response.data.data.classification?.id !== E_SecurityTypes.Index
		) {
			onAddSymbol(response.data.data);
			onClose();
			setSearchValue('');
		} else {
			setShowSymbolSearchPopup(true);
		}
	};

	const addSymbolHandler = (data: IXrefResponseItem) => {
		onAddSymbol(data);
		closeOnSymbolClick && onClose();
	};

	return (
		<div className="searchbox-container">
			<Textbox
				label={label}
				type="text"
				testId={`symbol-search-textbox`}
				className={`textbox`}
				placeholder={placeholder}
				onChange={handleInputChange}
				value={searchValue}
				onKeyDown={(e) => handleEnterKeyPress(e, onSearchHandler)}
			/>
			<span
				tabIndex={0}
				role="button"
				title="Go"
				data-testid={'go-button'}
				className={'go-button'}
				onClick={onSearchHandler}
				onKeyDown={(e) => handleEnterKeyPress(e, onSearchHandler)}
			>
				<ArrowRightIcon />
			</span>

			<Button
				className={`search-button`}
				testId={`symbol-search-button`}
				onClick={searchSymbolPopupHandler}
			>
				<SearchIcon color="black" />
				<span>Search</span>
			</Button>
			{showSymbolSearchPopup && (
				<SymbolSearchModal
					incorrectSymbol={searchValue}
					onClose={onClose}
					onAddSymbol={addSymbolHandler}
				/>
			)}
		</div>
	);
};

export default SearchBox;
