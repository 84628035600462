import React from 'react';
import './heading.scss';

interface HeadingProps {
	type?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
	content?: string;
	testId?: string;
	align?: 'left' | 'center' | 'right';
	isHtmlContent?: boolean;
}

export const Heading = (props: HeadingProps) => {
	const {
		type = 'h2',
		content,
		testId = '',
		align = '',
		isHtmlContent = false,
	} = props;
	const Type: any = type;
	const headingClass = `heading-${type}`;
	const alignClass: string = align ? 'align-' + align : '';

	const htmlContentProps = {
		className: `heading ${headingClass} ${alignClass}`,
		tabIndex: 0,
		'data-testid': testId,
	};
	const element = isHtmlContent
		? { dangerouslySetInnerHTML: { __html: content } }
		: { children: content };

	return React.createElement(Type, { ...element, ...htmlContentProps });
};
