import { ITableColumn } from '../../../../@core-ui/Table/table';

export interface ISymbolSearchResultTable {
	Name: React.ReactNode;
	Symbol: string;
}
export const SymbolSearchResultColumns: ITableColumn<ISymbolSearchResultTable>[] =
	[
		{ header: 'Name', accessor: 'Name' },
		{ header: 'Symbol', accessor: 'Symbol' },
	];
