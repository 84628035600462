import React from 'react';

import './demo.scss';
import { Container } from '../../@core-ui/Container/container';
import {
	DATE_FORMATS,
	formatDateTime,
} from '../../utilities/date-time-formatter';
import {
	convertUtcDttmToET,
	formatDateTimeTZ,
} from '../../utilities/date-time-formatter-tz';

import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);
dayjs.extend(timezone);

const DemoRC: React.FC = () => {
	const cd = new Date();
	const utcDate = dayjs.utc().toString();

	return (
		<Container id="demo-container">
			<p>Current Date = {cd.toString()}</p>
			<p>Formatted Current Date = {formatDateTime(cd)}</p>
			<p>UTC Date = {utcDate}</p>
			<p>UTC to Local Date = {formatDateTime(utcDate)}</p>
			<p>UTC to EST Date = {formatDateTimeTZ(utcDate)}</p>
			<p>Format Local Time = {formatDateTime(cd, { timeOnly: true })}</p>
			<p>Format UTC Time = {formatDateTimeTZ(utcDate, { timeOnly: true })}</p>

			<br></br>
			<p>
				{convertUtcDttmToET(utcDate, {
					format: DATE_FORMATS.ET_DATE_TIME_FORMAT,
				})}
			</p>
		</Container>
	);
};

export default DemoRC;
