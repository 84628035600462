import { logError } from '../utilities/logger';

const BUILD_VERSION = process.env.BUILD_VERSION;
const MINIFICATION_ENABLED = process.env.MINIFICATION_ENABLED;

export const WC_NAME_PREFIX = 'md-';
export const WC_NAME_POSTFIX = '';
export const SHADOW_DOM_ENABLED = true;

export const DOMAIN = (() => {
	let baseUrl = '/';

	try {
		const tempSrc = document?.currentScript?.getAttribute('src');
		if (
			tempSrc &&
			(tempSrc.includes('http://') || tempSrc.includes('https://'))
		) {
			baseUrl = `${new URL(tempSrc).origin}/`;
		}
	} catch (error) {
		logError(error);
	}

	return baseUrl;
})();

export const getWCScriptURL = (wcName: string) => {
	return `${DOMAIN}web-components/js/${wcName}.wc.${BUILD_VERSION}${
		MINIFICATION_ENABLED ? '.min.js' : '.js'
	}`;
};

export const getWcStyleURL = (wcName: string) => {
	return `${DOMAIN}web-components/css/${wcName}.wc.${BUILD_VERSION}${
		MINIFICATION_ENABLED ? '.min.css' : '.css'
	}`;
};

export const prepareWCName = (wcName: string) => {
	return `${WC_NAME_PREFIX}${wcName}${WC_NAME_POSTFIX}`;
};

export const MD_GLOBAL_CSS_ID = 'md_global_css';
export const MD_GLOBAL_CSS_SRC = `${DOMAIN}md-global${
	MINIFICATION_ENABLED ? '.min.css' : '.css'
}?v=${BUILD_VERSION}`;

export const MD_GLOBALS_SCRIPT_ID = 'md_globals_js';
export const MD_GLOBALS_SCRIPT_SRC = `${DOMAIN}md-globals.js?v=${BUILD_VERSION}`;

export const MD_VENDORS_SCRIPT_ID = 'md_vendors_js';
export const MD_VENDROS_SCRIPT_SRC = `${DOMAIN}web-components/md-vendors.${BUILD_VERSION}.${
	MINIFICATION_ENABLED ? 'min.js' : 'js'
}`;

// Mapping for React Component to WebComponent name
// Add any WC in lighthouserc.js as well
export const RC_WC_MAP: Record<string, any> = {
	HeadlineNews: {
		name: prepareWCName(`headline-news`),
	},
	TodaysPerformers: {
		name: prepareWCName(`todays-performers`),
	},
	IntradayCharts: {
		name: prepareWCName(`intraday-charts`),
	},
	QuotesNews: {
		name: prepareWCName(`quotes-news`),
	},
	QuickQuote: {
		name: prepareWCName(`quick-quote`),
		attributes: ['symbol', 'cusip', 'enable-add-to-watchlist'],
	},
};
