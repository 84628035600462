import React from 'react';
import { IBasicIconProps } from '../IBasicIconProps';

const NewsIcon: React.FC<IBasicIconProps> = ({
	width = 13,
	height = 13,
	color = '#000000',
}) => (
	<svg
		width={width}
		height={height}
		viewBox="0 0 20 22"
		version="1.1"
		xmlns="http://www.w3.org/2000/svg"
	>
		<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
			<g
				transform="translate(-213.000000, -614.000000)"
				fill={color}
				fillRule="nonzero"
			>
				<g id="News" transform="translate(213.000000, 614.000000)">
					<path d="M17.38,9.41 L7,9.41 C6.61,9.41 6.3,9.1 6.3,8.71 L6.3,3.77 C6.3,3.38 6.61,3.07 7,3.07 L16.9489663,3.07 C17.3389663,3.07 17.6489663,3.38 17.6489663,3.77 L17.6489663,8.71 C17.6489663,9.1 17.3389663,9.41 16.9489663,9.41 L17.38,9.41 Z M7.74201769,8.01 L16.2342858,8.01 L16.2342858,4.47 L7.74201769,4.47 L7.74201769,8.01 Z"></path>
					<path d="M17.1166384,12.57 L6.98336163,12.57 C6.60263158,12.57 6.3,12.26 6.3,11.87 C6.3,11.48 6.60263158,11.17 6.98336163,11.17 L17.1166384,11.17 C17.4973684,11.17 17.8,11.48 17.8,11.87 C17.8,12.26 17.4973684,12.57 17.1166384,12.57 L17.1166384,12.57 Z"></path>
					<path d="M17.1166384,15.32 L6.98336163,15.32 C6.60263158,15.32 6.3,15.01 6.3,14.62 C6.3,14.23 6.60263158,13.92 6.98336163,13.92 L17.1166384,13.92 C17.4973684,13.92 17.8,14.23 17.8,14.62 C17.8,15.01 17.4973684,15.32 17.1166384,15.32 L17.1166384,15.32 Z"></path>
					<path d="M12.36,18.01 L7,18.01 C6.61,18.01 6.3,17.7 6.3,17.31 C6.3,16.92 6.61,16.61 7,16.61 L12.36,16.61 C12.75,16.61 13.06,16.92 13.06,17.31 C13.06,17.7 12.75,18.01 12.36,18.01 Z"></path>
					<path d="M5.08,22 C2.9,22 0,21.91 0,17.51 L0,3.98 C0,3.59 0.31,3.28 0.7,3.28 L4.02350968,3.28 L4.01991996,1.25 C4.01991996,0.92 4.12991996,0.6 4.35991996,0.37 C4.58991996,0.13 4.89991996,0 5.23991996,0 L19.2971414,0 C19.6871414,0 19.9971414,0.31 19.9971414,0.7 L19.9971414,19.0199789 C19.9971414,20.6399789 18.6371414,22 16.9571414,22 L5.08,22 Z M16.7628988,20.6069708 C17.7615206,20.6069708 18.6526907,19.9293593 18.6526907,18.8069441 L18.6526907,1.39 L5.38519411,1.39 L5.41442341,19.0249881 C5.42523027,19.8066843 5.05151682,20.2546823 4.73032025,20.5969708 L16.7628988,20.6069708 Z M1.4,4.68 L1.4,17.1589914 C1.4,18.6930955 1.8594259,19.5805344 2.58692753,20.0776003 C3.45480206,20.2853532 4.019327,19.6113176 4.00818617,18.4638114 L4.00818617,4.68 L1.4,4.68 Z"></path>
				</g>
			</g>
		</g>
	</svg>
);

export default NewsIcon;
