import './chevron-arrow.scss';
import { IBasicIconProps } from './../IBasicIconProps';
interface ChevronArrowProps extends IBasicIconProps {
	type?: 'up' | 'down' | 'left' | 'right';
}

export const ChevronArrow = (props: ChevronArrowProps) => {
	const {
		type = 'down',
		color = '#2B6D9F',
		height = '8px',
		width = '14px',
	} = props;

	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 14 8"
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
			className={type}
		>
			<g
				stroke="none"
				strokeWidth="1"
				fill="none"
				fillRule="evenodd"
				strokeLinecap="round"
				strokeLinejoin="round"
			>
				<g
					transform="translate(-1328.000000, -538.000000)"
					stroke={color}
					strokeWidth="1.7"
				>
					<g transform="translate(854.000000, 474.000000)">
						<g transform="translate(270.000000, 50.000000)">
							<g transform="translate(211.261214, 18.316623) rotate(90.000000) translate(-211.261214, -18.316623) translate(208.577836, 12.577836)">
								<path
									d="M-1.77635684e-15,5.73878628 L5.36675462,0 M5.36675462,11.4775726 L-1.77635684e-15,5.73878628"
									transform="translate(2.683377, 5.738786) scale(-1, 1) translate(-2.683377, -5.738786) "
								></path>
							</g>
						</g>
					</g>
				</g>
			</g>
		</svg>
	);
};
