import { setAccessToken, printWindow } from '../../utilities/utils';

import PortfolioContainer from '../../components/quotes-news/portfolio/portfolio-container';

import './print-portfolio.scss';

function PrintPortfolio() {
	const url = new URL(window.location.href);

	const token = window.atob(url.searchParams.get('t') || '');

	let actionObject: any = {};

	const state = url.searchParams.get('state');

	if (state?.length) {
		try {
			actionObject = JSON.parse(window.atob(state));
		} catch (error) {
			actionObject = {};
		}

		actionObject['doPrint'] = 'true';
	}

	setAccessToken(token);

	const printTable = () => {
		printWindow();
	};

	return (
		<div id={'print-wrapper'}>
			<PortfolioContainer onLoad={printTable} actionObject={actionObject} />
		</div>
	);
}

export default PrintPortfolio;
