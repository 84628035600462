import DataRequest from './dataRequest';
import URLS from '../constants/urlConstants';
import { E_HTTP_METHOD } from '../constants/appConstants';
import {
	IDeletePortfolioResponse,
	IPortfolioBasicInfo,
	IUpdatePortfolioResponse,
	IPortfolioHoldingsInput,
	IPortfolioCreateTransInput,
	IGetHoldingsResponse,
	IGetTransactionsResponse,
	IPortfolioAnalysisResponse,
} from '../types/interfaces';

const DEFAULT_CONTAINER_OBJECT = {
	type: 'OP_PORTFOLIO',
	currency: 'USD',
	description: '',
	dividendManagementType: 'ToCash',
};

const DEFAULT_CREATE_HOLDINGS_OBJECT = {
	dividendManagementType: 'ToCash',
	type: 'Instrument',
	accountingMethod: 'Default',
	description: 'My holding',
	currency: 'USD',
};

const DEFAULT_CREATE_TRANSACTIONS_OBJECT = {
	description: 'My transaction',
	currency: 'USD',
	type: 'LongBuy',
};

const DEFAULT_UPDATE_TRANSACTIONS_OBJECT = {
	description: 'My transaction',
	currency: 'USD',
};

function generatePortfoliosInputs(containerIds: string[], datesArray: any) {
	return {
		containerIds: containerIds,
		currency: DEFAULT_CONTAINER_OBJECT.currency,
		value: { includeCurrent: true },
		unrealizedGain: {
			dateRanges: datesArray,
		},
		cost: { includeCurrent: true },
	};
}

export const savePortfolio = (
	inputObj: any,
	callback: (response: any) => void,
) => {
	callPortfolioAPI(
		callback,
		URLS.PLATFORM.PORTFOLIO,
		E_HTTP_METHOD.POST,
		inputObj,
	);
};

const callPortfolioAPI = async (
	callback: (response: any) => void,
	url: string,
	method?: string,
	inputObj?: any,
	inputParams?: any,
) => {
	const response = await DataRequest.execute(
		url,
		getInputPrams(DEFAULT_CONTAINER_OBJECT, method, inputObj, inputParams),
	);
	callback(response);
};

const getInputPrams = (
	defaultData?: any,
	method?: string,
	inputObj?: any,
	inputParams?: any,
) => {
	if (method) {
		let postData: any = {};
		if (method !== E_HTTP_METHOD.DELETE) {
			postData = getCombinedObject(defaultData, inputObj);
		}
		return {
			method: method,
			postData: postData,
			requestInputs: inputParams,
		};
	}
	return undefined;
};

export const getAllPortfolios = (
	portfolioCallBack: (response: any) => void,
) => {
	const url = `${URLS.PLATFORM.PORTFOLIO}?containerType=${DEFAULT_CONTAINER_OBJECT.type}&retrieveInceptionDate=true`;
	callPortfolioAPI(portfolioCallBack, url);
};

export const getPortfoliosInfo = async (
	containerIds: string[],
	datesArray: any,
	callback?: (response: any) => void,
): Promise<void | IPortfolioAnalysisResponse> => {
	const response = await DataRequest.execute(URLS.PLATFORM.PORTFOLIO_ANALYSIS, {
		method: E_HTTP_METHOD.POST,
		postData: generatePortfoliosInputs(containerIds, datesArray),
	});
	if (typeof callback === 'function') return callback(response);
	return response;
};

export const deletePortfolio = (
	containerId: string,
	callback: (response: IDeletePortfolioResponse) => void,
) => {
	const url = `${URLS.PLATFORM.PORTFOLIO}/${containerId}`;
	callPortfolioAPI(callback, url, E_HTTP_METHOD.DELETE);
};

export const updatePortfolio = (
	containerId: string,
	callback: (response: IUpdatePortfolioResponse) => void,
	data: IPortfolioBasicInfo,
	inputParams?: any,
) => {
	const url = `${URLS.PLATFORM.PORTFOLIO}/${containerId}`;
	callPortfolioAPI(callback, url, E_HTTP_METHOD.PUT, data, inputParams);
};

export class PortfolioHoldingsCrud {
	holdingsUrl: string;

	constructor(containerId: string) {
		this.holdingsUrl = `${URLS.PLATFORM.PORTFOLIO}/${containerId}/${URLS.PLATFORM.PORTFOLIO_HOLDINGS}`;
	}

	createHoldings = async (inputArray: IPortfolioHoldingsInput[]) => {
		let postData: any = [];

		if (inputArray?.length) {
			inputArray?.forEach((item: IPortfolioHoldingsInput) => {
				postData.push(getCombinedObject(DEFAULT_CREATE_HOLDINGS_OBJECT, item));
			});
		}

		postData = {
			holdings: postData,
		};

		return await makeAPICall(
			`${this.holdingsUrl}/instrument`,
			E_HTTP_METHOD.POST,
			postData,
		);
	};

	deleteHoldings = async (holdingId: string) => {
		const url = `${this.holdingsUrl}?holdingId=${holdingId}`;
		return await makeAPICall(url, E_HTTP_METHOD.DELETE);
	};

	getHoldings = async (): Promise<IGetHoldingsResponse> => {
		const url = `${this.holdingsUrl}/instrument`;
		return await makeAPICall(url);
	};
}

export class PortfolioTransactionsCrud {
	containerId: string;

	constructor(containerId: string) {
		this.containerId = containerId;
	}

	/* helper method to get the url */
	getUrl = (holdingId: string, instrumentEnabled = false) => {
		const instrumentString = instrumentEnabled ? '/instrument' : '';
		return `${URLS.PLATFORM.PORTFOLIO}/${this.containerId}/${URLS.PLATFORM.PORTFOLIO_HOLDINGS}${instrumentString}/${holdingId}/${URLS.PLATFORM.PORTFOLIO_TRANSACTIONS}`;
	};

	/* Get all transactions */
	getTransactions = async (): Promise<IGetTransactionsResponse> => {
		const url = `${URLS.PLATFORM.PORTFOLIO}/${this.containerId}/${URLS.PLATFORM.PORTFOLIO_TRANSACTIONS}?transactionTypes=${DEFAULT_CREATE_TRANSACTIONS_OBJECT.type}`;
		return await makeAPICall(url);
	};

	/* Create/Update transactions */
	createUpdateTransactions = async (
		inputArray: IPortfolioCreateTransInput[],
		isUpdate?: boolean,
		holdingId?: string,
	) => {
		let postData: any = [];

		if (inputArray?.length) {
			inputArray.forEach((item: IPortfolioCreateTransInput) => {
				postData.push(
					getCombinedObject(
						isUpdate
							? DEFAULT_UPDATE_TRANSACTIONS_OBJECT
							: DEFAULT_CREATE_TRANSACTIONS_OBJECT,
						item,
					),
				);
			});
		}
		postData = {
			transactions: postData,
		};
		return await makeAPICall(
			this.getUrl(holdingId || inputArray[0]['cashAccountId'], true),
			isUpdate ? E_HTTP_METHOD.PUT : E_HTTP_METHOD.POST,
			postData,
		);
	};

	/* Delete transactions */
	deleteTransaction = async (holdingId: string, transactionId: string) => {
		const url = `${this.getUrl(holdingId)}?transactionId=${transactionId}`;
		return await makeAPICall(url, E_HTTP_METHOD.DELETE);
	};
}

const getCombinedObject = (defaultData: any, inputObj: any) => {
	let postData: any = {};
	try {
		postData = Object.assign(
			{ ...JSON.parse(JSON.stringify(defaultData)) },
			{ ...(inputObj || {}) },
		);
	} catch (ex) {
		console.error(ex);
	}
	return postData;
};

const getInputObject = (method?: string, postData?: any) => {
	if (method) {
		return {
			method: method,
			postData: postData,
		};
	}
	return undefined;
};

const makeAPICall = async (url: string, method?: string, postData?: any) => {
	const response = await DataRequest.execute(
		url,
		getInputObject(method, postData),
	);
	return response;
};
