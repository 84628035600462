import React from 'react';
import './Label.scss';
interface LabelProps {
	text: string;
	size?: 'xs' | 's' | 'ms' | 'm' | 'l' | 'xl';
	testId?: string;
	isBold?: boolean;
	isGrey?: boolean;
	ellipsisEnabled?: boolean;
	align?: 'left' | 'center' | 'right';
	fontType?: 'neue-regular' | 'neue-medium' | 'neue-bold' | 'palatino-regular';
	display?: 'inline' | 'block' | 'inline-block' | 'none';
	isHtmlContent?: boolean;
	style?: any;
	className?: string;
	isDarkGrey?: boolean;
}

export const Label = (props: LabelProps) => {
	const {
		size,
		text,
		testId = '',
		isBold,
		isGrey,
		ellipsisEnabled,
		align,
		fontType = '',
		display = '',
		isHtmlContent = false,
		style,
		className,
		isDarkGrey,
	} = props;

	const sizeClass: string = size ? 'label-' + size : '';
	const boldClass: string = isBold ? 'boldLabel' : '';
	const greyClass: string = isGrey ? 'greyLabel' : '';
	const ellipsisClass: string = ellipsisEnabled ? 'ellipsis' : '';
	const alignClass: string = align ? 'align-' + align : '';
	const typeClass: string = fontType ? fontType : '';
	const displayClass: string = display ? `display-${display}` : '';
	const darkGreyClass: string = isDarkGrey ? 'darkGreyLabel' : '';

	const htmlContentProps = {
		className: `label ${sizeClass} ${boldClass} ${greyClass} ${ellipsisClass} ${alignClass} ${typeClass} ${displayClass} ${className} ${darkGreyClass}`,
		tabIndex: 0,
		'data-testid': testId,
		style: style,
	};
	const element = isHtmlContent
		? { dangerouslySetInnerHTML: { __html: text } }
		: { children: text, title: text };

	return React.createElement('span', { ...element, ...htmlContentProps });
};
