import React from 'react';
import './portfolio-tracker-chart.scss';

const HighChartComponent = React.lazy(
	() => import('../../../../../shared/high-chart/high-chart-component'),
);
import {
	HOVER_TABLE_HEADER,
	IAssetAllocation,
	IAllocationItem,
	HOVER_TABLE_DATA_MAPPING,
} from '../portfolio-tracker-constants';
import { generateColValue } from '../../portfolio-utils';
import ReactDOMServer from 'react-dom/server';
import { Heading, Loader } from '../../../../../@core-ui';
import { getDeviceType } from '../../../../../utilities/utils';
import { E_DeviceType } from '../../../../..//constants/appConstants';

interface PortfolioTrackerChartProps {
	assetAllocationsData?: IAssetAllocation;
}

const PortfolioTrackerChart: React.FC<PortfolioTrackerChartProps> = ({
	assetAllocationsData,
}) => {
	const isMobile = getDeviceType() === E_DeviceType.Mobile;

	const buildHoverTable = () => {
		const hoverParentStyle = {
			display: 'flex',
			flexDirection: 'column' as const,
			zIndex: '2',
			background: '#F6FBFF',
			padding: isMobile ? '7px 8px' : '7px 13px',
		};

		const commonRow = {
			display: 'flex',
			justifyContent: 'space-between',
			padding: '4px 0px',
			gap: '5px',
			alignItems: 'center',
		};

		const labelStyle = {
			height: 'auto',
			minWidth: isMobile ? '80px' : '100px',
			textAlign: 'right',
		};

		const labelStyleFirstElement = {
			textAlign: 'left',
		};

		const headerStyle = {
			...{
				borderBottom: '1px solid #F0F0F0',
				padding: '6px 0px 2px 0px',
			},
			...commonRow,
		};

		return (
			<div style={hoverParentStyle}>
				<h6
					style={{
						fontSize: '14px',
						fontFamily: 'Palatino-Regular',
						color: '#000',
						letterSpacing: '0.29px',
						lineHeight: '19px',
						margin: '10px 0',
					}}
				>
					{'Performance By Asset Allocations'}
				</h6>
				<div style={headerStyle}>
					{HOVER_TABLE_HEADER?.map((item: any, idx: number) => {
						return (
							<React.Fragment key={`header_${item.label}`}>
								{generateColValue(
									item,
									item.label,
									idx === 0
										? { ...labelStyle, ...labelStyleFirstElement }
										: labelStyle,
								)}
							</React.Fragment>
						);
					})}
				</div>
				<div className="hover-table-data">
					{assetAllocationsData?.allocations?.map((data: IAllocationItem) => {
						return (
							<div
								className="common-row"
								key={`row_${data.name}`}
								style={commonRow}
							>
								{HOVER_TABLE_DATA_MAPPING.map((item: any, idx: number) => {
									return (
										<div
											key={`col_${item.key}`}
											style={{
												width: '25%',
												textAlign: idx === 0 ? 'left' : 'right',
											}}
										>
											{generateColValue(item, data[item.key])}
										</div>
									);
								})}
							</div>
						);
					})}
				</div>
			</div>
		);
	};

	const chartOptions = {
		chart: {
			type: 'pie',
			height: isMobile ? '240px' : '250px',
		},
		title: false,
		subtitle: {
			useHTML: true,
			text: '<div>Total Asset <div style="text-align: center">Allocation</div></div>',
			style: {
				color: '#575757',
				fontSize: '14px',
				fontFamily: 'HelveticaNeue-Regular',
				fontWeight: 'bold',
				lineHeight: '17px',
			},
			verticalAlign: 'middle',
			y: 25,
		},
		tooltip: {
			useHTML: true,
			padding: 1,
			backgroundColor: '#F6FBFF',
			style: {
				boxShadow: '0 1px 5px 0 rgba(56, 81, 100, 0.5)',
				width: '477px',
			},
			formatter: () => {
				return ReactDOMServer.renderToString(buildHoverTable());
			},
			outside: true,
		},
		credits: {
			enabled: false,
		},
		plotOptions: {
			series: {
				states: {
					inactive: {
						opacity: 1,
					},
					hover: {
						enabled: false,
					},
				},
				type: 'pie',
				size: '100%',
				innerSize: '77%',
				dataLabels: {
					enabled: false,
				},
			},
		},
		colors: assetAllocationsData?.colors,
		series: [
			{
				type: 'pie',
				data: assetAllocationsData?.data,
			},
		],
	};

	return (
		<div className="chart-container" data-testid="chart-container">
			{assetAllocationsData ? (
				<React.Suspense fallback={<Loader />}>
					<HighChartComponent
						options={chartOptions}
						id={'portfolio-tracker-pie-high-chart'}
					/>
				</React.Suspense>
			) : (
				<div className="no-data-pie-chart">
					<Heading content="Asset Allocation" type={'h6'} />
					<Heading content="not available" type={'h6'} />
				</div>
			)}
		</div>
	);
};

export default React.memo(PortfolioTrackerChart);
