import { ITableColumn } from '../../../@core-ui';

export enum EStatisticsIndices {
	AMEX = 'AMEX',
	DJIA = 'DJIA',
	NASDAQ = 'NASDAQ',
	NYSE = 'NYSE',
}
export const StatisticsIndicesDropdownConstants = [
	{ key: 'AMEX', value: 'AMEX' },
	{ key: 'DJIA', value: 'DJIA' },
	{ key: 'NASDAQ', value: 'NASDAQ' },
	{ key: 'NYSE', value: 'NYSE' },
];

export interface IStatisticsTable {
	Index: string;
	NewHighs?: number;
	NewLows?: number;
	AdvancingIssues?: number;
	DecliningIssues?: number;
	UnchangingIssues?: number;
}

export const StatisticsColumns: ITableColumn<IStatisticsTable>[] = [
	{ header: 'Index', accessor: 'Index' },
	{ header: 'New Highs', accessor: 'NewHighs' },
	{ header: 'New Lows', accessor: 'NewLows' },
	{ header: 'Advancing Issues', accessor: 'AdvancingIssues' },
	{ header: 'Declining Issues', accessor: 'DecliningIssues' },
	{ header: 'Unchanging Issues', accessor: 'UnchangingIssues' },
];

export interface IStatisticsMobileTable {
	IndexKey: string;
	IndexValue?: number;
}

export const StatisticsMobileViewColumns: ITableColumn<IStatisticsMobileTable>[] =
	[
		{ header: 'IndexKey', accessor: 'IndexKey' },
		{ header: 'IndexValue', accessor: 'IndexValue' },
	];

export const StatisticsXidMapping = [
	{
		xid: 592477,
		ticker: 'US&AYHI',
		indexName: EStatisticsIndices.AMEX,
	},
	{
		xid: 19892935,
		ticker: 'US&AYLO',
		indexName: EStatisticsIndices.AMEX,
	},
	{
		xid: 7780680,
		ticker: 'US&ADVA',
		indexName: EStatisticsIndices.AMEX,
	},
	{
		xid: 7780688,
		ticker: 'US&DECA',
		indexName: EStatisticsIndices.AMEX,
	},
	{
		xid: 10545163,
		ticker: 'US&UNCA',
		indexName: EStatisticsIndices.AMEX,
	},
	{
		xid: 19892936,
		ticker: 'US&DYHI',
		indexName: EStatisticsIndices.DJIA,
	},
	{
		xid: 19892937,
		ticker: 'US&DYLO',
		indexName: EStatisticsIndices.DJIA,
	},
	{
		xid: 19892938,
		ticker: 'US&DADV',
		indexName: EStatisticsIndices.DJIA,
	},
	{
		xid: 19892941,
		ticker: 'US&DDEC',
		indexName: EStatisticsIndices.DJIA,
	},
	{
		xid: 19892945,
		ticker: 'US&DUNC',
		indexName: EStatisticsIndices.DJIA,
	},
	{
		xid: 17826014,
		ticker: 'US&QYHI',
		indexName: EStatisticsIndices.NASDAQ,
	},
	{
		xid: 17826017,
		ticker: 'US&QYLO',
		indexName: EStatisticsIndices.NASDAQ,
	},
	{
		xid: 10025200,
		ticker: 'US&ADVQ',
		indexName: EStatisticsIndices.NASDAQ,
	},
	{
		xid: 10025201,
		ticker: 'US&DECQ',
		indexName: EStatisticsIndices.NASDAQ,
	},
	{
		xid: 10546650,
		ticker: 'US&UNCQ',
		indexName: EStatisticsIndices.NASDAQ,
	},
	{
		xid: 20573987,
		ticker: 'US&NYHI',
		indexName: EStatisticsIndices.NYSE,
	},
	{
		xid: 20573986,
		ticker: 'US&NYLO',
		indexName: EStatisticsIndices.NYSE,
	},
	{
		xid: 10025197,
		ticker: 'US&ADVN',
		indexName: EStatisticsIndices.NYSE,
	},
	{
		xid: 10025198,
		ticker: 'US&DECN',
		indexName: EStatisticsIndices.NYSE,
	},
	{
		xid: 10544868,
		ticker: 'US&UNCN',
		indexName: EStatisticsIndices.NYSE,
	},
];
