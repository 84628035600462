import React, { useEffect } from 'react';
import './message-box.scss';
import { CloseIcon } from '../../assets/Icons';

export enum MessageBoxStateType {
	Success = 'success',
	Fail = 'fail',
	Warning = 'warning',
	AdditionalSuccess = 'additional-sucsess',
}

interface MessageBoxProps {
	content: React.ReactNode;
	state?:
		| MessageBoxStateType.Success
		| MessageBoxStateType.Fail
		| MessageBoxStateType.Warning
		| MessageBoxStateType.AdditionalSuccess;
	enableCloseIcon?: boolean;
	onClose?: (e?: any) => void;
	autoHide?: boolean;
	timeAutoHide?: number;
}

const MessageBox: React.FC<MessageBoxProps> = ({
	content,
	state = MessageBoxStateType.Fail,
	enableCloseIcon,
	onClose,
	timeAutoHide,
	autoHide = false,
}) => {
	useEffect(() => {
		if (autoHide) {
			const timeout = setTimeout(() => {
				handleClose();
			}, timeAutoHide || 3000);

			return () => clearTimeout(timeout);
		}
	}, [autoHide, onClose]);

	const handleClose = () => {
		if (onClose) {
			onClose();
		}
	};

	return (
		<div
			className={`message-box ${state}`}
			data-testid="tstMessageBox"
			tabIndex={0}
		>
			<div className={`message-box-content ${state}-content`}>{content}</div>
			{enableCloseIcon && (
				<button className={'close-button'} onClick={handleClose}>
					<CloseIcon />
				</button>
			)}
		</div>
	);
};

export default MessageBox;
