import React from 'react';
import { Modal, ModalProps, Button, ButtonVariant } from '../';
import './confirm-dialog.scss';

export interface ConfirmationDialogProps extends ModalProps {
	onConfirm: () => void;
	onCancel: () => void;
	cancelButtonText?: string;
	confirmButtonText?: string;
	dataClass?: string;
}

const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({
	title,
	onConfirm,
	onCancel,
	cancelButtonText = 'Cancel',
	confirmButtonText = 'Confirm',
	children,
	footer,
	dataClass,
	id = '',
}) => {
	const handleCancel = () => {
		onCancel?.();
	};

	const handleConfirm = () => {
		onConfirm?.();
	};

	const dialogFooter = (
		<>
			<Button onClick={handleCancel} variant={ButtonVariant.Secondary}>
				{cancelButtonText}
			</Button>
			<Button onClick={handleConfirm}>{confirmButtonText}</Button>
		</>
	);

	return (
		<div
			className={`confirm-dialog-container`}
			data-class={dataClass}
			data-testid="confirm-dialog-container"
		>
			<Modal
				title={title}
				onClose={handleCancel}
				footer={footer || dialogFooter}
				id={id}
			>
				{children}
			</Modal>
		</div>
	);
};

export default ConfirmationDialog;
