import { IXrefResponseItem } from '../../types/interfaces';

export enum E_SecurityType {
	equity = 'Equity',
	mf = 'MF',
	etf = 'ETF',
}

export const SnapshotTabNames = {
	Overview: 'Overview',
	Charts: 'Charts',
	News: 'News',
	Options: 'Options',
};

export const SecurityType: { [key: string]: string } = {
	Equity: 'Equity',
	MF: 'Mutual Fund',
	ETF: 'ETF',
};

export const XREF_MOCK_DATA: IXrefResponseItem = {
	classification: {
		id: 3,
		name: 'Equity',
	},
	currencyIso: 'USD',
	exchange: {
		id: 840,
		name: 'NASDAQ',
		code: 'NSQ',
		countryIso: 'US',
	},
	xids: {
		entity: 204156539,
		instrument: 204298893,
		regional: 204990542,
		venue: 205778,
		legacyCompany: 31003,
	},
	symbol: 'MSFT',
	name: 'Microsoft Corp',
	supplementalData: [
		{
			name: 'multexrepno',
			value: '57840',
		},
		{
			name: 'ric',
			value: 'MSFT.O',
		},
	],
};

export const XREF_OPTION_MOCK_DATA: IXrefResponseItem = {
	classification: {
		id: 2,
		name: 'Derivative',
	},
	currencyIso: 'USD',
	exchange: {
		id: 876,
		name: 'OPRA',
		code: 'OPQ',
		countryIso: 'US',
	},
	xids: {
		instrument: 866109512,
		venue: 866109514,
		optionUnderlyingVenue: 36276,
		entity: 204229369,
		legacyCompany: 29466,
		regional: 204984887,
	},
	symbol: 'AAPL  240405C00115000',
	name: 'AAPL Apr 05, 2024 $ 115.000 CALL',
	supplementalData: [
		{
			name: 'multexrepno',
			value: '05680',
		},
		{
			name: 'ric',
			value: 'AAPLD052411500.U',
		},
	],
};

export const WatchlistConstants = {
	AddToWatchlistText: 'Add to Watchlist',
	SuccessMessage: 'has been added to Watchlist.',
	AlertMessage:
		'Your Watchlist is full. Please delete a security before adding a new one.',
	Dismiss: 'Dismiss',
	CustomizeWatchlist: 'Customize Watchlist',
	AlreadyAdded: 'Added to Watchlist',
	WatchlistAlert: 'Watchlist Alert',
};

export interface ISnapshotHeaderDetails {
	companyName: string;
	exchange: string;
	symbol: string;
}

export const SNAPSHOT_TAB_SELECTED = 'SNAPSHOT_TAB_SELECTED';

export const MMFTypeConstants = {
	name: 'xtype',
	value: 'MMF',
};
