import { setAccessToken, printWindow } from '../../utilities/utils';
import ViewNewsModal from '../../shared/view-news/view-news-modal';
import './print-news-article.scss';

function PrintNewsArticle() {
	const url = new URL(window.location.href);
	const token = window.atob(url.searchParams.get('t') ?? '');
	const articleId = window.atob(url.searchParams.get('a') ?? '');
	const provider = window.atob(url.searchParams.get('p') ?? '');
	const timestamp = window.atob(url.searchParams.get('ts') ?? '');
	setAccessToken(token);

	const onPrint = () => {
		printWindow();
	};

	return (
		<ViewNewsModal
			articleId={articleId}
			provider={provider}
			timeStamp={timestamp}
			onLoad={onPrint}
			isPrintMode={true}
		/>
	);
}

export default PrintNewsArticle;
