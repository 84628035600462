import { useEffect, useState } from 'react';
import {
	getAccessToken,
	isInternalEnv,
	setAccessToken,
	deleteAccessToken,
	setSessionStorage,
	getSessionStorageItem,
} from '../../utilities/utils';
import { ACCESS_TOKEN_EXPIRE_TIME } from '../../constants/appConstants';
import { Heading } from '../../@core-ui/Heading/heading';

import './home.scss';

function HomePage() {
	const [token, setToken] = useState('');
	const [isTokenValid, setIsTokenValid] = useState(false);
	const [accessTokenStatus, setAccessTokenStatus] = useState('TOKEN_NOT_FOUND');

	useEffect(() => {
		const tempToken = getAccessToken();
		setToken(tempToken);
		checkTokenStatus(tempToken);
	}, []);

	const checkTokenStatus = (token = '') => {
		setIsTokenValid(false);
		const expireTime = getSessionStorageItem(ACCESS_TOKEN_EXPIRE_TIME);

		const sideBar: any = document.getElementById('sideBar');
		sideBar.style.pointerEvents = 'none';
		sideBar.style.opacity = '0.5';

		if (token) {
			const timeNow = new Date();

			if (timeNow < expireTime) {
				setIsTokenValid(true);
				setAccessTokenStatus('VALID');

				sideBar.style.pointerEvents = 'all';
				sideBar.style.opacity = '1';
			} else {
				setAccessTokenStatus('TOKEN_EXPIRED');
			}
		} else {
			setAccessTokenStatus('TOKEN_NOT_FOUND');
		}
	};

	const setTokenHandler = () => {
		if (!token) {
			alert('Please enter a valid access token!');
			return;
		}

		setAccessToken(token);
		setSessionStorage(
			ACCESS_TOKEN_EXPIRE_TIME,
			new Date().setMinutes(120).toString(),
		);
		checkTokenStatus(token);
	};

	const deleteTokenHandler = () => {
		setToken('');
		deleteAccessToken();
		checkTokenStatus();
	};

	return (
		<div className="home-page-container">
			<Heading
				type="h1"
				testId="pageHeading"
				content="Welcome to Web Components Test Center!"
			/>

			<input
				type="text"
				value={token || ''}
				data-testid="txtToken"
				placeholder="Please enter a valid access token"
				onChange={(e: any) => {
					setToken(e.target.value);
				}}
			/>
			<br />
			<input
				type="button"
				data-testid={'set-access-token'}
				value={'Set Access Token'}
				onClick={setTokenHandler}
			/>
			<br />
			<input
				type="button"
				value={'Delete Access Token'}
				onClick={deleteTokenHandler}
				style={{ background: 'maroon' }}
			/>
			<br />

			<div
				id="lblAccessTokenStatus"
				className={isTokenValid ? 'valid-token' : 'invalid-token'}
			>
				Token Status: {accessTokenStatus}
			</div>

			{isInternalEnv() && (
				<>
					<div className="separator">----------------OR----------------</div>
					<a href="./internal/">Go to MD Internal Jump Page</a>
				</>
			)}
		</div>
	);
}

export default HomePage;
