import './divider.scss';
interface DividerProps {
	size?: 'xs' | 's' | 'm' | 'l' | 'xl';
}

export const Divider = (props: DividerProps) => {
	const { size } = props;
	const sizeClass: string = size ? 'divider-' + size : '';

	return <span className={`divider ${sizeClass} `}>|</span>;
};
