import React from 'react';
import { IBasicIconProps } from '../IBasicIconProps';

const InfoIcon: React.FC<IBasicIconProps> = ({
	width = 22,
	height = 22,
	color = '#000000',
}) => (
	<svg
		width={width}
		height={height}
		viewBox="0 0 22 22"
		version="1.1"
		xmlns="http://www.w3.org/2000/svg"
	>
		<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
			<g
				transform="translate(-306.000000, -241.000000)"
				fill={color}
				fillRule="nonzero"
			>
				<g transform="translate(306.000000, 241.000000)">
					<path d="M11,1.5 C14.8622025,1.5 18.3433366,3.8287321 19.8175729,7.39849901 C21.2918092,10.9682659 20.4682491,15.0747094 17.7315281,17.7999556 C14.9948072,20.5252018 10.8849396,21.3315008 7.32139848,19.8422786 C3.75785732,18.3530563 1.44380628,14.8621686 1.45991555,11 C1.48748911,5.7491159 5.74904675,1.50542643 11,1.5 M11,0 C4.92486775,0 0,4.92486775 0,11 C0,17.0751322 4.92486775,22 11,22 C17.0751322,22 22,17.0751322 22,11 C22,4.92486775 17.0751322,0 11,0 Z"></path>
					<path d="M11,5.83 C10.2323242,5.83 9.61,6.4523242 9.61,7.22 C9.61,7.9876758 10.2323242,8.61 11,8.61 C11.7676758,8.61 12.39,7.9876758 12.39,7.22 C12.39,6.4523242 11.7676758,5.83 11,5.83 L11,5.83 Z"></path>
					<path d="M11,9.61 C10.2323242,9.61 9.61,10.2323242 9.61,11 C9.61,11.7676758 10.2323242,12.39 11,12.39 C11.7676758,12.39 12.39,11.7676758 12.39,11 C12.39,10.2323242 11.7676758,9.61 11,9.61 L11,9.61 Z"></path>
					<path d="M11,13.39 C10.2323242,13.39 9.61,14.0123242 9.61,14.78 C9.61,15.5476758 10.2323242,16.17 11,16.17 C11.7676758,16.17 12.39,15.5476758 12.39,14.78 C12.39,14.0123242 11.7676758,13.39 11,13.39 L11,13.39 Z"></path>
				</g>
			</g>
		</g>
	</svg>
);

export default InfoIcon;
