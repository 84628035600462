import React from 'react';
import { IBasicIconProps } from '../IBasicIconProps';

const ArrowRightIcon: React.FC<IBasicIconProps> = ({
	width = 16,
	height = 17,
	color = '#ffffff',
}) => (
	<svg
		width={width}
		height={height}
		viewBox="0 0 16.7016847 13.1775785"
		version="1.1"
		xmlns="http://www.w3.org/2000/svg"
		xmlnsXlink="http://www.w3.org/1999/xlink"
	>
		<g
			stroke="none"
			strokeWidth="1"
			fill="none"
			fillRule="evenodd"
			strokeLinecap="round"
			strokeLinejoin="round"
		>
			<g
				id="Markets---Watchlist-Change-View---Final"
				transform="translate(-693.150000, -131.399150)"
				stroke={color}
				strokeWidth="1.7"
			>
				<g
					id="Tabs-and-page-level-buttons"
					transform="translate(62.000000, 120.000000)"
				>
					<g id="Search-Bar" transform="translate(383.000000, 0.000000)">
						<g id="icon-+-button" transform="translate(231.000000, 0.000000)">
							<g
								id="arrow-withtail-right"
								transform="translate(18.000000, 12.000000)"
							>
								<path
									d="M15,5.98793963 L0,5.98793963 L5.36675462,0.24915335 M5.36675462,11.7267259 L0,5.98793963"
									transform="translate(7.500000, 5.987940) scale(-1, 1) translate(-7.500000, -5.987940) "
								></path>
							</g>
						</g>
					</g>
				</g>
			</g>
		</g>
	</svg>
);

export default ArrowRightIcon;
