export enum E_SearchTypes {
	Symbol = 'symbol',
	Name = 'name',
	CUSIP = 'cusip',
}

export enum E_SecurityTypes {
	All = -1,
	Equities = 3,
	MFs = 5,
	Options = 2,
	ETFs = 18,
	Debt = 1,
	CloseEndFunds = 84,
	Insurance = 8,
	Index = 7,
	ETMF = 83,
}

export const SYMBOL_SEARCH_TYPE_DROPDOWN_OPTIONS = [
	{ key: E_SearchTypes.Symbol, value: 'Symbol' },
	{ key: E_SearchTypes.Name, value: 'Name' },
	{ key: E_SearchTypes.CUSIP, value: 'CUSIP' },
];

export const SECURITY_TYPES_DROPDOWN_OPTIONS = [
	{ key: E_SecurityTypes.All, value: 'All Security Types' },
	{ key: E_SecurityTypes.Equities, value: 'Equities Only' },
	{ key: E_SecurityTypes.MFs, value: 'Mutual Funds Only' },
	{ key: E_SecurityTypes.Options, value: 'Options Only' },
	{ key: E_SecurityTypes.ETFs, value: 'ETFs Only' },
];
export const NoSearchResultsFoundByName = `We couldn't find any symbols that match exactly what you're looking for. Please try searching by Name instead.`;

export const NoSearchResultsFound = `We couldn't find any symbols that match exactly what you're looking for.`;

export const EmptySymbolSearched =
	'Please enter search criteria. You can search by Symbol, Name, or CUSIP.';
