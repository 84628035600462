import React from 'react';
import { IBasicIconProps } from '../IBasicIconProps';

const CloseIcon: React.FC<IBasicIconProps> = ({
	width = 13,
	height = 13,
	color = '#2B6D9F',
}) => (
	<svg
		width={width}
		height={height}
		viewBox="0 0 14.9364277 15.0001263"
		version="1.1"
		xmlns="http://www.w3.org/2000/svg"
	>
		<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
			<g
				transform="translate(-791.000000, -190.999940)"
				fill={color}
				fillRule="nonzero"
			>
				<path
					d="M791.608214,191 C791.446479,190.999655 791.291428,191.06456 791.178214,191.180063 C790.940595,191.421388 790.940595,191.808738 791.178214,192.050063 L797.178214,198.050063 C797.294287,198.163018 797.359764,198.318101 797.359764,198.480063 C797.359764,198.642025 797.294287,198.797108 797.178214,198.910063 L791.178214,204.910063 C790.940595,205.151388 790.940595,205.538738 791.178214,205.780063 C791.286131,205.902887 791.435797,205.981284 791.598214,206.000065 C791.759949,206.000471 791.914999,205.935566 792.028214,205.820063 L798.028214,199.820063 C798.145138,199.703983 798.303456,199.639217 798.468214,199.640063 C798.626946,199.640063 798.778743,199.705119 798.888214,199.820063 L804.888214,205.820063 C805.00743,205.932635 805.16428,205.996801 805.328214,206.000065 C805.489949,206.000471 805.644999,205.935566 805.758214,205.820063 C805.995832,205.578738 805.995832,205.191388 805.758214,204.950063 L799.758214,198.950063 C799.642141,198.837108 799.576663,198.682025 799.576663,198.520063 C799.576663,198.358101 799.642141,198.203018 799.758214,198.090063 L805.758214,192.090063 C805.995832,191.848738 805.995832,191.461388 805.758214,191.220063 C805.649708,191.097989 805.500349,191.019753 805.338214,191 C805.180032,190.998422 805.026848,191.055421 804.908214,191.160063 L798.908214,197.160063 C798.79129,197.276143 798.632971,197.340909 798.468214,197.340063 C798.309481,197.340063 798.157685,197.275007 798.048214,197.160063 L792.048214,191.160063 C791.926027,191.054753 791.769505,190.997836 791.608214,191 Z"
					id="close-icon-"
				></path>
			</g>
		</g>
	</svg>
);

export default CloseIcon;
