import { ITableColumn } from '../../../../../@core-ui/Table/table';

export interface IOptionSymbol {
	Component: string;
	Value: string;
	LocationInSymbol: React.JSX.Element;
}

export const OptionsSymbologyConstants = {
	StaticContent: `The components of an options symbol are:
			<br/>
			<br/>
			<span>Symbol</span> (ticker symbol) + <span>Expiration Date</span> (yy/mm/dd) + <span>Call/Put Indicator</span> (C or P) + <span>Strike Price.</span>
			<br/>
			<br/>`,
	OptionsSymbol: `OPY230519C00030000`,
	TableContent: [
		{
			Component: 'Root Symbol',
			Value: 'OPY',
			LocationInSymbol: (
				<>
					<span>OPY</span>230519C00030000
				</>
			),
		},
		{
			Component: 'Expiration Year',
			Value: '2023',
			LocationInSymbol: (
				<>
					OPY<span>23</span>0519C00030000
				</>
			),
		},
		{
			Component: 'Expiration Month',
			Value: '05',
			LocationInSymbol: (
				<>
					OPY23<span>05</span>19C00030000
				</>
			),
		},
		{
			Component: 'Expiration Day',
			Value: '19',
			LocationInSymbol: (
				<>
					OPY2305<span>19</span>C00030000
				</>
			),
		},
		{
			Component: 'Call or Put',
			Value: 'Call',
			LocationInSymbol: (
				<>
					OPY230519<span>C</span>00030000
				</>
			),
		},
		{
			Component: '*Strike Price',
			Value: '$30.00',
			LocationInSymbol: (
				<>
					OPY230519C<span>00030000</span>
				</>
			),
		},
	],
	DecimalPointNote: `*decimal point falls 3 places from the right`,
	OICNote: `Visit The <a  href="https://www.optionseducation.org/theoptionseducationcenter/occ-learning" target="_blank" rel="noopener">Options Industry Council</a> for more information.`,
};

export interface ISymbologyOptionTable {
	Component: string;
	Value: string;
	LocationInSymbol: React.ReactNode;
}

export const SymbologyOptionColumns: ITableColumn<ISymbologyOptionTable>[] = [
	{ header: 'Component', accessor: 'Component' },
	{ header: 'Value', accessor: 'Value' },
	{ header: 'Location In Symbol', accessor: 'LocationInSymbol' },
];
