import DataRequest from './dataRequest';
import URLS from '../constants/urlConstants';
import { IQuoteResponse } from '../types/interfaces';
import { resolvePromiseWithError } from '../utilities/utils';

export const getQuotes = async (
	venueXids: number[] | string,
	isRealTime = false,
	navNotYield = false,
): Promise<IQuoteResponse> => {
	if (!venueXids.length) {
		return await resolvePromiseWithError();
	}
	return await DataRequest.execute(
		URLS.PLATFORM.QUOTE,
		{
			params: {
				venueXids: venueXids,
				marketHoursTradeType: 'normal',
				clearPremarketQuote: true,
				navNotYield: navNotYield,
			},
		},
		{
			realtime: isRealTime || false,
		},
	);
};
