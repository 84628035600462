/* eslint-disable indent */
/* eslint-disable react/no-unknown-property */
import {
	useState,
	useEffect,
	useContext,
	createContext,
	useMemo,
	useRef,
} from 'react';
import { Tabs, Button, Container } from '../../../@core-ui';
import { RefreshIcon, HelpIcon } from '../../../assets/Icons';
import { SearchBox } from '../../shared/search';
import { getDeviceType, scrollToTop } from '../../../utilities/utils';
import MarketsIndices from '../../markets/markets-indices';
import TodaysPerformers from '../../welcome-page/todays-performers/todays-performers';
import { TabNames } from './quotes-news-container-constants';
import { E_DeviceType } from '../../../constants/appConstants';
import Statistics from '../statistics/statistics';
import {
	EVENTS_NAME,
	dispatchSymbolChangedCustomEvent,
	registerCustomEventHandler,
} from '../../../utilities/events';
import { IXrefResponseItem } from '../../../types/interfaces';
import { SnapshotTabNames } from '../../snapshots/snapshot-constants';
import NewsTab from '../../news/news-tab/news-tab';
import SnapshotContainer from '../../snapshots/snapshot-container';
import PortfolioContainer from '../portfolio/portfolio-container';
import { WatchlistsView, CustomizeWatchlist } from '../../watchlists';
import Glossary from '../../glossary/glossary';
import './quotes-news-container.scss';
import BackLink from './../../../shared/back-link/back-link';

const QuotesNewsContext = createContext<any>(null);
export const useQuotesNewsContext = () => useContext(QuotesNewsContext);

const QuotesNewsContainer = () => {
	const [activeSelectedTab, setActiveSelectedTab] = useState(TabNames.Markets);
	const [activeHiddenTab, setActiveHiddenTab] = useState('');
	const [isWatchlistDrawerOpen, setIsWatchlistDrawerOpen] = useState(false);
	const [hideWatchlist, setHideWatchListsView] = useState(false);
	const [snapshotData, setSnapshotData] = useState<any>();
	const [snapshotTab, setSnapshotTab] = useState(SnapshotTabNames.Overview);
	const [showWorldIndicesTab, setShowWorldIndicesTab] = useState(false);
	const [IsSnapshotBackButtonVisible, SetSnapshotBackButtonVisible] =
		useState(true);
	const [portfolioState, setPortfolioState] = useState('');
	const device = getDeviceType();
	const selectedTabsState = useRef({
		prvActiveTab: TabNames.Markets,
		glossaryReferrerTab: TabNames.Markets,
	});
	const [isRefreshKey, setIsRefreshKey] = useState<number>(0);
	const [isRefreshClicked, setIsRefreshClicked] = useState<boolean>();
	const isMobileView = getDeviceType() === E_DeviceType.Mobile;

	const worldIndicesValues = useMemo(
		() => ({ showWorldIndicesTab, setShowWorldIndicesTab }),
		[showWorldIndicesTab, setShowWorldIndicesTab],
	);

	useEffect(() => {
		registerCustomEventHandler(
			EVENTS_NAME.MD_SYMBOL_CHANGED_EVENT,
			(data: IXrefResponseItem) => {
				setSnapshotData(data);
				onCloseViewWatchlistHandler();
				setActiveSelectedTab(TabNames.SnapshotContainer);
				setSnapshotTab(SnapshotTabNames.Overview);
				setSelectedTabsState(selectedTabsState.current.prvActiveTab);
				scrollToTop();
			},
		);

		registerCustomEventHandler(
			EVENTS_NAME.MD_GO_TO_NEWS_PAGE_EVENT,
			(data: IXrefResponseItem) => {
				setSnapshotData(data);
				setActiveSelectedTab(TabNames.SnapshotContainer);
				setSnapshotTab(SnapshotTabNames.News);
			},
		);

		registerCustomEventHandler(
			EVENTS_NAME.MD_GO_TO_MARKET_INDICES_TAB_EVENT,
			() => {
				setActiveSelectedTab(TabNames.Markets);
				onCloseViewWatchlistHandler();
			},
		);
	}, []);

	const setSelectedTabsState = (
		prvActiveTab?: string,
		glossaryReferrerTab?: string,
	) => {
		selectedTabsState.current = {
			...selectedTabsState.current,
			...(prvActiveTab && { prvActiveTab: prvActiveTab }),
			...(glossaryReferrerTab && { glossaryReferrerTab: glossaryReferrerTab }),
		};
	};
	useEffect(() => {
		if (activeHiddenTab === TabNames.CustomizeWatchlist) {
			setHideWatchListsView(true);
		}
	});

	const clickRefreshHandler = () => {
		setIsRefreshKey((isRefreshKey) => isRefreshKey + 1);
		setIsRefreshClicked(true);
	};

	const onGlossaryHandler = () => {
		if (activeSelectedTab !== TabNames.Help) {
			setSelectedTabsState('', activeSelectedTab);
			setActiveSelectedTab(TabNames.Help);
			setHideWatchListsView(true);
		}
	};

	const glossaryBackButtonHandler = () => {
		setActiveSelectedTab(selectedTabsState.current.glossaryReferrerTab);
		setHideWatchListsView(false);
	};

	const snapshotBackButton = () => {
		setActiveSelectedTab(selectedTabsState.current.prvActiveTab);
		setHideWatchListsView(false);
	};

	const onCustomizeWatchlistHandler = () => {
		setActiveHiddenTab(TabNames.CustomizeWatchlist);
		setHideWatchListsView(true);
		setIsWatchlistDrawerOpen(false);
	};

	const onCloseViewWatchlistHandler = () => {
		setHideWatchListsView(false);
		setActiveHiddenTab('');
	};

	const handleToggleDrawer = () => {
		setIsWatchlistDrawerOpen(!isWatchlistDrawerOpen);
	};

	const snapshotWatchlistHandler = (show: boolean) => {
		setActiveHiddenTab(TabNames.CustomizeWatchlist);
		setHideWatchListsView(show);
	};

	const getSearchBox = () => (
		<div className={'searchbox'}>
			<SearchBox
				placeholder="Enter Symbol to Get Quote"
				onAddSymbol={dispatchSymbolChangedCustomEvent}
				closeOnSymbolClick={true}
			/>
		</div>
	);

	const renderTabs = () => (
		<div className={'tab-headers'}>
			<Tabs
				id={'quote-new-header-tab'}
				ariaLabel={activeSelectedTab}
				activeTab={activeSelectedTab}
				onClick={(k: any) => {
					setHideWatchListsView(false);
					setActiveSelectedTab(k);
					setSelectedTabsState(k);
					setPortfolioState('');
				}}
			>
				<div key="Markets" data-label="Markets" enable-divider={true}></div>
				<div key="News" data-label="News" enable-divider={true}></div>
				<div
					key="Portfolio Tracker"
					data-label="Portfolio Tracker"
					enable-divider={device !== E_DeviceType.Mobile}
				></div>
			</Tabs>
			{device !== E_DeviceType.Mobile && getSearchBox()}
		</div>
	);

	const renderBody = () => {
		let content = null;
		switch (activeSelectedTab) {
			case TabNames.Help:
				content = (
					<>
						<BackLink
							onClick={glossaryBackButtonHandler}
							testId={`back-button`}
						/>
						<Glossary key={isRefreshKey} />
					</>
				);
				break;
			case TabNames.Markets:
			case TabNames.News:
			case TabNames.PortfolioTracker:
			case TabNames.SnapshotContainer:
			case TabNames.ViewOptionProfile:
				if (activeHiddenTab === TabNames.CustomizeWatchlist) {
					content = (
						<CustomizeWatchlist
							onClose={onCloseViewWatchlistHandler}
							key={isRefreshKey}
						/>
					);
				} else if (activeSelectedTab === TabNames.Markets) {
					content = getMarketIndicesContent();
				} else if (activeSelectedTab === TabNames.News) {
					content = getNewsContent();
				} else if (activeSelectedTab === TabNames.PortfolioTracker) {
					content = getPortfolioContent();
				} else if (activeSelectedTab === TabNames.SnapshotContainer) {
					content = (
						<>
							{IsSnapshotBackButtonVisible && (
								<BackLink
									onClick={snapshotBackButton}
									testId={`snapshot-back-button`}
								/>
							)}
							{device === E_DeviceType.Mobile && getSearchBox()}
							<SnapshotContainer
								xrefResponse={snapshotData}
								watchlistHandler={snapshotWatchlistHandler}
								tab={snapshotTab}
								setTab={setSnapshotTab}
								key={isRefreshKey}
								isRefreshClicked={isRefreshClicked}
								setIsRefreshClicked={setIsRefreshClicked}
								setBackButtonVisible={SetSnapshotBackButtonVisible}
							/>
						</>
					);
				}
				break;
			default:
				break;
		}
		return content;
	};

	const getMarketIndicesContent = () => (
		<div className={'markets-container'}>
			{device === E_DeviceType.Mobile && getSearchBox()}
			<div className={'markets-indices-container'}>
				<MarketsIndices
					key={isRefreshKey}
					isRefreshClicked={isRefreshClicked}
					setIsRefreshClicked={setIsRefreshClicked}
				/>
			</div>
			{device !== E_DeviceType.Mobile && (
				<>
					<div className={'markets-todays-performers'}>
						<Container applyPadding applyBorder applyWhiteBackground>
							<TodaysPerformers
								key={isRefreshKey}
								showHeading={!isMobileView}
							/>
						</Container>
					</div>
					<div className={'markets-statistics'}>
						<Statistics key={isRefreshKey} />
					</div>
				</>
			)}
		</div>
	);
	const getNewsContent = () => (
		<div className={'news-container'}>
			{device === E_DeviceType.Mobile && getSearchBox()}
			<NewsTab key={isRefreshKey} />
		</div>
	);
	const getPortfolioContent = () => (
		<div className={'portfolio-container'}>
			{device === E_DeviceType.Mobile && getSearchBox()}
			<PortfolioContainer
				key={isRefreshKey}
				porfolioIdState={portfolioState}
				setPortfolioState={setPortfolioState}
			/>
		</div>
	);

	const renderBodyContainer = (classNames: string) => {
		const isDesktop = device === E_DeviceType.Desktop;
		return (
			<div className={`quotes-news-body ${classNames}`}>
				<div className={`${isDesktop ? 'left-container' : 'container'}`}>
					{renderBody()}
				</div>
				{!hideWatchlist && (
					<div className={`${isDesktop ? 'right-container' : ''}`}>
						<WatchlistsView
							customizeWatchlist={onCustomizeWatchlistHandler}
							handleToggleDrawer={handleToggleDrawer}
							isWatchlistDrawerOpen={isWatchlistDrawerOpen}
							key={isRefreshKey}
						/>
					</div>
				)}
			</div>
		);
	};

	const renderBodyByDevice = () => {
		const ipadCondition = device === E_DeviceType.Ipad ? 'ipad-view' : '';
		const classNames =
			device === E_DeviceType.Mobile ? 'mobile-view' : ipadCondition;
		return renderBodyContainer(
			`${classNames} ${hideWatchlist ? 'no-watchlist-view' : ''}`,
		);
	};

	const helpRefreshJSX = (
		<>
			<Button className={'tab-button'} onClick={clickRefreshHandler}>
				<RefreshIcon />
				<span>Refresh</span>
			</Button>
			<Button
				testId={'glossary-help'}
				className={'tab-button'}
				onClick={onGlossaryHandler}
			>
				<HelpIcon />
				<span>Help</span>
			</Button>
		</>
	);

	const getBody = () => (
		<div
			className={
				'quotes-news ' + (isWatchlistDrawerOpen ? 'watchlist-overlay-on' : '')
			}
		>
			<div className={'watchlist-overlay'}></div>
			{device !== E_DeviceType.Desktop && (
				<div className={'help-Refresh'}>{helpRefreshJSX}</div>
			)}
			<div className={'tabs-container'}>
				<div className={'left-side-header'}>{renderTabs()}</div>
				<div className={'right-side-header'}>
					{device === E_DeviceType.Desktop && helpRefreshJSX}
				</div>
			</div>
			{renderBodyByDevice()}
		</div>
	);

	return (
		<QuotesNewsContext.Provider value={worldIndicesValues}>
			{getBody()}
		</QuotesNewsContext.Provider>
	);
};

export default QuotesNewsContainer;
