import React from 'react';
import {
	ISymbologyOptionTable,
	OptionsSymbologyConstants,
	SymbologyOptionColumns,
} from './options-symbology-constants';
import { Table } from '../../../../../@core-ui';
import './options-symbology.scss';

const OptionsSymbology = () => {
	const {
		StaticContent,
		DecimalPointNote,
		TableContent,
		OICNote,
		OptionsSymbol,
	} = OptionsSymbologyConstants;
	return (
		<div className="options-symbology">
			<div
				className="static-content"
				dangerouslySetInnerHTML={{ __html: StaticContent }}
			></div>
			<div className="options-symbol">
				<div className="options-symbol-header">
					<span className="options-symbol-title">Options Symbol:</span>
					<span className="options-symbol-value"> {OptionsSymbol}</span>
				</div>
				<div className="options-symbol-table-container">
					<Table
						data={TableContent as ISymbologyOptionTable[]}
						columns={SymbologyOptionColumns}
						className="options-symbol-table"
						showHorizontalBorder
						showVerticalBorder
					/>
					<div className="decimal-point-note">{DecimalPointNote}</div>
				</div>
			</div>
			<div
				className="oic-note"
				dangerouslySetInnerHTML={{ __html: OICNote }}
			></div>
		</div>
	);
};

export default OptionsSymbology;
