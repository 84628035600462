export const NasdaqTableContent = [
	{ Type: 'Class A', Append: 'A', Example: 'MSFTA' },
	{ Type: 'Class B', Append: 'B', Example: 'MSFTB' },
	{
		Type: 'Issuer qualifications exceptions - issuer granted a continuance in NSDAQ under an exception to the qualification standards for a limited period',
		Append: 'C',
		Example: 'MSFTC',
	},
	{ Type: 'New', Append: 'D', Example: 'MSFTD' },
	{
		Type: 'Issuer is delinquent in its required SEC filings',
		Append: 'E',
		Example: 'MSFTE',
	},
	{ Type: 'Foreign', Append: 'F', Example: 'MSFTF' },
	{ Type: 'First convertible bond', Append: 'G', Example: 'MSFTG' },
	{
		Type: 'Second convertible bond, same company',
		Append: 'H',
		Example: 'MSFTH',
	},
	{
		Type: 'Third convertible bond, same company',
		Append: 'I',
		Example: 'MSFTI',
	},
	{ Type: 'Voting', Append: 'J', Example: 'MSFTJ' },
	{ Type: 'Nonvoting', Append: 'K', Example: 'MSFTK' },
	{
		Type: 'Miscellaneous situations, such as depository receipts, stubs, additional warrants, and units',
		Append: 'L',
		Example: 'MSFTL',
	},
	{
		Type: 'Fourth preferred, same company',
		Append: 'M',
		Example: 'MSFTM',
	},
	{
		Type: 'Third preferred, same company',
		Append: 'N',
		Example: 'MSFTN',
	},
	{
		Type: 'Second preferred, same company',
		Append: 'O',
		Example: 'MSFTO',
	},
	{ Type: 'First preferred issue', Append: 'P', Example: 'MSFTP' },
	{
		Type: 'Bankruptcy - involved in bankruptcy proceedings',
		Append: 'Q',
		Example: 'MSFTQ',
	},
	{ Type: 'Rights', Append: 'R', Example: 'MSFTR' },
	{
		Type: 'Shares of beneficial interest',
		Append: 'S',
		Example: 'MSFTS',
	},
	{ Type: 'With warrants or with rights', Append: 'T', Example: 'MSFTT' },
	{ Type: 'Units', Append: 'U', Example: 'MSFTU' },
	{
		Type: 'When-issued and when-distributed',
		Append: 'V',
		Example: 'MSFTV',
	},
	{ Type: 'Warrants', Append: 'W', Example: 'MSFTW' },
	{
		Type: 'ADR (American Depository Receipts)',
		Append: 'Y',
		Example: 'MSFTY',
	},
	{
		Type: 'Miscellaneous situations, such as depository receipts, stubs, additional warrants, and units',
		Append: 'Z',
		Example: 'MSFTZ',
	},
];
