import React from 'react';
import { Table } from '../../../../@core-ui';
import './symbol-search-results.scss';
import {
	ISymbolSearchResultTable,
	SymbolSearchResultColumns,
} from './symbol-search-results-constants';

export interface ISymbolSearchResultsProps {
	data: ISymbolSearchResultTable[];
	currentPage: number;
	setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
}
const SymbolSearchResults: React.FC<ISymbolSearchResultsProps> = ({
	data = [],
	currentPage,
	setCurrentPage,
}) => {
	return (
		<div
			className="symbol-search-results-container"
			data-testid="tstSymbolSearchResultsContainer"
		>
			<Table
				data={data}
				columns={SymbolSearchResultColumns}
				className="symbol-search-results-table"
				currentPage={currentPage}
				onPageChange={setCurrentPage}
				showPagination
				showHorizontalBorder={false}
			/>
		</div>
	);
};

export default SymbolSearchResults;
