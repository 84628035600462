import React, { useState } from 'react';
import {
	GLOSSARY_HEADER,
	GLOSSARY_INSTRUCTION,
	NO_RESULT,
} from './glossary-constants';
import GLOSSARY_TERMS from './glossary.json';
import './glossary.scss';
import {
	Heading,
	LabelLink,
	Label,
	SingleSelect,
	MessageBox,
	Container,
} from '../../@core-ui';
import { SearchIcon, CloseCircelIcon } from '../../assets/Icons';
import TermList from './term-list';
import {
	getDeviceType,
	handleEnterKeyPress,
	getAllAlphabets,
	sortByKey,
} from '../../utilities/utils';
import { E_DeviceType } from '../../constants/appConstants';

interface GlossaryProps {
	term?: string;
}
const Glossary: React.FC<GlossaryProps> = ({ term }) => {
	const [selectedTerm, setSelectedTerm] = useState(term || 'A');
	const [query, setQuery] = useState('');
	const [termsShortNames] = useState(getAllAlphabets());

	const changeTermsHandler = (dataValue: any) => {
		clearInput();
		setSelectedTerm(dataValue);
	};
	const Pagination = () => {
		if (query === '') {
			return GLOSSARY_TERMS.glossary.filter((item) => {
				return item.def && item.termType == selectedTerm.toLowerCase();
			}).length;
		} else {
			return searchResults.length;
		}
	};
	const changeDropDown = (event: any) => {
		clearInput();
		setSelectedTerm(event.getAttribute('data-key'));
	};
	const clearInput = () => {
		setQuery('');
		setSelectedTerm('A');
	};
	const cancelQuery = () => {
		clearInput();
	};

	const searchTextboxChangeHandler = (e: any) => {
		if (e.target.value.length > 0) {
			setSelectedTerm('');
			setQuery(e.target.value);
		} else {
			clearInput();
		}
	};

	const searchResults = GLOSSARY_TERMS.glossary.filter((item) => {
		if (query === '') {
			return item.def && item;
		} else if (
			item.def &&
			item.term.toLowerCase().includes(query.toLowerCase())
		) {
			return item;
		}
	});

	const getGlossaryData = () => {
		return searchResults.sort(sortByKey('term'));
	};

	function bindGlossaryContent() {
		if (query !== '') {
			if (searchResults.length > 0) {
				const glossaryData = getGlossaryData();
				return <TermList glossaryData={glossaryData} />;
			} else {
				return <MessageBox content={NO_RESULT} />;
			}
		} else {
			return (
				<TermList
					glossaryData={GLOSSARY_TERMS.glossary.filter((item) => {
						return item.termType == selectedTerm.toLowerCase();
					})}
				/>
			);
		}
	}
	return (
		<Container id="glossary-container" applyPadding>
			{
				<React.Fragment>
					<header className={'header'}>
						<Heading
							type={'h1'}
							testId={'glossary-container'}
							content={GLOSSARY_HEADER}
						/>
					</header>

					<main className={'glossary-main-container'}>
						<div className={'glossary-searchbox'}>
							<input
								className={'searchbox'}
								autoComplete="off"
								type="text"
								id="search_glossary"
								onChange={searchTextboxChangeHandler}
								value={query}
								placeholder={'Search'}
								aria-label={'Search'}
							/>
							<React.Fragment>
								{query.length > 0 && (
									<span
										role="button"
										tabIndex={0}
										title="clear"
										className={'cancelButton'}
										onKeyDown={cancelQuery}
										onClick={cancelQuery}
									>
										<CloseCircelIcon />
									</span>
								)}
								<span
									role="button"
									tabIndex={0}
									title="clear"
									className={'clearButton'}
								>
									<SearchIcon />
								</span>
							</React.Fragment>
						</div>
						<div className={'glossary-instruction'}>
							{GLOSSARY_INSTRUCTION.map((item: any) => (
								<div className={'instruction'} key={`instruction-${item.key}`}>
									<Label isGrey={true} text={`${item.value}`} />
								</div>
							))}
						</div>
						<div className={'glossary-terms-shortname'}>
							{getDeviceType() === E_DeviceType.Mobile ? (
								<div className={'terms-shortname-dropDown'}>
									<SingleSelect
										data={termsShortNames}
										changeHandler={changeDropDown}
										selectedValue={selectedTerm}
									/>
								</div>
							) : (
								termsShortNames.map((item: any) => (
									<LabelLink
										size={'l'}
										testId={`glossary-${item.key}`}
										key={`glossary-${item.key}`}
										text={`${item.key}`}
										dataKey={item.key}
										className={
											selectedTerm == item.key ? 'labelLink-active' : ''
										}
										onClick={() => {
											changeTermsHandler(item.key);
										}}
										onKeyDown={(event) => {
											handleEnterKeyPress(event, () => {
												changeTermsHandler(item.key);
											});
										}}
									/>
								))
							)}
						</div>
						<div className={'glossary-term-container'}>
							{bindGlossaryContent()}
						</div>
						{query && (
							<div className={'glossary-pagination'}>{`Showing ${
								Pagination() || 0
							} Results`}</div>
						)}
					</main>
				</React.Fragment>
			}
		</Container>
	);
};

export default Glossary;
