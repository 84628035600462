export const GLOSSARY_HEADER = 'Glossary';
export const NO_RESULT =
	'There are no results that match your search terms. Please check the spelling or try browsing alphabetically.';

export const GLOSSARY_INSTRUCTION = [
	{
		key: '1',
		value:
			'To research a term, click the letter the term begins with at the top of the page (to the left).',
	},
	{
		key: '2',
		value:
			'This page will refresh with all terms in our glossary that begin with that letter.',
	},
];
