import { NEWS_QBID_ID } from './appConstants';

const URLS = {
	PLATFORM: {
		QUOTE: 'quote/v1/details',
		XREF_GET_BY_SYMBOL: 'xref/v1/symbols',
		XREF_GET_BY_PREDICTIVE_SEACRH: 'xref/v1/venues',
		XREF_GET_BY_VENUEXID: 'xref/v1/venue-xids',
		MARKET_RANKINGS: 'market-rankings/v1/detail',
		CHARTS_PRICE: 'charts-price/v1/price',
		NEWS: `news/v1/${NEWS_QBID_ID}/articles`,
		WATCHLIST: '/watchlist/v1/watchlist',
		USER_MGMT_PREFERENCES: 'user-management/v1/preferences',
		PORTFOLIO: `portfolio/v1/containers`,
		PORTFOLIO_ANALYSIS: `portfolio/v1/analysis/containers`,
		PORTFOLIO_HOLDINGS: `holdings`,
		PORTFOLIO_TRANSACTIONS: 'transactions',
		QUOTE_HISTORICAL: 'quote-historical/v1/details/',
		QUOTE_INTRADAY_HISTORICAL: 'quote-intraday-historical/v1/details/',
		CORPORATEACTIONS_DIVIDENDS: 'corporateactions-dividends/v1',
		CORPORATEACTIONS_SPLITS: 'corporateactions-splits/v1',
		EQUITIES_CURRENT_RATIOS:
			'equities-trkdfundamentals-currentratios/v1/currentratios',
		OPTIONS_CHAIN_WITH_QUOTES: 'optionchains/v1/options/chainwithquotes',
		OPTION_CHAINS_EXPIRATION_DATES: 'optionchains/v1/options/expirationdates',
		SINGLE_OPTION_WITH_GREEKS:
			'optionchainsgreeks/v1/options/singleoptionwithgreeks',
		PRICING_CURRENT_END_OF_DAY_STATS:
			'pricing-current-endofdaystats/v1/details',
	},
	CUSTOM: {
		PEERS: 'oppenheimer-research-api/v1/peers',
		LIPPER_FUND: 'oppenheimer-research-api/v1/lipperFund',
		GET_CHART_SETTING: 'oppenheimer-research-api/v1/getChartSettings',
		SET_CHART_SETTING: 'oppenheimer-research-api/v1/setChartSetting',
		PORTFOLIO_FUNDAMENTALS: 'oppenheimer-research-api/v1/portfolioFundamentals',
	},
};

export default URLS;
