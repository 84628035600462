import React from 'react';
import { IBasicIconProps } from '../IBasicIconProps';

const EditIcon: React.FC<IBasicIconProps> = ({
	width = 13,
	height = 13,
	color = '#2B6D9F',
}) => (
	<svg
		width={width}
		height={height}
		viewBox="0 0 21.0829492 22.07"
		version="1.1"
		xmlns="http://www.w3.org/2000/svg"
	>
		<g
			stroke="none"
			strokeWidth="1"
			fill="none"
			fillRule="evenodd"
			strokeLinecap="round"
			strokeLinejoin="round"
		>
			<g
				transform="translate(-875.000000, -413.000000)"
				stroke={color}
				strokeWidth="1.55"
			>
				<g transform="translate(84.000000, 255.000000)">
					<g transform="translate(0.000000, 158.000000)">
						<g transform="translate(791.101990, 0.000000)">
							<path d="M19.1126736,13.3191667 L19.1126736,21.1475 L1.75024483,21.1475 L1.75024483,3.135 L7.80609195,3.135 M6.05584712,15.6108333 L9.3813123,15.1525 L20.1978254,3.91416667 L17.3099214,0.916666667 L6.49340833,12.1825 L6.05584712,15.6108333 Z M14.8595786,3.465 L17.7299802,6.48083333 L14.8595786,3.465 Z"></path>
						</g>
					</g>
				</g>
			</g>
		</g>
	</svg>
);

export default EditIcon;
