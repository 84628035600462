export const NyseAmexTableContent = [
	{ Type: 'Class A', Append: 'a', Example: 'IBMa' },
	{ Type: 'Preferred', Append: '_p', Example: 'IBM_p' },
	{ Type: 'Preferred with Series', Append: '_pa', Example: 'IBM_pa' },
	{ Type: 'Units', Append: '_u', Example: 'IBM_u' },
	{ Type: 'Warrants', Append: '_t', Example: 'IBM_t' },
	{ Type: 'Warrants with Series', Append: '_ta', Example: 'IBM_ta' },
	{ Type: 'Rights when Issued', Append: '_r_w', Example: 'IBM_r_w' },
	{ Type: 'Rights', Append: '_r', Example: 'IBM_r' },
	{ Type: 'When Issued', Append: '_w', Example: 'IBM_w' },
	{ Type: 'When Distributed', Append: 'w', Example: 'IBMw' },
	{ Type: 'CI A When Distributed', Append: 'aw', Example: 'IBMaw' },
];
