import { TYPES_OF_ELEMENTS } from '../../../../constants/appConstants';
import {
	ICustomTableHeader,
	ICustomTableMapping,
} from '../../../../types/interfaces';

export const OverviewTitle: { [key: string]: string } = {
	Equity: 'Company Overview',
	MF: 'Mutual Fund Overview',
	ETF: 'Exchange Traded Fund  Overview',
};

export const SelectedChartPeriod = {
	Day: 'Intraday',
	Week: '1 Week',
};

export const EquityProfileKeys = {
	MarketCap: 'MarketCap',
	Revenue: 'Revenue',
	EPS: 'EPS',
	UpcomingDividendAmount: 'UpcomingDividendAmount',
	AnnualDividendRate: 'AnnualDividendRate',
	AnnualDividendYield: 'AnnualDividendYield',
	PreviousExDate: 'PreviousExDate',
	NextPayDate: 'NextPayDate',
	AverageVolume: 'AverageVolume',
	PEratio: 'PEratio',
	Beta: 'Beta',
	Week52High: 'WeekHigh',
	Week52Low: 'WeekLow',
	SharesOutstanding: 'SharesOutstanding',
	FinancialStatus: 'FinancialStatus',
	ProfileDate: 'ProfileDate',
};

export const MfProfileKeys = {
	Week52High: 'WeekHigh',
	Week52Low: 'WeekLow',
	Month1PerChange: 'Month1PerChange',
	Year1PerChange: 'Year1PerChange',
	IssueType: 'IssueType',
	DistributionFreq: 'DistributionFreq',
	LastDistribution: 'LastDistribution',
	LastDistributionYield: 'LastDistributionYield',
};

export const EtfProfileKeys = {
	Week52High: 'WeekHigh',
	Week52Low: 'WeekLow',
	Avg10DayVolume: 'Avg10DayVolume',
	Beta: 'Beta',
	MarketCap: 'MarketCap',
	IssueType: 'IssueType',
	DistributionFreq: 'DistributionFreq',
	LastDistribution: 'LastDistribution',
	NextDistribution: 'NextDistribution',
	LastDistributionYield: 'LastDistributionYield',
	NextDistributionYield: 'NextDistributionYield',
	SharesOutstanding: 'SharesOutstanding',
};

export const CompanyProfileHeader: { [key: string]: ICustomTableHeader[] } = {
	Equity: [
		{
			type: TYPES_OF_ELEMENTS.LABEL,
			key: EquityProfileKeys.MarketCap,
			label: 'Market Cap',
		},
		{
			type: TYPES_OF_ELEMENTS.LABEL,
			key: EquityProfileKeys.Revenue,
			label: 'Revenue',
		},
		{
			type: TYPES_OF_ELEMENTS.LABEL,
			label: 'EPS (TTM)',
			key: EquityProfileKeys.EPS,
		},
		{
			type: TYPES_OF_ELEMENTS.LABEL,
			label: 'Upcoming Dividend Amount',
			key: EquityProfileKeys.UpcomingDividendAmount,
		},
		{
			type: TYPES_OF_ELEMENTS.LABEL,
			label: 'Annual Dividend Rate (IAD)',
			key: EquityProfileKeys.AnnualDividendRate,
		},
		{
			type: TYPES_OF_ELEMENTS.LABEL,
			label: 'Annual Dividend Yield',
			key: EquityProfileKeys.AnnualDividendYield,
		},
		{
			type: TYPES_OF_ELEMENTS.LABEL,
			label: 'Previous Ex-Date',
			key: EquityProfileKeys.PreviousExDate,
		},
		{
			type: TYPES_OF_ELEMENTS.LABEL,
			label: 'Next Pay Date',
			key: EquityProfileKeys.NextPayDate,
		},
		{
			type: TYPES_OF_ELEMENTS.LABEL,
			label: 'Average Volume',
			key: EquityProfileKeys.AverageVolume,
		},
		{
			type: TYPES_OF_ELEMENTS.LABEL,
			label: 'P/E Ratio',
			key: EquityProfileKeys.PEratio,
		},
		{
			type: TYPES_OF_ELEMENTS.LABEL,
			label: 'Beta',
			key: EquityProfileKeys.Beta,
		},
		{
			type: TYPES_OF_ELEMENTS.LABEL,
			label: '52 Week High',
			key: EquityProfileKeys.Week52High,
		},
		{
			type: TYPES_OF_ELEMENTS.LABEL,
			label: '52 Week Low',
			key: EquityProfileKeys.Week52Low,
		},
		{
			type: TYPES_OF_ELEMENTS.LABEL,
			label: 'Shares Outstanding',
			key: EquityProfileKeys.SharesOutstanding,
		},
		{
			type: TYPES_OF_ELEMENTS.LABEL,
			label: 'Financial Status',
			key: EquityProfileKeys.FinancialStatus,
		},
		{
			type: TYPES_OF_ELEMENTS.LABEL,
			label: '',
			key: EquityProfileKeys.ProfileDate,
		},
	],
	MF: [
		{
			type: TYPES_OF_ELEMENTS.LABEL,
			key: MfProfileKeys.Week52High,
			label: '52 Week High',
		},
		{
			type: TYPES_OF_ELEMENTS.LABEL,
			label: '52 Week Low',
			key: MfProfileKeys.Week52Low,
		},
		{
			type: TYPES_OF_ELEMENTS.LABEL,
			label: '1 Month % Perf Chg',
			key: MfProfileKeys.Month1PerChange,
		},
		{
			type: TYPES_OF_ELEMENTS.LABEL,
			label: '1 Year % Perf Chg',
			key: MfProfileKeys.Year1PerChange,
		},
		{
			type: TYPES_OF_ELEMENTS.LABEL,
			label: 'Issue Type',
			key: MfProfileKeys.IssueType,
		},
		{
			type: TYPES_OF_ELEMENTS.LABEL,
			label: 'Distribution Freq',
			key: MfProfileKeys.DistributionFreq,
		},
		{
			type: TYPES_OF_ELEMENTS.LABEL,
			label: 'Last Distribution',
			key: MfProfileKeys.LastDistribution,
		},
		{
			type: TYPES_OF_ELEMENTS.LABEL,
			label: 'Last Distribution Yield',
			key: MfProfileKeys.LastDistributionYield,
		},
	],
	ETF: [
		{
			type: TYPES_OF_ELEMENTS.LABEL,
			key: EtfProfileKeys.Week52High,
			label: '52 Week High',
		},
		{
			type: TYPES_OF_ELEMENTS.LABEL,
			label: '52 Week Low',
			key: EtfProfileKeys.Week52Low,
		},
		{
			type: TYPES_OF_ELEMENTS.LABEL,
			label: '10 Day Avg Volume',
			key: EtfProfileKeys.Avg10DayVolume,
		},
		{
			type: TYPES_OF_ELEMENTS.LABEL,
			label: 'Beta',
			key: EtfProfileKeys.Beta,
		},
		{
			type: TYPES_OF_ELEMENTS.LABEL,
			label: 'Market Cap',
			key: EtfProfileKeys.MarketCap,
		},
		{
			type: TYPES_OF_ELEMENTS.LABEL,
			label: 'Issue Type',
			key: EtfProfileKeys.IssueType,
		},
		{
			type: TYPES_OF_ELEMENTS.LABEL,
			label: 'Distribution Freq',
			key: EtfProfileKeys.DistributionFreq,
		},
		{
			type: TYPES_OF_ELEMENTS.LABEL,
			label: 'Last Distribution',
			key: EtfProfileKeys.LastDistribution,
		},
		{
			type: TYPES_OF_ELEMENTS.LABEL,
			label: 'Next Distribution',
			key: EtfProfileKeys.NextDistribution,
		},
		{
			type: TYPES_OF_ELEMENTS.LABEL,
			label: 'Last Distribution Yield',
			key: EtfProfileKeys.LastDistributionYield,
		},
		{
			type: TYPES_OF_ELEMENTS.LABEL,
			label: 'Next Distribution Yield',
			key: EtfProfileKeys.NextDistributionYield,
		},
		{
			type: TYPES_OF_ELEMENTS.LABEL,
			label: 'Shares Outstanding',
			key: EtfProfileKeys.SharesOutstanding,
		},
	],
};

export const CompanyProfileMapping: { [key: string]: ICustomTableMapping[] } = {
	Equity: [
		{
			key: EquityProfileKeys.MarketCap,
			type: TYPES_OF_ELEMENTS.LABEL,
		},
		{
			key: EquityProfileKeys.Revenue,
			type: TYPES_OF_ELEMENTS.LABEL,
		},
		{
			key: EquityProfileKeys.EPS,
			type: TYPES_OF_ELEMENTS.LABEL,
		},
		{
			key: EquityProfileKeys.UpcomingDividendAmount,
			type: TYPES_OF_ELEMENTS.LABEL,
		},
		{
			key: EquityProfileKeys.AnnualDividendRate,
			type: TYPES_OF_ELEMENTS.LABEL,
		},
		{
			key: EquityProfileKeys.AnnualDividendYield,
			type: TYPES_OF_ELEMENTS.LABEL,
		},
		{
			key: EquityProfileKeys.PreviousExDate,
			type: TYPES_OF_ELEMENTS.LABEL,
		},
		{
			key: EquityProfileKeys.NextPayDate,
			type: TYPES_OF_ELEMENTS.LABEL,
		},
		{
			key: EquityProfileKeys.AverageVolume,
			type: TYPES_OF_ELEMENTS.LABEL,
		},
		{
			key: EquityProfileKeys.PEratio,
			type: TYPES_OF_ELEMENTS.LABEL,
		},
		{
			key: EquityProfileKeys.Beta,
			type: TYPES_OF_ELEMENTS.LABEL,
		},
		{
			key: EquityProfileKeys.Week52High,
			type: TYPES_OF_ELEMENTS.LABEL,
		},
		{
			key: EquityProfileKeys.Week52Low,
			type: TYPES_OF_ELEMENTS.LABEL,
		},
		{
			key: EquityProfileKeys.SharesOutstanding,
			type: TYPES_OF_ELEMENTS.LABEL,
		},
		{
			key: EquityProfileKeys.FinancialStatus,
			type: TYPES_OF_ELEMENTS.LABEL,
		},
		{
			key: EquityProfileKeys.ProfileDate,
			type: TYPES_OF_ELEMENTS.LABEL,
		},
	],
	MF: [
		{
			key: MfProfileKeys.Week52High,
			type: TYPES_OF_ELEMENTS.LABEL,
		},
		{
			key: MfProfileKeys.Week52Low,
			type: TYPES_OF_ELEMENTS.LABEL,
		},
		{
			key: MfProfileKeys.Month1PerChange,
			type: TYPES_OF_ELEMENTS.NUMBER,
			showPercent: true,
			showColor: true,
			isBold: true,
		},
		{
			key: MfProfileKeys.Year1PerChange,
			type: TYPES_OF_ELEMENTS.NUMBER,
			showPercent: true,
			showColor: true,
			isBold: true,
		},
		{
			key: MfProfileKeys.IssueType,
			type: TYPES_OF_ELEMENTS.LABEL,
		},
		{
			key: MfProfileKeys.DistributionFreq,
			type: TYPES_OF_ELEMENTS.LABEL,
		},
		{
			key: MfProfileKeys.LastDistribution,
			type: TYPES_OF_ELEMENTS.LABEL,
		},
		{
			key: MfProfileKeys.LastDistributionYield,
			type: TYPES_OF_ELEMENTS.NUMBER,
			showPercent: true,
			showColor: true,
			isBold: true,
		},
	],
	ETF: [
		{
			key: EtfProfileKeys.Week52High,
			type: TYPES_OF_ELEMENTS.LABEL,
		},
		{
			key: EtfProfileKeys.Week52Low,
			type: TYPES_OF_ELEMENTS.LABEL,
		},
		{
			key: EtfProfileKeys.Avg10DayVolume,
			type: TYPES_OF_ELEMENTS.LABEL,
		},
		{
			key: EtfProfileKeys.Beta,
			type: TYPES_OF_ELEMENTS.LABEL,
		},
		{
			key: EtfProfileKeys.MarketCap,
			type: TYPES_OF_ELEMENTS.LABEL,
		},
		{
			key: EtfProfileKeys.IssueType,
			type: TYPES_OF_ELEMENTS.LABEL,
		},
		{
			key: EtfProfileKeys.DistributionFreq,
			type: TYPES_OF_ELEMENTS.LABEL,
		},
		{
			key: EtfProfileKeys.LastDistribution,
			type: TYPES_OF_ELEMENTS.LABEL,
		},
		{
			key: EtfProfileKeys.NextDistribution,
			type: TYPES_OF_ELEMENTS.LABEL,
		},
		{
			key: EtfProfileKeys.LastDistributionYield,
			type: TYPES_OF_ELEMENTS.LABEL,
		},
		{
			key: EtfProfileKeys.NextDistributionYield,
			type: TYPES_OF_ELEMENTS.LABEL,
		},
		{
			key: EtfProfileKeys.SharesOutstanding,
			type: TYPES_OF_ELEMENTS.LABEL,
		},
	],
};

export const FinancialStatusMapping: { [key: string]: string } = {
	D: 'Deficient',
	E: 'Delinquent',
	Q: 'Bankrupt',
	N: 'Normal',
	G: 'Deficient and Bankrupt',
	H: 'Deficient and Delinquent',
	J: 'Delinquent and Bankrupt',
	K: 'Deficient, Delinquent, and Bankrupt',
};

export interface ImfQuoteBar {
	yield: number;
	yieldAsof: string;
	nav: string;
	navAsOf: number;
	change: string;
	changePercent: string;
}
