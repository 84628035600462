export enum News_Type {
	Economic = 'Economic',
	Government = 'Government',
	Market = 'Markets',
}

export enum News_Category {
	All = 'Select All',
	Derivative = 'Derivative Markets',
	Equity = 'Equity Markets',
	Foreign = 'Foreign Exchange',
	MacroEconomics = 'Macroeconomics',
	Regulations = 'Regulations',
	TaxPolicy = 'Tax Policy',
	GovernmentPolitics = 'US Government and Politics',
	InterestRates = 'US Interest Rates',
	EconomicIndicators = 'Economic Indicators',
}

export enum News_Time_Period {
	Day = '1 Day',
	Week = '1 Week',
	Month = '1 Month',
}

export enum News_Correlate {
	NYSE = 'NYSE',
	DJIA = 'DJIA',
	NASDAQ = 'NASDAQ',
	Transportation = 'DJ Transportation',
	Utilities = 'DJ Utilities',
}

export enum Controls {
	Show = 'Show Controls',
	Hide = 'Hide Controls',
}

export const NEWS_TYPE_DROPDOWN = [
	{
		key: News_Type.Economic,
		value: 'Economic',
	},
	{
		key: News_Type.Government,
		value: 'Government',
	},
	{
		key: News_Type.Market,
		value: 'Markets',
	},
];

export const NEWS_CATEGORY_DROPDOWN = {
	Markets: [
		{
			key: News_Category.All,
			value: 'Select All',
		},
		{
			key: News_Category.Derivative,
			value: 'Derivative Markets',
		},
		{
			key: News_Category.Equity,
			value: 'Equity Markets',
		},
		{
			key: News_Category.Foreign,
			value: 'Foreign Exchange',
		},
		{
			key: News_Category.MacroEconomics,
			value: 'Macroeconomics',
		},
	],
	Government: [
		{
			key: News_Category.All,
			value: 'Select All',
		},
		{
			key: News_Category.Regulations,
			value: 'Regulations',
		},
		{
			key: News_Category.TaxPolicy,
			value: 'Tax Policy',
		},
		{
			key: News_Category.GovernmentPolitics,
			value: 'US Government and Politics',
		},
	],
	Economic: [
		{
			key: News_Category.All,
			value: 'Select All',
		},
		{
			key: News_Category.InterestRates,
			value: 'US Interest Rates',
		},
		{
			key: News_Category.EconomicIndicators,
			value: 'Economic Indicators',
		},
	],
};

export const TIME_PERIOD_DROPDOWN = [
	{
		key: News_Time_Period.Day,
		value: '1 Day',
	},
	{
		key: News_Time_Period.Week,
		value: '1 Week',
	},
	{
		key: News_Time_Period.Month,
		value: '1 Month',
	},
];

export const NEWS_CORRELATE_DROPDOWN = [
	{
		key: News_Correlate.NYSE,
		value: 'NYSE',
	},
	{
		key: News_Correlate.DJIA,
		value: 'DJIA',
	},
	{
		key: News_Correlate.NASDAQ,
		value: 'NASDAQ',
	},
	{
		key: News_Correlate.Transportation,
		value: 'DJ Transportation',
	},
	{
		key: News_Correlate.Utilities,
		value: 'DJ Utilities',
	},
];

export const NEWS_TYPE_MAPPING: { [key: string]: string } = {
	Economic: 'EconomicNewsMap',
	Government: 'Government',
	Markets: 'MarketsNewsMap',
	'1 Day': 'DocumentDate',
	'1 Week': 'DocumentDate',
	'1 Month': 'DocumentDate',
};

export const NEWS_CATEGORY_MAPPING: { [key: string]: string } = {
	'Select All': 'All',
	'US Interest Rates': 'USInterestRates',
	'Economic Indicators': 'EconomicIndicators',
	Regulations: 'Regulations',
	'Tax Policy': 'TaxPolicy',
	'US Government and Politics': 'USGovernmentAndPolitics',
	'Derivative Markets': 'DerivativeMarkets',
	'Equity Markets': 'EquityMarkets',
	'Foreign Exchange': 'ForeignExchange',
	Macroeconomics: 'MacroEconomics',
};

export const TIME_PERIOD_MAPPING: { [key: string]: number } = {
	'1 Day': 1,
	'1 Week': 7,
	'1 Month': 30,
};

export const TIME_PERIOD_MAPPING_INTERVAL: { [key: string]: number | string } =
	{
		'1 Day': 5,
		'1 Week': 5,
		'1 Month': 'daily',
	};

export const NEWS_CORRELATE_XIDS: { [key: string]: number } = {
	NYSE: 566677,
	DJIA: 599362,
	NASDAQ: 579435,
	'DJ Transportation': 589792,
	'DJ Utilities': 578405,
};

export const IndicatorText =
	'Bar chart represents number of stories. Select color bar to view stories above.';

export const headLineNews = 'Headline News';

export const searchNewsByCategory = 'Search News By Category';

export const News_Map = 'News Map';

export const defaultDate = '2000/01/01';

export const TIME_PERIOD_WEEK_TICK_MAPPING: { [key: string]: number } = {
	MAX_TIMEINTERVAL_FOR_DAY: 78,
	NEWSBAR_POS: 36,
};
