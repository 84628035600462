import React from 'react';
import { Table } from '../../../../../@core-ui';
import { NyseAmexTableContent } from './nyse-amex-symbology-constants';
import { ISymbologyTable, SymbologyColumns } from '../symbology-constants';

const NyseAmexSymbology = () => {
	return (
		<div>
			<Table
				data={NyseAmexTableContent as ISymbologyTable[]}
				columns={SymbologyColumns}
			/>
		</div>
	);
};

export default NyseAmexSymbology;
