import { RC_WC_MAP } from '../../constants/wcConstants';
import { Heading } from '../../@core-ui/Heading/heading';

import './integrate.scss';

function HowToIntegratePage() {
	return (
		<div className="how-to-integrate-page-container">
			<Heading
				type="h1"
				testId="pageHeading"
				content="Web Components Integration Sample Code!"
			/>

			<div className="wc-code">
				<details>
					<summary>Web Components - How to Integrate?</summary>
					<div className="sample-code-con">
						{Object.keys(RC_WC_MAP).map((key: any) => {
							return (
								<details key={key}>
									<summary>{RC_WC_MAP[key].name}</summary>
									<div className="code">
										<pre>{`<!DOCTYPE html>
<html lang="en">
	<head>
		<script src="${window.MD.WEB_APP_BASE_URL}web-components/md-web-components.min.js"></script>
	</head>

	<body>
		<${RC_WC_MAP[key].name}></${RC_WC_MAP[key].name}>
	</body>
</html>`}</pre>
									</div>
								</details>
							);
						})}
					</div>
				</details>

				<details>
					<summary>Access Token Expired?</summary>
					<div className="sample-code-con white">
						<h3>
							MD Access Token lifespan is 2hrs. If token is expired then
							Oppenheimer needs to generate it again using the JWT Assertion.
						</h3>
						<br />
						=&gt; When access token get expired then MD will dispatch{' '}
						<b>MD_ACCESS_TOKEN_EXPIRED_EVENT</b>
						<pre>{`
window.dispatchEvent(new CustomEvent('MD_ACCESS_TOKEN_EXPIRED_EVENT'));
								`}</pre>
						=&gt; Oppenheimer needs to listen this event and generate access
						token using JWT assertion.
						<pre>
							{`
window.addEventListener('MD_ACCESS_TOKEN_EXPIRED_EVENT', (event: any) => {
	// Code to generate access token
});
									`}
						</pre>
						=&gt; Once token generated then Oppenheimer needs to dispatch{' '}
						<b>MD_ACCESS_TOKEN_REFRESHED_EVENT</b> and send the updated token
						details in the event detail section-
						<pre>
							{`
const ceTokenUpdated = new CustomEvent('MD_ACCESS_TOKEN_REFRESHED_EVENT', {
	detail: {
		access_token: 'REPLACE_THIS_WITH_UPDATED_TOKEN',
		expires_in: 'REPLACE_THIS_WITH_TOKEN_EXPIRATION_TIME',
	},
});

window.dispatchEvent(ceTokenUpdated);
								`}
						</pre>{' '}
						<br />
						=&gt; MD all components will be listening this
						<b> MD_ACCESS_TOKEN_REFRESHED_EVENT</b> which will update token in
						the browser storage for the subsequent API calls and update UI
						automatically!
					</div>
				</details>

				<details>
					<summary>Logout - remove access token from browser storage</summary>
					<div className="sample-code-con white">
						<h3>
							Oppenheimer needs to trigger the CustomEvent (MD_LOGOUT_EVENT)
							when user is logging out from the application, MD components
							listen this event and clear the access token
						</h3>
						<br />
						=&gt; On user logout, Oppenheimer will dispatch{' '}
						<b>MD_LOGOUT_EVENT</b>
						<pre>{`
window.dispatchEvent(new CustomEvent('MD_LOGOUT_EVENT'));
								`}</pre>
						=&gt; MD needs to listen this event and clear the access token from
						session storage
						<pre>
							{`
window.addEventListener('MD_LOGOUT_EVENT', (event: any) => {
	window.sessionStorage.removeItem('MD_ACCESS_TOKEN');
});
									`}
						</pre>
					</div>
				</details>
			</div>
		</div>
	);
}

export default HowToIntegratePage;
