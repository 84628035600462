/* eslint-disable indent */
import React, { useState, useRef } from 'react';
import './symbol-details.scss';
import { Popover, LabelLink } from '../../@core-ui';
import SymbolDetailsPopover from './symbol-details-popover';
import { dispatchSymbolChangedCustomEvent } from '../../utilities/events';
import { getXrefDataByXids } from '../../services';
import { getDeviceType, handleEnterKeyPress } from '../../utilities/utils';
import { E_DeviceType } from '../../constants/appConstants';

interface SymbolDetailsPropsType {
	venueXid: number;
	displayName: string;
	isCompanyNameOrSymbol?: boolean;
	isClickable?: boolean;
}

const SymbolDetails = (props: SymbolDetailsPropsType) => {
	const [isPopoverOpen, setIsPopoverOpen] = useState(false);
	const ref = useRef(null);
	let debounceTimeout: any = false;
	const isMobile = getDeviceType() === E_DeviceType.Mobile;

	const togglePopover = () => {
		debounceTimeout = setTimeout(() => {
			setIsPopoverOpen(true);
		}, 500);
	};

	const togglePopoverBlur = () => {
		clearTimeout(debounceTimeout);
		setIsPopoverOpen(false);
	};

	const onClickHandler = () => {
		getXrefDataByXids([props.venueXid]).then((outputXref) => {
			const responseData = outputXref?.data?.data?.items[0];
			responseData && dispatchSymbolChangedCustomEvent(responseData);
		});
	};

	return (
		<div className="symbol-details-container">
			<div className="symbol-details-label">
				<LabelLink
					text={props.displayName}
					isHideTitle={true}
					size={'s'}
					isBold={true}
					ellipsisEnabled={true}
					isCompanyNameOrSymbol={props.isCompanyNameOrSymbol}
					ref={ref}
					{...(!isMobile && {
						onFocus: togglePopover,
						onMouseOver: togglePopover,
						onMouseOut: togglePopoverBlur,
						onBlur: togglePopoverBlur,
					})}
					{...(props?.isClickable &&
						props?.venueXid && {
							onClick: onClickHandler,
							onKeyDown: (e) => {
								handleEnterKeyPress(e, onClickHandler);
							},
						})}
				/>
			</div>
			{isPopoverOpen && (
				<Popover
					id="popover"
					closePopover={togglePopover}
					body={<SymbolDetailsPopover venueXid={props.venueXid} />}
					referenceElement={ref.current}
					placement={'auto'}
					testid="popover"
				/>
			)}
		</div>
	);
};

export default SymbolDetails;
