import React from 'react';
import { Table } from '../../../../../@core-ui';
import { ISymbologyTable, SymbologyColumns } from '../symbology-constants';
import { NasdaqTableContent } from './nasdaq-symbology-constants';

const NasdaqSymbology = () => {
	return (
		<Table
			data={NasdaqTableContent as ISymbologyTable[]}
			columns={SymbologyColumns}
		/>
	);
};

export default NasdaqSymbology;
