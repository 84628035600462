import './Check.scss';

interface CheckProps {
	color?: 'white' | 'black';
	height?: 8 | 11;
}

export const Check = (props: CheckProps) => {
	const { color = 'black', height = 8 } = props;

	return (
		<svg
			width="14px"
			height={height}
			viewBox="0 0 10.6691237 9.00918817"
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
			className={color}
		>
			<g
				id="Final-Finals---Very-Final"
				stroke="none"
				strokeWidth="1"
				fill="none"
				fillRule="evenodd"
			>
				<g
					id="Welcome---States"
					transform="translate(-305.333755, -332.314883)"
					fill="currentColor"
					fillRule="nonzero"
				>
					<g
						id="Dropdown---Expanded-Hover-Opt-1"
						transform="translate(298.000000, 319.000000)"
					>
						<polygon
							id="Check"
							points="16.5460123 13.3148829 18.0028783 14.6851171 11.4859686 21.6140526 10.8181711 22.3240711 10.0912903 21.6746682 7.33375464 19.2110568 8.66624536 17.7195916 10.696 19.534"
						></polygon>
					</g>
				</g>
			</g>
		</svg>
	);
};
