import DataRequest from './dataRequest';
import URLS from '../constants/urlConstants';
import { E_HTTP_METHOD } from '../constants/appConstants';
import {
	IPortfolioFundamentalsInputs,
	IPostPortfolioFundamentalsResponse,
} from '../types/interfaces';

export const getPortfolioFundamentalData = async (
	data: IPortfolioFundamentalsInputs[],
): Promise<IPostPortfolioFundamentalsResponse> => {
	return await DataRequest.execute(URLS.CUSTOM.PORTFOLIO_FUNDAMENTALS, {
		method: E_HTTP_METHOD.POST,
		postData: data,
	});
};
