import { ITableColumn } from '../../../../@core-ui/Table/table';
import NasdaqSymbology from './nasdaq/nasdaq-symbology';
import NyseAmexSymbology from './nyse-amex/nyse-amex-symbology';
import OptionsSymbology from './options/options-symbology';

export const SYMBOLOGY_CONSTANTS = {
	StaticContent: `When in doubt, the symbol lookup feature allows you to find the appropriate symbol based upon the company name. Symbol lookup results are linked directly to research content, but they may also be entered directly at a later time.
 	<br/>
 	<br/>
	Securities may be represented using different symbols depending on what data source or web site you are using. For most securities, this web site accepts symbols that will match other sources. Please check the tables below for specific examples on how to get quotes on special security types.`,
	Links: [
		{
			Header: 'NYSE and AMEX',
			Component: NyseAmexSymbology,
		},
		{
			Header: 'NASDAQ',
			Component: NasdaqSymbology,
		},
		{
			Header: 'OPTIONS',
			Component: OptionsSymbology,
		},
	],
	WorldIndices: [
		{ Indices: 'AMEX', Symbol: '.XAX' },
		{ Indices: 'CBOE 10Y Treas', Symbol: '.TNX' },
		{ Indices: 'CBOE 30Y Trea', Symbol: '.TYX' },
		{ Indices: 'CBOE 5Y Treas', Symbol: '.FVX' },
		{ Indices: 'CBOE Mexico', Symbol: '.MEX' },
		{ Indices: 'DJ Industrials', Symbol: '.DJU' },
		{ Indices: 'DJ Transportation', Symbol: '.DJT' },
		{ Indices: 'DJ Utilities', Symbol: '.DJU' },
		{ Indices: 'NASDAQ 100', Symbol: '.NDX' },
		{ Indices: 'NASDAQ Composite', Symbol: '.DJA' },
		{ Indices: 'NASDAQ Bank Index', Symbol: '.BANK' },
		{ Indices: 'NASDAQ Computer Index', Symbol: '.IXK' },
		{ Indices: 'NASDAQ Financials', Symbol: '.IXF' },
		{ Indices: 'NYSE', Symbol: '.NYA' },
		{ Indices: 'Pacific Technology 100', Symbol: '.PSE' },
		{ Indices: 'PHLX Gold & Silv', Symbol: '.XAU' },
		{ Indices: 'PHLX Semi', Symbol: '.SOXX' },
		{ Indices: 'S&P 100', Symbol: '.OEX' },
		{ Indices: 'S&P 500', Symbol: '.SPX' },
		{ Indices: 'S&P Midcap 400', Symbol: '.MID' },
		{ Indices: 'S&P Small 600', Symbol: '.SML' },
	],
	ResultNotFoundMessage: `We couldn't find any symbols that match exactly what you're looking for. Please try searching by Name instead.`,
};

export interface ISymbologyTable {
	Type: string;
	Append: string;
	Example: string;
}

export const SymbologyColumns: ITableColumn<ISymbologyTable>[] = [
	{ header: 'Type', accessor: 'Type' },
	{ header: 'Append', accessor: 'Append' },
	{ header: 'Example', accessor: 'Example' },
];

export interface IWorldIndicesTable {
	Indices: string;
	Symbol: string;
}

export const WorldIndicesColumns: ITableColumn<IWorldIndicesTable>[] = [
	{ header: 'Indices', accessor: 'Indices' },
	{ header: 'Symbol', accessor: 'Symbol' },
];
