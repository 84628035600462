import { logInfo } from './logger';
import { setAccessToken } from './utils';

export enum EVENTS_NAME {
	MD_ACCESS_TOKEN_EXPIRED_EVENT = 'MD_ACCESS_TOKEN_EXPIRED_EVENT',
	MD_ACCESS_TOKEN_REFRESHED_EVENT = 'MD_ACCESS_TOKEN_REFRESHED_EVENT',
	MD_LOGOUT_EVENT = 'MD_LOGOUT_EVENT',
	MD_SYMBOL_CHANGED_EVENT = 'MD_SYMBOL_CHANGED_EVENT',
	MD_ADD_TO_WATCHLIST_EVENT = 'MD_ADD_TO_WATCHLIST_EVENT',
	MD_GO_TO_NEWS_PAGE_EVENT = 'MD_GO_TO_NEWS_PAGE_EVENT',
	MD_GO_TO_MARKET_INDICES_TAB_EVENT = 'MD_GO_TO_MARKET_INDICES_TAB_EVENT',
	MD_WC_ATTRIBUTE_CHANGED_EVENT = 'MD_WC_ATTRIBUTE_CHANGED_EVENT',
}

/**
 * Dispatch MD Custom Event
 */
export const dispatchCustomEvent = (
	eventName: EVENTS_NAME,
	eventData: any = null,
) => {
	logInfo(`[EVENT-DISPATCHED] | ${eventName} | EventData:`, eventData);

	window.dispatchEvent(
		new CustomEvent(eventName, {
			detail: eventData,
		}),
	);
};

/**
 * MD Custom Event Listener
 */
export const registerCustomEventHandler = (
	eventName: EVENTS_NAME,
	callback: any,
) => {
	if (!callback || typeof callback != 'function') return;

	window.addEventListener(eventName, (event: any) => {
		logInfo(`[EVENT-LISTENED] | ${eventName} | EventData:`, event?.detail);

		callback?.(event?.detail);
	});
};

/**
 * Dispatch MD_ACCESS_TOKEN_EXPIRED_EVENT Event
 */
export const dispatchAccessTokenExpiredEvent = (invalidAccessToken = false) => {
	logInfo(
		`[EVENT] - DISPATCH_ACCESS_TOKEN_EXPIRED_EVENT (${
			invalidAccessToken ? 'EXPIRED' : 'NOT_AVAILABLE'
		}), GET_TOKEN_IN_PROGRESS: ${!!window.MD.GET_TOKEN_IN_PROGRESS}`,
	);

	if (window.MD.GET_TOKEN_IN_PROGRESS) {
		return;
	}

	window.MD.GET_TOKEN_IN_PROGRESS = true;

	dispatchCustomEvent(EVENTS_NAME.MD_ACCESS_TOKEN_EXPIRED_EVENT);
};

/**
 * Register MD_ACCESS_TOKEN_REFRESHED_EVENT
 */
export const registerAccessTokenRefreshedEventListener = (callback: any) => {
	if (!callback || typeof callback != 'function') return;

	registerCustomEventHandler(
		EVENTS_NAME.MD_ACCESS_TOKEN_REFRESHED_EVENT,
		(event_data: any) => {
			const access_token = event_data?.access_token;

			if (access_token) {
				window.MD.GET_TOKEN_IN_PROGRESS = false;

				// set access token in the storage
				setAccessToken(access_token);
				callback?.();
			}

			logInfo(
				`[EVENT] - ACCESS_TOKEN_REFRESHED, GET_TOKEN_IN_PROGRESS: ${window.MD.GET_TOKEN_IN_PROGRESS}`,
			);
		},
	);
};

export const dispatchSymbolChangedCustomEvent = (data: any) => {
	dispatchCustomEvent(EVENTS_NAME.MD_SYMBOL_CHANGED_EVENT, data);
};

export const dispatchAddToWatchlistCustomEvent = () => {
	dispatchCustomEvent(EVENTS_NAME.MD_ADD_TO_WATCHLIST_EVENT);
};

export const dispatchNewsCustomEvent = (data: any) => {
	dispatchCustomEvent(EVENTS_NAME.MD_GO_TO_NEWS_PAGE_EVENT, data);
};

export const dispatchMarketIndicesCustomEvent = () => {
	dispatchCustomEvent(EVENTS_NAME.MD_GO_TO_MARKET_INDICES_TAB_EVENT);
};
