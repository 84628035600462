export const CHART_TIMEFRAMES = [
	{ key: 1, value: '1D' },
	{ key: 7, value: '1W' },
	{ key: 30, value: '1M' },
	{ key: 90, value: '3M' },
	{ key: 180, value: '6M' },
	{ key: 365, value: '1Y' },
	{ key: 1095, value: '3Y' },
	{ key: 1825, value: '5Y' },
];
export enum EVENTS_TYPE {
	NEWS = 'news',
	DIVIDENDS = 'dividends',
	SPLITS = 'splits',
}
export const CHART_EVENTS = [
	{
		key: EVENTS_TYPE.NEWS,
		value: 'News',
	},
	{
		key: EVENTS_TYPE.DIVIDENDS,
		value: 'Dividends',
	},
	{
		key: EVENTS_TYPE.SPLITS,
		value: 'Splits',
	},
];

export const VOLUME_TYPES = [
	{
		key: 1,
		value: 'Volume',
	},
	{
		key: 2,
		value: 'MACD',
	},
	{
		key: 3,
		value: 'RSI',
	},
];
export const SAVED_CHART = [
	{
		key: 1,
		value: 'charts',
	},
];

export const CHART_TYPE = [
	{
		key: 'line',
		value: 'Line Chart',
	},
	{
		key: 'candlestick',
		value: 'Candlestick ',
	},
	{
		key: 'fill',
		value: 'Mountain Chart',
	},
	{
		key: 'hlc',
		value: 'HLC',
	},
];
export const SAVE_SETTINGS_HEADER = 'Save Settings';
export const SAVE_SETTINGS_PLACEHOLDER = 'Enter new settings name';
export const SAVE_SETTINGS_LIMIT_ERROR = `There is a limit of 10 saved settings. To save a new setting, please delete one from your list below.`;
export const SAVE_SETTINGS_EMPTY_ERROR = 'Missing required values.';
export const ERROR_DIALOG_TITLE = 'Chart Alert';
export const SAVE_SETTINGS_SUCCESS = 'Settings saved successfully';
export const MAX_LIMIT_MESSAGE =
	'You have reached the maximum number of overlays. Please remove an overlay before adding a new one.';
export const DUPLICATE_CHART_ERROR =
	'You cannot add this option because it already exists.';
export const CHART_TYPE_ERROR =
	'This option cannot be displayed in your current chart style. Please change chart style to Line or Mountain to view comparison.';

export const CHARTCOLORS = [
	'#004480',
	'#0095FF',
	'#D97E07',
	'#00B3A1',
	'#AA01AA',
	'#FF573F',
	'#9085FF',
	'#BF360C',
	'#093055',
	'#C60000',
	'#0B8224',
	'#9F9F9F',
];

export const OVERLAY_OPTIONS = [
	{
		key: 'sma',
		value: 'Simple Moving Average',
		color: CHARTCOLORS[1],
		period: 9,
		description:
			'Moving Averages smooth stock price movements. Comparing multiple time frames shows trend changes. Simple Moving Averages are computed by adding up all the closing prices for a chosen time frame then dividing by the number of periods in that time frame. Then, as each successive period is included, the earliest period is dropped so that only the most recent data is included.',
	},
	{
		key: 'ema',
		value: 'Exponential Moving Average',
		color: CHARTCOLORS[2],
		period: 9,
		description:
			"Exponential Moving Averages place more emphasis on the most recent period by adding a percentage of the most recent period's price to prior periods.",
	},
	{
		key: 'bollinger',
		value: 'Bollinger Bands',
		color: CHARTCOLORS[3],
		period: 9,
		description:
			"Bollinger Bands signal periods of high and low volatility. Bollinger Bands are envelopes calculated by adding and subtracting a stock's standard deviation from a simple moving average. The distance between the bands can signal periods of high and low volatility. During periods of low volatility, price may range between the upper and lower bands. When the price moves through a band, that may signal the start of a new trend",
	},
	{
		key: 'psar',
		value: 'Parabolic SAR',
		color: CHARTCOLORS[4],
		period: 9,
		description:
			"Parabolic SAR signals a reversal of a stock's trend. Parabolic SAR, which was developed by Welles Wilder, is used by many investors to determine where to place stop orders. SAR is short for Stop and Reversal and when the SAR is pierced by the stock price, an investor should exit the current position and reverse, i.e. selling a long position and initiating a short. The SAR is useful in determining trend reversal.",
	},
	{
		key: 'pricechannel',
		value: 'Price Channel',
		color: CHARTCOLORS[5],
		period: 9,
		description:
			"Price Channel shows the high and low prices for a period. Price Channel makes it easier to visualize a stock's range by plotting the highest high and lowest low for a period. It can also be used to measure volatility as the bands will be wider when prices are fluctuating. By lengthening the period used, an investor can capture price movement over a greater amount of time.",
	},
	{
		key: 'mae',
		value: 'Moving Average Envelope',
		color: CHARTCOLORS[6],
		period: 9,
		description:
			"Moving Average Envelopes help to recognize the possible price range for a given security. The Moving Average Envelope tries to capture the general range of a security's price movements and help the user identify extreme overbought or oversold periods. Moving Average Envelopes plot two moving averages. The average of the high price is increased by a given percent and the average of the low price is decreased by the same percent. A Moving Average Envelope is calculated by using either an Exponential (EMA), Simple (SMA) or Weighted (WMA) Moving Average. The user can specify the time period for the Moving Average and the percentage shift used to create the upper and lower bands.",
	},
];

export const CHART_TIMEFRAMES_MOBILE = [
	{ key: 1, value: '1 Day' },
	{ key: 7, value: '1 Week' },
	{ key: 30, value: '1 Month' },
	{ key: 90, value: '3 Months' },
	{ key: 180, value: '6 Months' },
	{ key: 365, value: '1 Year' },
	{ key: 1095, value: '3 Year' },
	{ key: 1825, value: '5 Year' },
];

export const DEFAULT_INDICATOR = 'volume';
