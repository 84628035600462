import React, { ChangeEvent, InputHTMLAttributes, forwardRef } from 'react';
import './textbox.scss';
import { Label } from '..';

type TextboxProps = {
	label?: string;
	placeholder?: string;
	testId?: string;
	value?: string;
	size?: 's' | 'm' | 'l';
	maxlength?: number;
	onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
} & InputHTMLAttributes<HTMLInputElement>;

const Textbox = forwardRef<HTMLInputElement, TextboxProps>(
	(
		{ label, placeholder, value, testId, size, maxlength, onChange, ...rest },
		ref,
	) => {
		const sizeClass: string = size ? 'textbox-' + size : '';
		return (
			<div className="textbox-container">
				{label && <Label text={label} />}
				<input
					type="text"
					className={`textbox ${sizeClass}`}
					placeholder={placeholder}
					data-testid={testId}
					value={value}
					maxLength={maxlength}
					onChange={onChange}
					ref={ref}
					{...rest}
				/>
			</div>
		);
	},
);

Textbox.displayName = 'Textbox';

export default Textbox;
