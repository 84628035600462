import React, { useState, useEffect } from 'react';
import './table.scss';
import { Label } from '..';
import { handleWindowResize, getDeviceType } from '../../utilities/utils';
import { E_DeviceType, T_DeviceType } from '../../constants/appConstants';
import { ChevronArrow } from './../../assets/Icons';
import { useSwipeable } from 'react-swipeable';

export interface ITableColumn<T> {
	header: string;
	accessor: string | ((item: T) => React.ReactNode);
}

export interface ITableProps<T> {
	columns: ITableColumn<T>[];
	data: T[];
	showVerticalBorder?: boolean;
	showHorizontalBorder?: boolean;
	hideHeader?: boolean;
	showPagination?: boolean;
	className?: string;
	currentPage?: number;
	pageSize?: number;
	onPageChange?: (page: number) => void;
	marketDelayedData?: string;
}

function Table<T>({
	columns,
	data,
	showVerticalBorder = false,
	showHorizontalBorder = true,
	hideHeader = false,
	showPagination = false,
	className,
	currentPage = 1,
	pageSize = data?.length,
	onPageChange,
	marketDelayedData,
}: ITableProps<T>) {
	const trClassName = `${showVerticalBorder ? 'show-vertical-border' : ''} ${
		showHorizontalBorder ? 'show-horizontal-border' : ''
	}`;
	const totalResults = data.length;
	const totalPages = Math.ceil(totalResults / pageSize);
	const startIndex = (currentPage - 1) * pageSize;
	const endIndex = Math.min(startIndex + pageSize, totalResults);
	const paginatedData = data.slice(startIndex, endIndex);
	const [device, setDevice] = useState<T_DeviceType>(getDeviceType());

	useEffect(() => {
		const handleResize = () => {
			const deviceType = getDeviceType();
			setDevice(deviceType);
		};
		handleWindowResize(handleResize);
	}, []);

	const handlePageChange = (page: number) => {
		onPageChange?.(page);
	};

	const handleSwipeLeft = () => {
		if (currentPage < totalPages) {
			handlePageChange(currentPage + 1);
		}
	};

	const handleSwipeRight = () => {
		if (currentPage > 1) {
			handlePageChange(currentPage - 1);
		}
	};

	const swipeHandlers = useSwipeable({
		onSwipedLeft: handleSwipeLeft,
		onSwipedRight: handleSwipeRight,
	});

	const renderPaginationIcons = () => {
		if (totalPages <= 1) {
			return null; // No need to render pagination icons if there's only one page
		}

		const isDesktopView = device === E_DeviceType.Desktop;
		return (
			<div className="pagination-icons">
				<a
					href="#"
					onClick={(event) => {
						event.preventDefault();
						if (currentPage !== 1) {
							handlePageChange(currentPage - 1);
						}
					}}
					className={currentPage === 1 ? 'disabled' : ''}
				>
					{isDesktopView && <ChevronArrow type="left" color="#000" />}
				</a>
				{Array.from({ length: totalPages }, (_, i) => (
					<a
						key={i}
						href="#"
						onClick={(event) => {
							event.preventDefault();
							handlePageChange(i + 1);
						}}
						className={currentPage === i + 1 ? 'active' : ''}
					>
						{!isDesktopView ? (
							<span className="dash-icon"></span>
						) : (
							<span>{i + 1}</span>
						)}
					</a>
				))}
				<a
					href="#"
					onClick={(event) => {
						event.preventDefault();
						if (currentPage !== totalPages) {
							handlePageChange(currentPage + 1);
						}
					}}
					className={currentPage === totalPages ? 'disabled' : ''}
				>
					{isDesktopView && <ChevronArrow type="right" color="#000" />}
				</a>
			</div>
		);
	};

	const renderCellContent = (
		row: any,
		accessor: string | ((data: T) => React.ReactNode),
	) => {
		if (typeof accessor === 'string') {
			return row[accessor];
		} else {
			return accessor(row);
		}
	};

	return (
		<div className="table-container" {...swipeHandlers}>
			<table className={className}>
				{!hideHeader && (
					<thead>
						<tr className={trClassName}>
							{columns.map((column: any) => (
								<th key={column.header}>
									<Label
										text={column.header}
										isHtmlContent={column.isHtmlContent}
									/>
								</th>
							))}
						</tr>
					</thead>
				)}
				<tbody>
					{paginatedData.map((row, index) => {
						const key = `tr_${index}`;
						return (
							<tr key={key} className={trClassName}>
								{columns.map((column) => (
									<td key={column.header}>
										{renderCellContent(row, column.accessor)}
									</td>
								))}
							</tr>
						);
					})}
				</tbody>
			</table>
			{showPagination && (
				<div className="pagination-container">
					<div className="pagination-info" tabIndex={0}>
						{marketDelayedData ? (
							<span>{marketDelayedData}</span>
						) : (
							<span>
								Showing {startIndex + 1} to {endIndex} of {totalResults} Search
								Results
							</span>
						)}
					</div>
					<div className="pagination-row">{renderPaginationIcons()}</div>
				</div>
			)}
		</div>
	);
}

export default Table;
