import React, { useState, useEffect } from 'react';
import NoData from '../NoData/NoData';
import {
	Modal,
	ModalProps,
	Label,
	Container,
	Loader,
	Heading,
} from '../../@core-ui';
import { getNewsArticle } from '../../services/news-data-request-methods';
import './view-news-modal.scss';
import { PrinterIcon, IBasicIconProps } from '../../assets/Icons';
import { getAccessToken } from '../../utilities/utils';

export interface ViewNewsProps extends ModalProps {
	articleId: string;
	provider: string;
	timeStamp: string;
	onLoad?: () => void;
	onClose?: () => void;
	isPrintMode?: boolean;
}

const ViewNewsModal: React.FC<ViewNewsProps> = (props) => {
	const { articleId, provider, timeStamp, isPrintMode = false } = props;
	const [isLoading, setIsLoading] = useState(true);
	const [newsData, setNewsData] = useState<any>();

	useEffect(() => {
		getNews(articleId).then(() => {
			setIsLoading(false);
		});
	}, [articleId]);

	useEffect(() => {
		!isLoading && props.onLoad?.();
	}, [newsData]);

	const getNews = async (id: string) => {
		if (id) {
			setIsLoading(true);
			const newsArticle = { isNewsAvl: false, headLine: '', fullStory: '' };
			const response = await getNewsArticle(id);
			if (response?.data) {
				const parser = new DOMParser();
				const xmlDoc = parser.parseFromString(response.data, 'text/xml');

				// Extract data from the XML document
				newsArticle.headLine =
					xmlDoc.getElementsByTagName('HeadLine')[0]?.innerHTML || '';
				newsArticle.fullStory =
					xmlDoc.getElementsByTagName('text')[0]?.innerHTML || '';
				newsArticle.isNewsAvl = true;
				setNewsData(newsArticle);
			}
		}
	};

	const printNewsArticle = () => {
		if (newsData?.isNewsAvl) {
			const accessToken = window.btoa(getAccessToken());
			const a = window.btoa(articleId);
			const p = window.btoa(provider);
			const ts = window.btoa(timeStamp);
			window.open(
				`${window.MD?.WEB_APP_BASE_URL}print-news-article?t=${accessToken}&a=${a}&p=${p}&ts=${ts}`,
				'',
			);
		}
	};

	const getPrintIcon = (
		onClick: (e: any) => void,
		text: string,
		Icon: React.FC<IBasicIconProps>,
	) => {
		return (
			<button
				className={'print-button-container'}
				onClick={onClick}
				data-testid={'printButton'}
			>
				<div className={'print-button'}>
					<Icon height={20} width={20} />
					<div className={'print-button-heading'}>
						<Label size={'ms'} fontType={'palatino-regular'} text={text} />
					</div>
				</div>
			</button>
		);
	};

	const bindProvider = (): React.ReactNode => {
		return (
			<>
				<Label
					isGrey={true}
					size={'s'}
					text={`${provider}`}
					isBold={true}
					testId={'news-provider'}
				/>
				<span className={'dividerProvider'}>|</span>
				<Label
					isGrey={true}
					size={'s'}
					text={`${timeStamp}`}
					testId={'news-timestamp'}
				/>
				{getPrintIcon(printNewsArticle, 'Print', PrinterIcon)}
			</>
		);
	};

	const bindNewsContent = (): React.ReactNode => {
		return (
			<>
				<Label
					isGrey={true}
					size={'ms'}
					text={newsData.fullStory}
					testId={'news-content'}
					isHtmlContent
					{...(isPrintMode ? { display: 'block' } : {})}
				></Label>
			</>
		);
	};
	const handleClose = () => {
		props.onClose?.();
	};
	const getNewsBody = (): React.ReactNode => {
		return (
			<Container id="news-article-container">
				{newsData?.isNewsAvl && (
					<>
						<div className={'headingStyle'} data-testid={'news-headline'}>
							<Heading
								type={'h3'}
								content={newsData.headLine}
								align={'left'}
								isHtmlContent
							/>
						</div>
						<div className="provider">{bindProvider()}</div>
					</>
				)}

				<main className={'newsContainer'} data-testid="newsContainer">
					{isLoading ? (
						<div className={'align-loader'}>
							<Loader />
						</div>
					) : (
						<div className={!isPrintMode ? 'news-content-main' : ''}>
							{newsData?.isNewsAvl ? (
								bindNewsContent()
							) : (
								<div className={'noData-container'}>
									<NoData error={newsData?.error} />
								</div>
							)}
						</div>
					)}
				</main>
			</Container>
		);
	};

	return (
		<>
			{!isPrintMode ? (
				<Modal onClose={handleClose} id={'news-article-modal'}>
					{getNewsBody()}
				</Modal>
			) : (
				<>{getNewsBody()}</>
			)}
		</>
	);
};

export default ViewNewsModal;
