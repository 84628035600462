import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

import { formatDateTime } from './date-time-formatter';
import { TEXT_NULL_VALUE } from './formatter';

dayjs.extend(utc);
dayjs.extend(timezone);

export const formatDateTimeTZ = (date: any, options: any = {}) => {
	const formatOptions = {
		...options,
		isDayJSObject: true,
	};

	const dayJsObj = dayjs.utc(date).tz(options.timezone);

	return formatDateTime(dayJsObj, formatOptions);
};

export const convertUtcDttmToET = (date: any, options: any = {}) => {
	if (!date) {
		return TEXT_NULL_VALUE;
	}
	return formatDateTimeTZ(date, {
		...options,
		timezone: 'America/New_York',
	});
};

export const getConvertedDate = (date: any, options: any = {}) => {
	const formatOptions = {
		utc: options.utc || false,
		onlyDate: options.onlyDate || false,
		format: options.format || false,
		isMsDate: options.isMsDate || false,
	};
	let formattedDate: any = date;
	if (formatOptions.utc) {
		formattedDate = dayjs.utc(formattedDate);
	}

	if (formatOptions.onlyDate) {
		formattedDate = dayjs(formattedDate);
	}

	if (formatOptions.isMsDate) {
		const msPerDay = 86400000; //24 * 60 * 60 * 1000
		const excelEpoch = new Date(Date.UTC(1899, 11, 30));
		const millisecondsSinceEpoch = formattedDate * msPerDay;
		const dateObject = new Date(excelEpoch.getTime() + millisecondsSinceEpoch);
		formattedDate = dayjs.utc(dateObject);
	}

	if (formatOptions.format) {
		formattedDate = formatDateTime(formattedDate, {
			format: formatOptions.format,
		});
	}

	return formattedDate;
};

export const getNewDate = () => {
	return dayjs();
};
