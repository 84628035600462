/* eslint-disable indent */
import React, { useEffect, useReducer, useState } from 'react';
import './portfolio-holdings.scss';
import {
	PortfolioTransactionsCrud,
	PortfolioHoldingsCrud,
	getQuotes,
	fetchXrefDataInChunks,
} from '../../../../services';
import {
	checkIfAPIError,
	bindData,
	generateColValue,
} from '../portfolio-utils';
import { SearchBox } from '../../../shared/search';
import NoData from '../../../../shared/NoData/NoData';
import {
	IGetTransactionsResponse,
	IGetHoldingsResponse,
} from '../../../../types/interfaces';
import {
	TRANSACTIONS_KEYS,
	ADD_TRANSACTIONS_TABLE_HEADER,
	ADD_TRANSACTIONS_TABLE_DATA_MAPPING,
	PORTFOLIO_ACTIONS,
	PORTFOLIO_MESSAGE,
	ERROR_MESSAGE_STATE_TYPE,
	MAX_PORTFOLIO_TRANSACTIONS,
} from '../portfolio-constants';
import {
	createUUID,
	sortByKey,
	getDeviceType,
} from '../../../../utilities/utils';
import {
	Button,
	ButtonVariant,
	Loader,
	MessageBox,
	MessageBoxStateType,
} from '../../../../@core-ui';
import {
	TYPES_OF_ELEMENTS,
	E_DeviceType,
} from '../../../../constants/appConstants';
import {
	getDate,
	DATE_FORMATS,
} from '../../../../utilities/date-time-formatter';
import { formatNumber } from '../../../../utilities/formatter';
import { ITransactionResponse } from '../../../../types/interfaces/IAPIResponse';

type PORTFOLIO_HOLDINGS_PROPS_TYPE = {
	id?: string;
	onButtonClick?: (
		e: any,
		saveTransactions: () => Promise<ITransactionResponse>,
		errorMessage?: string | null,
	) => void;
	preventFetchAgain?: boolean;
};

type OBJECT_TYPE = { [key: string]: any };

type SINGLE_PROMISE_SETTLED = {
	status: 'fulfilled' | 'rejected';
	value: any;
};

type ALL_PROMISE_SETTLED = SINGLE_PROMISE_SETTLED[];

const STATE_ACTIONS = {
	GET_HOLDINGS: 'GET HOLDINGS',
	UPDATE_TRANSACTION: 'UPDATE HOLDINGS',
	ADD_A_TRANSACTION: 'ADD HOLDINGS',
	DELETE_TRANSACTION: 'Delete TRANSACTION',
	HIGHLIGHT_ERROR_ROW: 'HIGHLIGHT ERROR ROW',
};

export type ERROR_MESSAGE_STATES_TYPE = {
	status: boolean;
	message: string;
};

const PortfolioHoldings = (props: PORTFOLIO_HOLDINGS_PROPS_TYPE) => {
	const defaultFees = '0';
	const [isLoading, setIsLoading] = useState(true);
	const [portfolioHoldings, dispatch] = useReducer(holdingsReducer, []);
	const [errorMessage, setErrorMessage] = useState<ERROR_MESSAGE_STATE_TYPE>(
		getErrorState(''),
	);
	const [isMaxLimit, setIsMaxLimit] = useState(false);
	const holdingObj = new PortfolioHoldingsCrud(props.id || '');
	const transObj = new PortfolioTransactionsCrud(props.id || '');
	const isEdit = props.id || false;
	const isMobile = getDeviceType() === E_DeviceType.Mobile ? true : false;

	useEffect(() => {
		!props.preventFetchAgain && fetchData();
	}, []);

	useEffect(() => {
		let count = 0;
		portfolioHoldings?.forEach((holding: any) => {
			holding.transactions?.forEach((transaction: any) => {
				if (!transaction.isDelete) {
					count++;
				}
			});
		});
		setIsMaxLimit(count >= MAX_PORTFOLIO_TRANSACTIONS);
	}, [portfolioHoldings]);

	function getErrorState(message: string, type?: MessageBoxStateType) {
		return {
			message: message,
			status: type || MessageBoxStateType.Fail,
		};
	}

	const onCrossClick = () => {
		setErrorMessage(getErrorState(''));
	};

	/**
	 * Reducer method to update the state
	 * @param portfolioHoldings is portfolio holdings state
	 * @param action is used to define the type of action and also data can be passed with this object
	 * @returns new state
	 */
	function holdingsReducer(portfolioHoldings: any, action: any) {
		switch (action?.type) {
			case STATE_ACTIONS.GET_HOLDINGS:
				return action.data;
			case STATE_ACTIONS.UPDATE_TRANSACTION:
				return updateTransaction(portfolioHoldings, action);
			case STATE_ACTIONS.ADD_A_TRANSACTION:
				return addTransaction(portfolioHoldings, action);
			case STATE_ACTIONS.HIGHLIGHT_ERROR_ROW:
				return highlightRow(portfolioHoldings, action);
		}
		return portfolioHoldings;
	}

	/**
	 * Crud operations for UI
	 */
	const fetchData = async () => {
		try {
			await getHoldings();
		} catch (ex) {
			console.log(ex);
		}
	};

	const getXrefData = async (holdings: any[]) => {
		const holdingsNew = JSON.parse(JSON.stringify(holdings));
		const xids = holdingsNew.map((e: any) => e.xid);
		const xrefResponses = await fetchXrefDataInChunks(xids);
		const xrefArray = xrefResponses.flatMap(
			(item) => item?.data?.data?.items || [],
		);
		if (xrefArray?.length) {
			holdingsNew.forEach((item: any) => {
				const xidResponse = xrefArray.find(
					(e: any) => e.xids?.venue == item.xid,
				);
				if (xidResponse) {
					item[TRANSACTIONS_KEYS.SYMBOL] = xidResponse.symbol;
					item[TRANSACTIONS_KEYS.NAME] = xidResponse.name;
				}
			});
			dispatch({
				type: STATE_ACTIONS.GET_HOLDINGS,
				data: holdingsNew,
			});
		}
	};

	const getTransactions = async (holdings: any[]) => {
		const transactions: IGetTransactionsResponse =
			await transObj.getTransactions();
		if (checkIfAPIError(transactions)) {
			logAPIError();
		} else {
			const transactionsArray = transactions?.data?.data?.items;
			const holdingsData = setTransactions(transactionsArray, holdings);
			dispatch({
				type: STATE_ACTIONS.GET_HOLDINGS,
				data: holdingsData,
			});
			setIsLoading(false);
			getXrefData(holdingsData);
		}
	};

	const getHoldings = async () => {
		setIsLoading(true);
		const holdings: IGetHoldingsResponse = await holdingObj.getHoldings();
		if (checkIfAPIError(holdings)) {
			logAPIError();
		} else {
			let holdingsArray = holdings?.data?.data?.items;
			if (holdingsArray?.length) {
				holdingsArray = holdingsArray.sort(
					sortByKey('lastModifiedDateTime', 'desc'),
				);
				const newState = setHoldings(holdingsArray);
				getTransactions(newState);
			} else {
				setIsLoading(false);
			}
		}
	};

	const logAPIError = () => {
		setIsLoading(false);
		setErrorMessage(getErrorState(PORTFOLIO_MESSAGE.GENERIC_ERROR));
	};

	const handleAddPortfolioHolding = async (item: any) => {
		if (isMaxLimit) {
			setErrorMessage(
				getErrorState(
					`User can not have more than ${MAX_PORTFOLIO_TRANSACTIONS} rows`,
				),
			);
		} else {
			const response = await getQuotes([item.xids.venue]);
			item['lastPrice'] =
				response?.data?.data?.quotes?.[0]?.data?.lastTrade?.last;
			dispatch({
				type: STATE_ACTIONS.ADD_A_TRANSACTION,
				data: item,
			});
		}
	};

	function updateTransaction(portfolioHoldings: any[], action: any) {
		const selectedHolding = portfolioHoldings.find((e: any) =>
			e['dummyHoldingId']
				? e['dummyHoldingId'] == action[TRANSACTIONS_KEYS.HOLDING_ID]
				: e[TRANSACTIONS_KEYS.HOLDING_ID] ==
				  action[TRANSACTIONS_KEYS.HOLDING_ID],
		);
		if (selectedHolding) {
			const transaction = selectedHolding[TRANSACTIONS_KEYS.TRANSACTIONS].find(
				(e: any) => e[TRANSACTIONS_KEYS.ID] === action[TRANSACTIONS_KEYS.ID],
			);

			if (transaction) {
				transaction[action.key] = action.value;
				transaction['isUpdate'] = true;
				if (
					transaction['highlight'] &&
					transaction['highlight'] === action.key
				) {
					delete transaction['highlight'];
				}
			} else if (!selectedHolding[TRANSACTIONS_KEYS.HOLDING_ID]) {
				selectedHolding[TRANSACTIONS_KEYS.TRANSACTIONS][0][action.key] =
					action.value;
			}
		}

		return [...portfolioHoldings];
	}

	function highlightRow(portfolioHoldings: any[], action: any) {
		if (action.holdingIndex >= 0 && action.transactionIndex >= 0) {
			portfolioHoldings[action.holdingIndex].transactions[
				action.transactionIndex
			]['highlight'] = action.key;
		}
		return [...portfolioHoldings];
	}

	function addTransaction(portfolioHoldings: any[], action: any) {
		const newHoldings = [...portfolioHoldings];
		const exisitngHolding = newHoldings.find(
			(e) =>
				e[TRANSACTIONS_KEYS.XID] == action.data.xids.venue &&
				e[TRANSACTIONS_KEYS.HOLDING_ID],
		);
		const holdingId = exisitngHolding
			? exisitngHolding[TRANSACTIONS_KEYS.HOLDING_ID]
			: '';
		const { value: lastPrice } = formatNumber(
			action.data?.lastPrice?.toString() || '',
			{
				precision: 2,
			},
		);
		newHoldings.unshift({
			[TRANSACTIONS_KEYS.SYMBOL]: action.data.symbol,
			[TRANSACTIONS_KEYS.NAME]: action.data.name,
			[TRANSACTIONS_KEYS.HOLDING_ID]: holdingId,
			[TRANSACTIONS_KEYS.XID]: action.data.xids.venue,
			[TRANSACTIONS_KEYS.TRANSACTIONS]: [
				{
					[TRANSACTIONS_KEYS.ID]: null,
					[TRANSACTIONS_KEYS.FEES]: defaultFees,
					[TRANSACTIONS_KEYS.QUANTITY]: '',
					[TRANSACTIONS_KEYS.DATE_TIME]: getDate(
						new Date(),
						DATE_FORMATS.YEAR_MONTH_DATE,
					),
					[TRANSACTIONS_KEYS.UNIT_VALUE]: +lastPrice || '',
					[TRANSACTIONS_KEYS.CASH_ACCOUNT_ID]: holdingId,
					isAdd: true,
				},
			],
			dummyHoldingId: createUUID(),
		});
		return newHoldings;
	}

	function setHoldings(holdings: any) {
		const newState: any = [];
		holdings.forEach((item: any) => {
			newState.push({
				[TRANSACTIONS_KEYS.HOLDING_ID]: item.id,
				[TRANSACTIONS_KEYS.XID]: item.xid,
				[TRANSACTIONS_KEYS.TRANSACTIONS]: [],
			});
		});
		return newState;
	}

	function setTransactions(transactionsArray: any, portfolioHoldings: any[]) {
		const currentState = JSON.parse(JSON.stringify(portfolioHoldings));

		transactionsArray.forEach((item: any) => {
			const currentHolding = currentState.find(
				(e: any) => e.holdingId === item.holdingId,
			);

			if (currentHolding) {
				currentHolding.transactions = item.transactions?.items?.map(
					(transaction: any) => {
						return {
							[TRANSACTIONS_KEYS.ID]: transaction.id,
							[TRANSACTIONS_KEYS.FEES]: parseFloat(transaction.fees),
							[TRANSACTIONS_KEYS.QUANTITY]: parseInt(transaction.quantity),
							[TRANSACTIONS_KEYS.DATE_TIME]: transaction.dateTime,
							[TRANSACTIONS_KEYS.UNIT_VALUE]: parseFloat(transaction.unitValue),
							[TRANSACTIONS_KEYS.CASH_ACCOUNT_ID]: currentHolding.holdingId,
							isUpdate: false,
						};
					},
				);
			}
		});

		return currentState;
	}
	/**
	 * Crud operations for UI Ends
	 */

	const handleTransactionArray = (
		transactionsToUpdate: OBJECT_TYPE,
		transactionToDelete: OBJECT_TYPE,
		existingHoldingsTransactions: OBJECT_TYPE,
		newHoldingsToAdd: OBJECT_TYPE,
		newTransactionsToAdd: OBJECT_TYPE,
		holdingsToDelete: OBJECT_TYPE,
	) => {
		portfolioHoldings.forEach((holding: any) => {
			let isHoldingDelete = true;
			holding.transactions.forEach((transaction: any) => {
				if (transaction[TRANSACTIONS_KEYS.ID] && transaction['isDelete']) {
					checkifObjectHasProperty(
						transactionToDelete,
						transaction[TRANSACTIONS_KEYS.CASH_ACCOUNT_ID],
						'',
					);
					transactionToDelete[transaction[TRANSACTIONS_KEYS.CASH_ACCOUNT_ID]] +=
						transaction[TRANSACTIONS_KEYS.ID] + ',';
				} else if (
					transaction[TRANSACTIONS_KEYS.ID] &&
					transaction['isUpdate'] &&
					!transaction['isDelete']
				) {
					checkifObjectHasProperty(
						transactionsToUpdate,
						holding[TRANSACTIONS_KEYS.HOLDING_ID],
					);
					transactionsToUpdate[holding[TRANSACTIONS_KEYS.HOLDING_ID]].push(
						getTransactionObj(transaction, holding, true),
					);
					isHoldingDelete = false;
				} else if (
					transaction['isAdd'] &&
					holding[TRANSACTIONS_KEYS.HOLDING_ID]?.length &&
					!transaction['isDelete']
				) {
					checkifObjectHasProperty(
						existingHoldingsTransactions,
						holding[TRANSACTIONS_KEYS.HOLDING_ID],
					);

					existingHoldingsTransactions[
						holding[TRANSACTIONS_KEYS.HOLDING_ID]
					].push(getTransactionObj(transaction, holding));
					isHoldingDelete = false;
				} else if (
					transaction['isAdd'] &&
					!holding[TRANSACTIONS_KEYS.HOLDING_ID] &&
					!transaction['isDelete']
				) {
					checkifObjectHasProperty(
						newHoldingsToAdd,
						holding[TRANSACTIONS_KEYS.XID],
					);
					newTransactionsToAdd.push(getTransactionObj(transaction, holding));
					isHoldingDelete = false;
				} else {
					isHoldingDelete = false;
				}
			});
			if (isHoldingDelete && holding[TRANSACTIONS_KEYS.HOLDING_ID]) {
				checkifObjectHasProperty(
					holdingsToDelete,
					holding[TRANSACTIONS_KEYS.HOLDING_ID],
				);
			}
		});
	};
	/**
	 * Save transactions to DB
	 */

	const saveTransactions = async () => {
		const transactionsToUpdate: OBJECT_TYPE = {};
		const transactionToDelete: OBJECT_TYPE = {};
		const existingHoldingsTransactions: OBJECT_TYPE = {};
		const newHoldingsToAdd: OBJECT_TYPE = {};
		const newTransactionsToAdd: any = [];
		const holdingsToDelete: OBJECT_TYPE = {};

		handleTransactionArray(
			transactionsToUpdate,
			transactionToDelete,
			existingHoldingsTransactions,
			newHoldingsToAdd,
			newTransactionsToAdd,
			holdingsToDelete,
		);

		const promiseArray: any = [];

		const deleteTransactionsArray = Object.keys(transactionToDelete);
		if (deleteTransactionsArray?.length) {
			deleteTransactionsArray.forEach((key: string) => {
				promiseArray.push(
					transObj.deleteTransaction(key, transactionToDelete[key]),
				);
			});
		}

		const transactionsUpdateArray = Object.keys(transactionsToUpdate);
		if (transactionsUpdateArray?.length) {
			transactionsUpdateArray.forEach((key: string) => {
				promiseArray.push(
					transObj.createUpdateTransactions(
						transactionsToUpdate[key],
						true,
						key,
					),
				);
			});
		}

		const transactionsExistingHoldings = Object.keys(
			existingHoldingsTransactions,
		);

		if (transactionsExistingHoldings?.length) {
			transactionsExistingHoldings.forEach((key: string) => {
				promiseArray.push(
					transObj.createUpdateTransactions(
						existingHoldingsTransactions[key],
						false,
					),
				);
			});
		}

		const newHoldingsArray = Object.keys(newHoldingsToAdd).map(
			(xid: string) => {
				return {
					xid: parseInt(xid),
				};
			},
		);

		if (newHoldingsArray?.length) {
			promiseArray.push(holdingObj.createHoldings(newHoldingsArray));
		}

		let commaSepratedHoldings = '';
		Object.keys(holdingsToDelete).forEach((key: string) => {
			if (
				!(
					transactionsToUpdate[key] ||
					newHoldingsToAdd[key] ||
					existingHoldingsTransactions[key]
				)
			) {
				commaSepratedHoldings += key + ',';
			}
		});

		commaSepratedHoldings.length &&
			promiseArray.push(holdingObj.deleteHoldings(commaSepratedHoldings));
		let response: any = await Promise.allSettled(promiseArray);

		if (newTransactionsToAdd?.length) {
			response = await addUpdateDeleteTransactions(
				response[promiseArray?.length - 1]?.value,
				newTransactionsToAdd,
			);
		}
		return getResult(response);
	};

	const checkifObjectHasProperty = (
		obj: { [key: string]: any },
		key: string,
		defaultValue?: any,
	) => {
		if (!obj[key]) {
			obj[key] = defaultValue || [];
		}
		return obj;
	};

	const addUpdateDeleteTransactions = async (
		newHoldings: any,
		newTransactionsToAdd: any,
	) => {
		const holdingsResults = newHoldings?.data?.data?.items;
		const transactionsToAdd: OBJECT_TYPE = {};

		if (holdingsResults?.length) {
			holdingsResults.forEach((item: any) => {
				newTransactionsToAdd
					?.filter((e: any) => e[TRANSACTIONS_KEYS.XID] == item.xid)
					.forEach((transaction: any) => {
						checkifObjectHasProperty(transactionsToAdd, item.id);
						transaction[TRANSACTIONS_KEYS.CASH_ACCOUNT_ID] = item.id;
						transactionsToAdd[item.id].push(transaction);
					});
			});
		}

		const promiseArray: Array<any> = [];
		Object.keys(transactionsToAdd).forEach((key: string) => {
			promiseArray.push(
				transObj.createUpdateTransactions(transactionsToAdd[key], false),
			);
		});

		const response = (await Promise.allSettled(
			promiseArray,
		)) as ALL_PROMISE_SETTLED;
		return response;
	};

	const getResult = (response: ALL_PROMISE_SETTLED) => {
		let responseResult = {
			status: MessageBoxStateType.Success,
			message: PORTFOLIO_MESSAGE.SUCCESS,
		};

		const failArray = response?.filter(
			(item: SINGLE_PROMISE_SETTLED) => item.value?.data?.errors?.length,
		);
		const failArrayLength = failArray?.length;

		if (failArrayLength) {
			responseResult =
				failArrayLength === response.length
					? {
							status: MessageBoxStateType.Fail,
							message: PORTFOLIO_MESSAGE.TRANSACTIONS_ERROR,
					  }
					: {
							status: MessageBoxStateType.Warning,
							message: PORTFOLIO_MESSAGE.TRANSACTIONS_PARTIALY_SAVED,
					  };
		}
		return responseResult;
	};

	const getTransactionObj = (data: any, item: any, isUpdate = false) => {
		const transObject = {
			[TRANSACTIONS_KEYS.FEES]: parseFloat(data[TRANSACTIONS_KEYS.FEES]),
			[TRANSACTIONS_KEYS.QUANTITY]: parseInt(data[TRANSACTIONS_KEYS.QUANTITY]),
			[TRANSACTIONS_KEYS.DATE_TIME]: data[TRANSACTIONS_KEYS.DATE_TIME],
			[TRANSACTIONS_KEYS.UNIT_VALUE]: parseFloat(
				data[TRANSACTIONS_KEYS.UNIT_VALUE],
			),
		};
		if (!isUpdate) {
			transObject[TRANSACTIONS_KEYS.CASH_ACCOUNT_ID] = item.id?.length
				? item.id
				: data[TRANSACTIONS_KEYS.CASH_ACCOUNT_ID];
			transObject[TRANSACTIONS_KEYS.XID] = item.xid;
		} else {
			transObject[TRANSACTIONS_KEYS.ID] = data[TRANSACTIONS_KEYS.ID] || null;
		}
		return transObject;
	};

	/**
	 * Event handlers
	 */
	const onIconClick = (e: any) => {
		dispatch({
			type: STATE_ACTIONS.UPDATE_TRANSACTION,
			key: 'isDelete',
			value: true,
			[TRANSACTIONS_KEYS.HOLDING_ID]: e.currentTarget.getAttribute('data-key'),
			[TRANSACTIONS_KEYS.ID]: e.currentTarget.getAttribute('data-id'),
		});
	};

	const onInputChange = (e: any) => {
		const keyName = e.target.name;
		let value = e.target.value;
		if (
			keyName === TRANSACTIONS_KEYS.FEES ||
			keyName === TRANSACTIONS_KEYS.UNIT_VALUE
		) {
			value = e.target.value.replace('-', '');
			value = value?.length ? parseFloat(value) : '';
			value =
				keyName === TRANSACTIONS_KEYS.FEES && !value ? defaultFees : value;
		} else if (keyName === TRANSACTIONS_KEYS.QUANTITY) {
			value = e.target.value.replace('-', '');
			value = value?.length ? parseInt(value) : '';
		}

		dispatch({
			type: STATE_ACTIONS.UPDATE_TRANSACTION,
			key: keyName,
			value: value,
			[TRANSACTIONS_KEYS.HOLDING_ID]: e.target.getAttribute('data-key'),
			[TRANSACTIONS_KEYS.ID]: e.target.getAttribute('data-id'),
		});
	};

	/**
	 * Event handlers Ends
	 */

	/**
	 * Bind UI
	 */

	const bindColumn = (
		transactionObj: any,
		item: any,
		data: any,
		idx: number,
		todaysDate: string,
	) => {
		const newItem: any = { ...item };
		if (
			transactionObj['highlight'] &&
			transactionObj['highlight'] === item.key
		) {
			newItem.highlight = true;
		}
		if (item.isButton) {
			newItem.onClick = onIconClick;
		} else if (item.isInput) {
			newItem.onChange = onInputChange;
		} else if (item.isLink) {
			newItem.venueXid = data?.xid;
		}
		if (item.isDate) {
			if (transactionObj[item.key]) {
				transactionObj[item.key] = transactionObj[item.key].split('T')[0];
			}
			newItem.max = todaysDate;
		}

		newItem.dataKey =
			data['dummyHoldingId'] || data[TRANSACTIONS_KEYS.HOLDING_ID];
		newItem.dataId = transactionObj[TRANSACTIONS_KEYS.ID];
		return (
			<React.Fragment key={`col_${idx}`}>
				{generateColValue(
					newItem,
					item.isParent ? data[item.key] : transactionObj[item.key],
				)}
			</React.Fragment>
		);
	};

	const handleMobilecolumn = (
		transactionObj: any,
		data: any,
		todaysDate: string,
		mobileFirstRow: any = [],
	) => {
		return (
			<React.Fragment>
				{ADD_TRANSACTIONS_TABLE_DATA_MAPPING.map((item, idx: number) => {
					if (!isMobile) {
						return bindColumn(transactionObj, item, data, idx, todaysDate);
					} else if (isMobile && item.showInSingleRow) {
						mobileFirstRow.push(
							bindColumn(transactionObj, item, data, idx + 2, todaysDate),
						);
					} else {
						return (
							<div className={'common-row'} key={`row_${item.key}`}>
								{generateColValue(
									ADD_TRANSACTIONS_TABLE_HEADER[idx],
									ADD_TRANSACTIONS_TABLE_HEADER[idx].label,
								)}
								{bindColumn(transactionObj, item, data, idx + 2, todaysDate)}
							</div>
						);
					}
				})}
			</React.Fragment>
		);
	};

	const bindTable = () => {
		const holdings = JSON.parse(JSON.stringify(portfolioHoldings));
		const todaysDate = getDate(new Date(), DATE_FORMATS.YEAR_MONTH_DATE);
		const oldTransactions = holdings?.map((data: any) => {
			return data?.transactions.map((transaction: any) => {
				const transactionObj = { ...transaction };
				const mobileFirstRow: any = [];
				const transactionsData = !transaction.isDelete
					? handleMobilecolumn(transactionObj, data, todaysDate, mobileFirstRow)
					: null;
				if (transactionsData) {
					return (
						<div
							className={`${isMobile ? 'single-porfolio' : 'common-row'}`}
							key={`row_${transaction.id}`}
						>
							{isMobile && (
								<div className={'common-row single-row'}>{mobileFirstRow}</div>
							)}
							{transactionsData}
						</div>
					);
				}
			});
		});

		return <React.Fragment>{oldTransactions}</React.Fragment>;
	};

	const bindTransactions = () => {
		if (!portfolioHoldings?.length) {
			return (
				<div className={'no-data-container'}>
					<NoData text={'No transactions found'} size={'m'} />
				</div>
			);
		}
		return (
			<React.Fragment>
				{bindData(
					!isMobile
						? ADD_TRANSACTIONS_TABLE_HEADER
						: ADD_TRANSACTIONS_TABLE_HEADER.filter((e: any) => e.isMobile),
					'header',
					`portfolio-table-header common-row`,
				)}
				<div className={'table-data'}>{bindTable()}</div>
			</React.Fragment>
		);
	};

	const onButtonClick = (e: any) => {
		let errorMessage: string | null = null;
		if (
			isEdit &&
			e.currentTarget.getAttribute('data-action') === PORTFOLIO_ACTIONS.SAVE
		) {
			const response = isValidInputs();
			errorMessage = !response?.status ? response?.message : '';
			errorMessage?.length && setErrorMessage(getErrorState(errorMessage));
		}
		props.onButtonClick?.(e, saveTransactions, errorMessage);
	};

	const getKeyvalue = (
		holding: any,
		holdingIndex: number,
		transactionIndex: number,
		transaction: any,
	) => {
		let key = '';
		const currentDate = getDate(new Date(), DATE_FORMATS.YEAR_MONTH_DATE);
		let errorMessage = PORTFOLIO_MESSAGE.REQUIRED_FIELD_ERROR;
		if (isNaN(Date.parse(transaction[TRANSACTIONS_KEYS.DATE_TIME]))) {
			key = TRANSACTIONS_KEYS.DATE_TIME;
		} else if (
			Date.parse(transaction[TRANSACTIONS_KEYS.DATE_TIME]) >
			Date.parse(currentDate)
		) {
			key = TRANSACTIONS_KEYS.DATE_TIME;
			errorMessage = PORTFOLIO_MESSAGE.INVALID_DATE_ERROR;
		} else if (
			!checkIfIsNumeric(transaction[TRANSACTIONS_KEYS.UNIT_VALUE]) ||
			parseFloat(transaction[TRANSACTIONS_KEYS.UNIT_VALUE]) <= 0
		) {
			key = TRANSACTIONS_KEYS.UNIT_VALUE;
		} else if (
			!checkIfIsNumeric(transaction[TRANSACTIONS_KEYS.QUANTITY]) ||
			parseFloat(transaction[TRANSACTIONS_KEYS.QUANTITY]) <= 0
		) {
			key = TRANSACTIONS_KEYS.QUANTITY;
		} else if (
			!checkIfIsNumeric(transaction[TRANSACTIONS_KEYS.FEES]) &&
			transaction[TRANSACTIONS_KEYS.FEES] != defaultFees
		) {
			key = TRANSACTIONS_KEYS.FEES;
		}

		if (key.length) {
			dispatch({
				type: STATE_ACTIONS.HIGHLIGHT_ERROR_ROW,
				value: true,
				[TRANSACTIONS_KEYS.HOLDING_ID]:
					holding[TRANSACTIONS_KEYS.HOLDING_ID] || holding['dummyHoldingId'],
				holdingIndex: holdingIndex,
				transactionIndex: transactionIndex,
				key: key,
			});
			return { status: false, message: errorMessage };
		}
		return { status: true, message: '' };
	};

	const isValidInputs = () => {
		let isPassed: ERROR_MESSAGE_STATES_TYPE = {
			status: true,
			message: '',
		};
		if (portfolioHoldings?.length) {
			portfolioHoldings.forEach((holding: any, holdingIndex: number) => {
				isPassed?.status &&
					holding.transactions?.forEach(
						(transaction: any, transactionIndex: number) => {
							if (!transaction['isDelete']) {
								isPassed = getKeyvalue(
									holding,
									holdingIndex,
									transactionIndex,
									transaction,
								);
							}
							if (!isPassed?.status) {
								return isPassed;
							}
						},
					);
				if (!isPassed?.status) {
					return isPassed;
				}
			});
		}
		return isPassed;
	};

	const checkIfIsNumeric = (value: any) => {
		return Number.isFinite(value);
	};

	const bindFooter = () => {
		return (
			<div className={'create-p-footer'} data-testid={'portfolioFooter'}>
				<div className={'common-button'}>
					<Button
						onClick={onButtonClick}
						variant={ButtonVariant.Secondary}
						data-action={PORTFOLIO_ACTIONS.CANCEL}
					>
						Cancel
					</Button>
				</div>
				<div className={'common-button'}>
					<Button
						onClick={onButtonClick}
						variant={ButtonVariant.Primary}
						data-action={PORTFOLIO_ACTIONS.SAVE}
					>
						Save
					</Button>
				</div>
			</div>
		);
	};

	return (
		<div
			data-testid={`portfolio-holdings-container`}
			className={`portfolio-holdings-container ${isLoading && 'disabled'}`}
		>
			{errorMessage.message.length > 0 && (
				<MessageBox
					content={errorMessage.message}
					state={errorMessage.status}
					enableCloseIcon={true}
					onClose={onCrossClick}
					autoHide={true}
					timeAutoHide={3000}
				/>
			)}
			{isEdit && (
				<React.Fragment>
					{isLoading ? (
						<div className={'loader-container'}>
							<Loader />
						</div>
					) : (
						<React.Fragment>
							<div className={'search-container'}>
								{generateColValue(
									{
										type: TYPES_OF_ELEMENTS.LABEL,
										size: 'm',
										isBold: true,
									},
									'Add a Transaction',
								)}
								<SearchBox
									onAddSymbol={handleAddPortfolioHolding}
									placeholder="Enter Name or Symbol"
								/>
							</div>
							<div
								data-testid="transactions-container"
								className="transactions-container"
							>
								{bindTransactions()}
							</div>
						</React.Fragment>
					)}
				</React.Fragment>
			)}
			{bindFooter()}
		</div>
	);
};

export default PortfolioHoldings;
