import React from 'react';
import { IBasicIconProps } from '../IBasicIconProps';

const PlusIcon: React.FC<IBasicIconProps> = ({
	width = 13,
	height = 13,
	color = '#000000',
}) => (
	<svg
		width={width}
		height={height}
		viewBox="0 0 13 13"
		version="1.1"
		xmlns="http://www.w3.org/2000/svg"
		data-icon="plus"
	>
		<g
			stroke="none"
			strokeWidth="1"
			fill="none"
			fillRule="evenodd"
			strokeLinecap="round"
			strokeLinejoin="round"
		>
			<g
				transform="translate(-498.000000, -1058.000000)"
				stroke={color}
				strokeWidth="1.3"
			>
				<g transform="translate(458.000000, 172.000000)">
					<g transform="translate(40.000000, 883.000000)">
						<g transform="translate(0.000000, 3.000000)">
							<path d="M6.5,0.5 L6.5,12.5 M0.5,6.5 L12.5,6.5"></path>
						</g>
					</g>
				</g>
			</g>
		</g>
	</svg>
);

export default PlusIcon;
