export enum WatchlistDisplayOption {
	LastAndChg = 'Last & Chg',
	MostRecentSecurityNews = 'Most Recent Security News',
	MostRecentNewsLabelInMobile = 'Most Recent News',
	LastAndChgInMobile = 'Last + Chg',
}

export const WatchlistDisplayOptionPreferenceConstants = {
	OP_WATCHLIST_DISPLAY_TYPE: 'OP_WATCHLIST_DISPLAY_TYPE',
	LAST_CHANGE: 'LAST_CHANGE',
	RECENT_NEWS: 'RECENT_NEWS',
};

export const WatchlistDisplayOptionLabels = {
	LAST_CHANGE: 'Last/Chg',
	RECENT_NEWS: 'News',
};

export const WatchlistLimit = 50;

export const WatchlistLimitMessage =
	'Watchlist maximum reached. Please delete a Watchlist item to add a new item.';

export const NoWatchlistAddedMessage = 'No items added to the watchlist.';
export const WatchlistSavedSuccessfullMessage = 'Watchlist saved successfully.';

const ClassificationMappings: { [key: string]: string } = {
	Equity: 'STOCK',
};

export const getClassificationName = (classification: string) => {
	return (
		ClassificationMappings[classification]?.toUpperCase() ||
		classification?.toUpperCase()
	);
};
