import { IAPIResponse } from '../types';

import { dispatchAccessTokenExpiredEvent } from '../utilities/events';

import {
	geRequestUrlWithParams,
	getAccessToken,
	resolvePromiseWithError,
	resolvePromiseWithSuccess,
} from '../utilities/utils';

/**
 * Method used to execute a data request
 * @param {String} url [Fully qualified URL for a data request]
 * @param {String} method [Request method, defaults to 'GET']
 * @param {Function} callback [Callback to which a response will be passed]
 * @param {Object} postData [Data to be included in body of POST request]
 */
const executeDataRequest = async (
	url: string,
	inputs: any,
	headers: any,
	setData: any = null,
	setError: any = null,
	requestInputs: any = null,
): Promise<IAPIResponse | any> => {
	try {
		const dataRequestUrl = geRequestUrlWithParams(url, inputs?.params);

		if (!dataRequestUrl) {
			return await resolvePromiseWithError(
				'Request URL not provided!',
				requestInputs,
			);
		}

		const accessToken: string = getAccessToken();

		if (!accessToken) {
			// token not available, than dispatch event
			return dispatchAccessTokenExpiredEvent();
		}

		const fetchConfig: any = {
			method: inputs?.method || 'GET',
		};

		if (accessToken) {
			// Add Authorization header if access token exists
			fetchConfig.headers = {
				Authorization: `Bearer ${accessToken}`,
			};
		}

		handleAuthorization(fetchConfig, headers, inputs?.method, inputs?.postData);

		const response = await fetch(dataRequestUrl, fetchConfig);

		let responseData;

		if (headers.Accept === 'application/xml') {
			responseData = await response.text();
			if (!response.ok) {
				responseData = JSON.parse(responseData);
			}
		} else {
			responseData = await response.json();
		}
		setData?.(responseData);
		if (responseData?.error) {
			if (['401', '403'].includes(responseData?.error?.code)) {
				//if invalid access token than dispatch event
				dispatchAccessTokenExpiredEvent(true);
			}
			setError?.(responseData?.error);
			return await resolvePromiseWithError(responseData?.error, requestInputs);
		}
		return await resolvePromiseWithSuccess(responseData, requestInputs);
	} catch (error: any) {
		return await resolvePromiseWithError(error, requestInputs);
	}
};

const handleAuthorization = (
	fetchConfig: any,
	headers: any,
	method: string,
	postData: any,
) => {
	if (headers && headers instanceof Object) {
		if (!fetchConfig.headers) {
			fetchConfig.headers = {};
		}
		Object.assign(fetchConfig.headers, headers);
	}
	if (method !== 'GET' && postData) {
		fetchConfig.body = JSON.stringify(postData);
	}
};

/**
 * Method used to execute a data request via an API path
 * @param {String} path [API path]
 * @param {Object} inputs [Request inputs
 * {
 *  method: 'GET',
 *  params: Object, URL parameters as key value pairs
 *  postData: Object, Data to be included in body of POST request
 * }]
 * @param {Object} params [URL parameters as key value pairs]
 */
const execute = async (
	path: string,
	inputs: any = {},
	headers: any = {},
	setData: any = null,
	setError: any = null,
) => {
	if (!inputs) {
		inputs = {};
	}

	return await executeDataRequest(
		path,
		inputs,
		headers,
		setData,
		setError,
		inputs.requestInputs,
	);
};

const DataRequest = {
	execute,
};

export default DataRequest;
